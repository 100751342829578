import numbro from "numbro";

export const add = (value1, value2) => {
  return numbro(value1).add(value2).value();
};

export const subtract = (value1, value2) => {
  return numbro(value1).subtract(value2).value();
};

export const divide = (value1, value2) => {
  return numbro(value1).divide(value2).value();
};
