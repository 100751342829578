import React from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Button, FormInput, Form, Pane } from "components/materials";
import { Formik } from "formik";
import { majorScale, toaster } from "helpers/utilities";
import { CLEAR_LAST_EXTERNAL_VENDOR_SYNC_AT_FOR_ORGANIZATION } from "./graphql";

export function BillComPaymentSettingsForm({ organizationId, billComUrl }) {
  const [updateBillComPaymentSettings, updateBillComPaymentSettingsResult] =
    useMutation(UPDATE_ORGANIZATION_BILL_COM_PAYMENT_SETTINGS_MUTATION, {
      onCompleted: () => toaster.success("Organization updated"),
    });

  const [clearLastExternalVendorSyncAt] = useMutation(
    CLEAR_LAST_EXTERNAL_VENDOR_SYNC_AT_FOR_ORGANIZATION,
    {
      onCompleted: () => toaster.success("Success"),
    }
  );

  function submitForm(data) {
    const trimmedData = {
      ...data,
      billComUrl: data.billComUrl ? data.billComUrl.trim() : "",
    };

    return updateBillComPaymentSettings({
      variables: { ...trimmedData, id: organizationId },
    });
  }

  return (
    <Pane>
      <Pane marginBottom={majorScale(2)}>
        <Button
          intent="danger"
          appearance="primary"
          onClick={() =>
            clearLastExternalVendorSyncAt({ variables: { organizationId } })
          }
        >
          Clear &quot;vendor last synced at&quot;
        </Button>
      </Pane>
      <Pane>
        <Formik
          initialValues={{
            billComUrl: billComUrl || undefined,
          }}
          onSubmit={(data) => submitForm(data)}
        >
          {({ handleSubmit }) => (
            <Form
              isLoading={updateBillComPaymentSettingsResult.loading}
              onSubmit={handleSubmit}
            >
              <FormInput label="Url" name="billComUrl" width={300} />
            </Form>
          )}
        </Formik>
      </Pane>
    </Pane>
  );
}

const UPDATE_ORGANIZATION_BILL_COM_PAYMENT_SETTINGS_MUTATION = gql`
  mutation UpdateOrganizationBillComPaymentSettings(
    $id: String!
    $billComUrl: String
  ) {
    updateOrganizationBillComPaymentSettings(id: $id, billComUrl: $billComUrl) {
      id
      billComUrl
    }
  }
`;
