import React from "react";
import PropTypes from "prop-types";
import { Link, Table } from "components/materials";

const ProjectTemplatesTable = (props) => (
  <Table border="default">
    <Table.Head>
      <Table.TextHeaderCell>Name</Table.TextHeaderCell>
      <Table.TextHeaderCell>Project Count</Table.TextHeaderCell>
      <Table.TextHeaderCell />
    </Table.Head>
    <Table.Body>
      {props.projectTemplates.map((projectTemplate) => (
        <Table.Row key={projectTemplate.id}>
          <Table.Cell>
            <Link
              to={`/organizations/${props.organizationId}/project_templates/${projectTemplate.id}/edit`}
            >
              {projectTemplate.name}
            </Link>
          </Table.Cell>
          <Table.TextCell>{projectTemplate.projectCount}</Table.TextCell>
          <Table.Cell>
            <Link
              to={`/organizations/${props.organizationId}/project_templates/${projectTemplate.id}/edit`}
            >
              Edit
            </Link>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

ProjectTemplatesTable.propTypes = {
  organizationId: PropTypes.string.isRequired,
  projectTemplates: PropTypes.array.isRequired,
};

export default ProjectTemplatesTable;
