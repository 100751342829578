import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  AdminPage,
  AppLayoutPage,
  HomePage,
  IntegrationEditPage,
  IntegrationNewPage,
  NotFoundPage,
  OrganizationEditPage,
  OrganizationNewPage,
  OrganizationsPage,
  ProjectConnectionsPage,
  ProjectEditPage,
  ProjectMonthCreditsActivitiesPage,
  ProjectsPage,
  ProjectTemplateEditPage,
  ProjectTemplateNewPage,
  UserLookupPage,
  UserPage,
  JinjaHelperPage,
} from "components/containers";
import auth from "auth";

const renderApp = (props) =>
  auth.isAuthenticated() ? (
    <AppLayoutPage>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/organizations" component={OrganizationsPage} />
        <Route exact path="/user_lookup" component={UserLookupPage} />
        <Route
          exact
          path="/organizations/new"
          component={OrganizationNewPage}
        />
        <Route
          exact
          path="/organizations/:organizationId/edit"
          component={OrganizationEditPage}
        />
        <Route
          exact
          path="/organizations/:organizationId/integrations/new"
          component={IntegrationNewPage}
        />
        <Route
          exact
          path="/organizations/:organizationId/integrations/:integrationId/edit"
          component={IntegrationEditPage}
        />
        <Route
          exact
          path="/organizations/:organizationId/project_templates/new"
          component={ProjectTemplateNewPage}
        />
        <Route
          exact
          path="/organizations/:organizationId/project_templates/:projectTemplateId/edit"
          component={ProjectTemplateEditPage}
        />
        <Route
          exact
          path="/organizations/:organizationId/projects"
          component={ProjectsPage}
        />
        <Route
          exact
          path="/organizations/:organizationId/projects/:projectId/edit"
          component={ProjectEditPage}
        />
        <Route
          exact
          path="/organizations/:organizationId/project_month_credits_activities"
          component={ProjectMonthCreditsActivitiesPage}
        />
        <Route exact path="/admin" component={AdminPage} />
        <Route exact path="/users/:userId" component={UserPage} />
        <Route
          exact
          path="/project_connections"
          component={ProjectConnectionsPage}
        />
        <Route exact path="/jinja" component={JinjaHelperPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </AppLayoutPage>
  ) : (
    <Redirect to="/login" />
  );

const renderCallback = (props) => {
  const { push } = props.history;
  auth
    .handleCallback()
    .then(() => push("/"))
    .catch(() => push("/login"));
  return null;
};

const renderLogout = () => {
  auth.logout();
  return null;
};

const renderLogin = () => {
  auth.login();
  return null;
};

const routes = (
  <Switch>
    <Route exact path="/login" render={renderLogin} />
    <Route exact path="/logout" render={renderLogout} />
    <Route exact path="/auth/callback" render={renderCallback} />
    <Route render={renderApp} />
  </Switch>
);

export default routes;
