import React from "react";
import PropTypes from "prop-types";
import { Link, Table, Text } from "components/materials";
import { t } from "helpers/utilities";

function IntegrationsTable(props) {
  return (
    <Table border="default">
      <Table.Head>
        <Table.TextHeaderCell>Webhook URL</Table.TextHeaderCell>
        <Table.TextHeaderCell>Trigger</Table.TextHeaderCell>
        <Table.TextHeaderCell>Enabled?</Table.TextHeaderCell>
        <Table.TextHeaderCell />
      </Table.Head>
      <Table.Body>
        {props.integrations.map((integration) => (
          <Table.Row key={integration.id}>
            <Table.Cell>
              <Link
                to={`/organizations/${props.organizationId}/integrations/${integration.id}/edit`}
              >
                {integration.webhookUrl}
              </Link>
            </Table.Cell>
            <Table.Cell>
              <Text>{t(`integrationTriggers.${integration.trigger}`)}</Text>
            </Table.Cell>
            <Table.Cell>
              <Text>{integration.enabled ? "Yes" : "No"}</Text>
            </Table.Cell>
            <Table.Cell>
              <Link
                to={`/organizations/${props.organizationId}/integrations/${integration.id}/edit`}
              >
                Edit
              </Link>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

IntegrationsTable.propTypes = {
  organizationId: PropTypes.string.isRequired,
  integrations: PropTypes.array.isRequired,
};

export default IntegrationsTable;
