import React from "react";
import {
  Button,
  Form,
  FormFile,
  FormInput,
  Pane,
  Segment,
} from "components/materials";
import { Formik, Field } from "formik";
import { isBlank, isInvalidHexCode, majorScale, t } from "helpers/utilities";

function validateCobranding(values) {
  const errors = {};
  if (!isBlank(values.headerColor) && isInvalidHexCode(values.headerColor)) {
    errors.headerColor = "Use valid hex code (#xxxxxx)";
  }
  if (!isBlank(values.primaryColor) && isInvalidHexCode(values.primaryColor)) {
    errors.primaryColor = "Use valid hex code (#xxxxxx)";
  }
  return errors;
}

export function CobrandingForm({
  alternateLogo,
  deleteLogo,
  loading,
  organization,
  primaryLogo,
  updateCobranding,
}) {
  return (
    <Formik
      initialValues={{
        headerColor: organization.headerColor || undefined,
        headerColorPicker: organization.headerColor || undefined,
        id: organization.id,
        primaryColor: organization.primaryColor || undefined,
        primaryColorPicker: organization.primaryColor || undefined,
      }}
      onSubmit={(variables) => updateCobranding({ variables })}
      validate={validateCobranding}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Form isLoading={loading} onSubmit={handleSubmit}>
          <Pane display="flex" alignItems="center" marginBottom={majorScale(2)}>
            <Segment
              heading="Primary Color"
              helpText={t(`helpText.cobrandingPrimaryColor`)}
              marginRight={majorScale(4)}
            >
              <FormInput
                name="primaryColor"
                onChange={(e) =>
                  setFieldValue("primaryColorPicker", e.target.value)
                }
              />
              <Field
                name="primaryColorPicker"
                type="color"
                onChange={(e) => {
                  setFieldValue("primaryColor", e.target.value);
                  setFieldValue("primaryColorPicker", e.target.value);
                }}
              />
            </Segment>
            <Segment
              heading="Header Color"
              helpText={t(`helpText.cobrandingHeaderColor`)}
            >
              <FormInput
                name="headerColor"
                onChange={(e) =>
                  setFieldValue("headerColorPicker", e.target.value)
                }
              />
              <Field
                name="headerColorPicker"
                onChange={(e) => {
                  setFieldValue("headerColor", e.target.value);
                  setFieldValue("headerColorPicker", e.target.value);
                }}
                type="color"
              />
            </Segment>
          </Pane>
          <Pane display="flex">
            <Segment
              heading="Primary Logo"
              helpText={t(`helpText.cobrandingPrimaryLogo`)}
            >
              <Pane display="flex">
                <FormFile accept={["image/*"]} name="primaryLogo" />
                {primaryLogo && (
                  <Pane marginLeft={majorScale(4)}>
                    <Pane
                      height={68}
                      marginBottom={majorScale(3)}
                      padding={18}
                      width={300}
                      backgroundColor={values.headerColor}
                    >
                      <img src={primaryLogo.url} height={32} alt="" />
                    </Pane>
                    <Button
                      onClick={() => {
                        deleteLogo({
                          variables: {
                            organizationId: organization.id,
                            type: "primary",
                          },
                        });
                      }}
                      isLoading={loading}
                      type="button" // prevents formik submit
                    >
                      Reset Primary Logo
                    </Button>
                  </Pane>
                )}
              </Pane>
            </Segment>
            <Segment
              heading="Alternate Logo (Generated Documents and Emails)"
              helpText={t(`helpText.cobrandingAlternateLogo`)}
              marginLeft={majorScale(4)}
            >
              <Pane display="flex">
                <FormFile
                  accept={["image/jpeg", "image/jpg", "image/png"]}
                  name="alternateLogo"
                />
                {alternateLogo && (
                  <Pane marginLeft={majorScale(4)}>
                    <Pane
                      height={68}
                      marginBottom={majorScale(3)}
                      padding={18}
                      width={300}
                    >
                      <img src={alternateLogo.url} height={32} alt="" />
                    </Pane>
                    <Button
                      onClick={() => {
                        deleteLogo({
                          variables: {
                            organizationId: organization.id,
                            type: "alternate",
                          },
                        });
                      }}
                      isLoading={loading}
                      type="button" // prevents formik submit
                    >
                      Reset Alternate Logo
                    </Button>
                  </Pane>
                )}
              </Pane>
            </Segment>
          </Pane>
        </Form>
      )}
    </Formik>
  );
}
