import React from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { FormInput, Form } from "components/materials";
import { Formik } from "formik";
import { toaster } from "helpers/utilities";

export function MriPaymentSettingsForm({
  organizationId,
  mriClientId,
  mriDatabaseName,
  mriPartnerKey,
  mriPartnerName,
  mriPassword,
  mriUrl,
  mriUserId,
}) {
  const [updateMriPaymentSettings, updateMriPaymentSettingsResult] =
    useMutation(UPDATE_ORGANIZATION_MRI_PAYMENT_SETTINGS_MUTATION, {
      onCompleted: () => toaster.success("Organization updated"),
    });

  return (
    <Formik
      initialValues={{
        mriClientId: mriClientId || undefined,
        mriDatabaseName: mriDatabaseName || undefined,
        mriPartnerKey: mriPartnerKey || undefined,
        mriPartnerName: mriPartnerName || undefined,
        mriPassword: mriPassword || undefined,
        mriUrl: mriUrl || undefined,
        mriUserId: mriUserId || undefined,
        id: organizationId,
      }}
      onSubmit={(data) => {
        const trimmedData = {
          ...data,
          mriClientId: data.mriClientId ? data.mriClientId.trim() : "",
          mriDatabaseName: data.mriDatabaseName
            ? data.mriDatabaseName.trim()
            : "",
          mriPartnerKey: data.mriPartnerKey ? data.mriPartnerKey.trim() : "",
          mriPartnerName: data.mriPartnerName ? data.mriPartnerName.trim() : "",
          mriPassword: data.mriPassword ? data.mriPassword.trim() : "",
          mriUrl: data.mriUrl ? data.mriUrl.trim() : "",
          mriUserId: data.mriUserId ? data.mriUserId.trim() : "",
        };
        return updateMriPaymentSettings({ variables: trimmedData });
      }}
    >
      {({ handleSubmit }) => (
        <Form
          isLoading={updateMriPaymentSettingsResult.loading}
          onSubmit={handleSubmit}
        >
          <FormInput label="Url" name="mriUrl" width={300} />
          <FormInput label="Client Id" name="mriClientId" width={300} />
          <FormInput label="User Id" name="mriUserId" width={300} />
          <FormInput label="Partner Key" name="mriPartnerKey" width={300} />
          <FormInput label="Partner Name" name="mriPartnerName" width={300} />
          <FormInput
            label="Password"
            name="mriPassword"
            width={300}
            type="password"
          />

          <FormInput label="Database Name" name="mriDatabaseName" width={300} />
        </Form>
      )}
    </Formik>
  );
}

const UPDATE_ORGANIZATION_MRI_PAYMENT_SETTINGS_MUTATION = gql`
  mutation UpdateOrganizationMriPaymentSettings(
    $id: String!
    $mriClientId: String
    $mriDatabaseName: String
    $mriPartnerKey: String
    $mriPartnerName: String
    $mriPassword: String
    $mriUrl: String
    $mriUserId: String
  ) {
    updateOrganizationMriPaymentSettings(
      id: $id
      mriClientId: $mriClientId
      mriDatabaseName: $mriDatabaseName
      mriPartnerKey: $mriPartnerKey
      mriPartnerName: $mriPartnerName
      mriPassword: $mriPassword
      mriUrl: $mriUrl
      mriUserId: $mriUserId
    ) {
      id
      mriClientId
      mriDatabaseName
      mriPartnerKey
      mriPartnerName
      mriPassword
      mriUrl
      mriUserId
    }
  }
`;
