import React from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { FormInput, Form } from "components/materials";
import { Formik } from "formik";
import { toaster } from "helpers/utilities";

export function NexusPaymentSettingsForm({
  organizationId,
  nexusClientCode,
  nexusUserName,
  nexusURL,
  nexusKey,
  nexusSecret,
}) {
  const [updateNexusPaymentSettings, updateNexusPaymentSettingsResult] =
    useMutation(UPDATE_ORGANIZATION_NEXUS_PAYMENT_SETTINGS_MUTATION, {
      onCompleted: () => toaster.success("Organization updated"),
    });

  return (
    <Formik
      initialValues={{
        nexusClientCode: nexusClientCode || undefined,
        nexusUserName: nexusUserName || undefined,
        nexusURL: nexusURL || undefined,
        nexusKey: nexusKey || undefined,
        nexusSecret: nexusSecret || undefined,
        id: organizationId,
      }}
      onSubmit={(data) => {
        const trimmedData = {
          ...data,
          nexusClientCode: data.nexusClientCode
            ? data.nexusClientCode.trim()
            : "",
          nexusUserName: data.nexusUserName ? data.nexusUserName.trim() : "",
          nexusURL: data.nexusURL ? data.nexusURL.trim() : "",
          nexusKey: data.nexusKey ? data.nexusKey.trim() : "",
          nexusSecret: data.nexusSecret ? data.nexusSecret.trim() : "",
        };
        return updateNexusPaymentSettings({ variables: trimmedData });
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form
          isLoading={updateNexusPaymentSettingsResult.loading}
          onSubmit={handleSubmit}
        >
          <FormInput
            label="Client Code (Integration Package Name)"
            name="nexusClientCode"
            width={300}
          />
          <FormInput label="Nexus User Name" name="nexusUserName" />
          <FormInput
            label="Nexus URL (ex: https://regentpartnersuat.nexuspayables.com)"
            name="nexusURL"
            width={500}
          />
          <FormInput label="API Client Key" name="nexusKey" width={300} />
          <FormInput
            label="API Client Secret"
            name="nexusSecret"
            width={300}
            type="password"
          />
        </Form>
      )}
    </Formik>
  );
}

const UPDATE_ORGANIZATION_NEXUS_PAYMENT_SETTINGS_MUTATION = gql`
  mutation UpdateOrganizationNexusPaymentSettings(
    $id: String!
    $nexusClientCode: String
    $nexusUserName: String
    $nexusURL: String
    $nexusKey: String
    $nexusSecret: String
  ) {
    updateOrganizationNexusPaymentSettings(
      id: $id
      nexusClientCode: $nexusClientCode
      nexusUserName: $nexusUserName
      nexusURL: $nexusURL
      nexusKey: $nexusKey
      nexusSecret: $nexusSecret
    ) {
      id
      nexusClientCode
      nexusUserName
      nexusURL
      nexusKey
      nexusSecret
    }
  }
`;
