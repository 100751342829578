import React from "react";
import { Pane } from "evergreen-ui";
import { majorScale } from "helpers/utilities";

const NavBar = (props) => (
  <Pane
    backgroundColor="white"
    borderBottom
    elevation={1}
    is="nav"
    padding={majorScale(2)}
    position="sticky"
    top={0}
    {...props}
  />
);

export default NavBar;
