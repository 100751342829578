import i18next from "i18next";
import translation from "en.json";

export const initI18n = () =>
  i18next.init({
    keySeparator: ".",
    lng: "en",
    resources: {
      en: { translation },
    },
  });
