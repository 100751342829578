import React from "react";
import { Formik } from "formik";
import {
  Form,
  FormCheckbox,
  HelpIcon,
  Icon,
  Pane,
  Paragraph,
  Select,
  Tooltip,
} from "components/materials";
import { majorScale, minorScale, t } from "helpers/utilities";
import { NOTIFICATION_FREQUENCY, NOTIFICATION_TYPE } from "helpers/enums";

const NOTIFICATION_TYPES_WITH_PROJECT_SCOPING = [
  "DRAW_SUBMITTED",
  "DOCUMENTS_RECEIVED_VIA_EMAIL",
];

const NOTIFICATION_TYPES_WITH_FREQUENCY = ["ACTIVITY_SUMMARY"];

const NOTIFICATION_TYPES_WITH_DAY_OFFSET = ["NO_DRAWS_RECEIVED"];

function initialValues(userNotificationSettings) {
  return {
    notificationSettings: Object.values(NOTIFICATION_TYPE).reduce(
      (acc, type) => {
        const userSetting = userNotificationSettings.find(
          ({ notificationType }) => notificationType === type
        );

        const frequency = NOTIFICATION_TYPES_WITH_FREQUENCY.includes(type)
          ? userSetting?.frequency ?? NOTIFICATION_FREQUENCY.MONTHLY
          : null;

        const dayOffset = NOTIFICATION_TYPES_WITH_DAY_OFFSET.includes(type)
          ? userSetting?.dayOffset ?? 45
          : null;

        return {
          ...acc,
          [type]: {
            enabled: userSetting?.enabled ?? false,
            notificationType: type,
            frequency,
            dayOffset,
          },
        };
      },
      {}
    ),
  };
}

export function NotificationSettingsForm({
  user,
  updateNotificationSettings,
  result,
}) {
  function onSubmit({ notificationSettings }) {
    const sanitizedNotificationSettings = Object.values(
      notificationSettings
    ).map((setting) => {
      const frequency = NOTIFICATION_TYPES_WITH_FREQUENCY.includes(
        setting.notificationType
      )
        ? setting.frequency ?? NOTIFICATION_FREQUENCY.MONTHLY
        : null;

      const dayOffset = NOTIFICATION_TYPES_WITH_DAY_OFFSET.includes(
        setting.notificationType
      )
        ? setting.dayOffset ?? 45
        : null;

      return {
        ...setting,
        frequency,
        dayOffset,
      };
    });

    updateNotificationSettings({
      variables: {
        userId: user.id,
        notificationSettings: sanitizedNotificationSettings,
      },
    });
  }

  const supportedNotifications = user.organization.permissionConfig
    ?.taskManagement
    ? Object.values(NOTIFICATION_TYPE)
    : Object.values(NOTIFICATION_TYPE).filter(
        (notificationType) => notificationType !== "WEEKLY_TIMELINE_REPORT"
      );

  return (
    <Formik
      onSubmit={(values) => onSubmit(values)}
      initialValues={initialValues(user.notificationSettings)}
      enableReinitialize
    >
      {(form) => {
        return (
          <Form
            onSubmit={form.handleSubmit}
            textSubmit="Update Notification Settings"
            isLoading={result.loading}
          >
            {supportedNotifications.map((notificationType) => (
              <Pane display="flex" alignItems="center" height={28}>
                <Pane display="inline">
                  <FormCheckbox
                    label={t(`notificationTypes.${notificationType}`)}
                    name={`notificationSettings.${notificationType}.enabled`}
                  />
                </Pane>
                {notificationType === NOTIFICATION_TYPE.NO_DRAWS_RECEIVED && (
                  <Paragraph size={300}>
                    Day Offset:{" "}
                    {
                      form.values.notificationSettings[notificationType]
                        ?.dayOffset
                    }
                  </Paragraph>
                )}
                {NOTIFICATION_TYPES_WITH_PROJECT_SCOPING.includes(
                  notificationType
                ) && (
                  <Pane flex="1" marginTop={-2}>
                    <Tooltip content="This notification must also be enabled on a per-project basis">
                      <Icon
                        icon={HelpIcon}
                        color="default"
                        marginLeft={majorScale(1)}
                        size={minorScale(3)}
                      />
                    </Tooltip>
                  </Pane>
                )}
                {NOTIFICATION_TYPES_WITH_FREQUENCY.includes(
                  notificationType
                ) && (
                  <Pane marginTop={-2}>
                    <Select
                      marginLeft={majorScale(1)}
                      height={24}
                      width={150}
                      defaultValue={
                        form.values.notificationSettings[notificationType]
                          .frequency
                      }
                      onChange={(e) => {
                        const currentSetting =
                          form.values.notificationSettings[notificationType];
                        const newSetting = {
                          ...currentSetting,
                          enabled: true,
                          frequency: e.target.value,
                        };

                        form.setFieldValue(
                          `notificationSettings.${notificationType}`,
                          newSetting
                        );
                      }}
                    >
                      {[
                        NOTIFICATION_FREQUENCY.DAILY,
                        NOTIFICATION_FREQUENCY.WEEKLY,
                        NOTIFICATION_FREQUENCY.MONTHLY,
                      ].map((frequency) => (
                        <option value={frequency}>
                          {t(`notificationFrequencies.${frequency}`)}
                        </option>
                      ))}
                    </Select>
                  </Pane>
                )}
              </Pane>
            ))}
          </Form>
        );
      }}
    </Formik>
  );
}
