import React, { useState, Fragment } from "react";
import auth from "auth";
import ReactJson from "react-json-view";
import { Formik } from "formik";
import {
  Form,
  FormInput,
  Heading,
  Paragraph,
  Pane,
} from "components/materials";
import { toaster, majorScale } from "helpers/utilities";

async function fetchJinjaData(drawId, setJinjaData, setLoading) {
  setLoading(true);

  const authorization = auth.getAuthorization();
  const apiUrl = process.env.REACT_APP_ADMIN_API_URI;

  const response = await fetch(`${apiUrl}/${drawId}/jinja-data`, {
    headers: { authorization },
  });

  if (response.ok) {
    const data = await response.json();
    setJinjaData(data);
  } else if (response.status === 404) {
    toaster.danger("Draw not found. Please check the ID and try again");
  } else {
    toaster.danger(
      "There was an error processing your request. Please try again."
    );
  }

  setLoading(false);
}

export default function JinjaPage() {
  const [jinjaData, setJinjaData] = useState();
  const [loading, setLoading] = useState(false);
  return (
    <Fragment>
      <Pane marginBottom={majorScale(3)}>
        <Heading>Jinja Data Viewer</Heading>
        <Paragraph>
          Enter a draw ID to see all the data available to Jinja for that
          specific draw
        </Paragraph>
      </Pane>
      <Formik
        initialValues={{}}
        onSubmit={({ drawId }) =>
          fetchJinjaData(drawId, setJinjaData, setLoading)
        }
      >
        {(form) => (
          <Form isLoading={loading} onSubmit={form.handleSubmit}>
            <FormInput label="Draw ID" name="drawId" required />
          </Form>
        )}
      </Formik>
      {jinjaData && (
        <Pane marginTop={majorScale(3)}>
          <ReactJson src={jinjaData.data} name={false} collapsed={1} />
        </Pane>
      )}
    </Fragment>
  );
}
