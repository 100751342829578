import React from "react";
import gql from "graphql-tag";
import { Query } from "@apollo/react-components";
import { DashboardMetrics, RecentProjectsTable } from "components/templates";
import { Loading, Segment } from "components/materials";

const renderContent = ({ data, error, loading }) => {
  if (loading) return <Loading />;
  if (error) return null;
  const { projectMetrics, projects } = data;
  return (
    <React.Fragment>
      <Segment>
        <DashboardMetrics metrics={projectMetrics} />
      </Segment>
      <Segment heading="Recent Projects">
        <RecentProjectsTable projects={projects} />
      </Segment>
    </React.Fragment>
  );
};

const HomePage = () => <Query query={HomePage.Query}>{renderContent}</Query>;

HomePage.Query = gql`
  query HomePageQuery {
    projectMetrics {
      name
      type
      labels
      target
      datasets {
        label
        data
      }
    }
    projects(limit: 20) {
      id
      createdByUser {
        id
        email
      }
      insertedAt
      name
      streetAddress
      startDate
      amount
      requestedAmount
      disbursedAmount
      owner {
        id
        name
        type
      }
      recentDraw {
        id
        name
      }
      payApplicationCount
    }
  }
`;

export default HomePage;
