export const DRAW_STATE = {
  FUNDED: "FUNDED",
  INCOMPLETE: "INCOMPLETE",
  PAUSED: "PAUSED",
  PENDING_RESUBMISSION: "PENDING_RESUBMISSION",
  RECEIVED: "RECEIVED",
  RECEIVED_AND_CHANGED: "RECEIVED_AND_CHANGED",
  REJECTED: "REJECTED",
  STARTED: "STARTED",
  SUBMITTED: "SUBMITTED",
  WITHDRAWN: "WITHDRAWN",
};
