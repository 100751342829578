import React from "react";
import PropTypes from "prop-types";
import { FormFieldLabel, HelpIcon, Icon, Pane, Tooltip } from "evergreen-ui";
import { FieldArray } from "formik";

const FormFieldArray = ({ showAstrix, containerProps, ...props }) => (
  <React.Fragment>
    <Pane display="flex" alignItems="center" {...containerProps}>
      <FormFieldLabel isAstrixShown={showAstrix} marginRight={5}>
        {props.label}
      </FormFieldLabel>
      {props.helpText && (
        <Tooltip content={props.helpText}>
          <Icon icon={HelpIcon} size={15} />
        </Tooltip>
      )}
    </Pane>
    <FieldArray {...props} />
  </React.Fragment>
);

FormFieldArray.propTypes = {
  helpText: PropTypes.string,
  showAstrix: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

FormFieldArray.defaultProps = {
  helpText: null,
  showAstrix: false,
};

export default FormFieldArray;
