import React from "react";
import PropTypes from "prop-types";
import { AppLayout } from "components/templates";

const AppLayoutPage = (props) => <AppLayout>{props.children}</AppLayout>;

AppLayoutPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLayoutPage;
