import React from "react";
import {
  Button,
  Dialog,
  Heading,
  Pane,
  Paragraph,
  Text,
  TextInput,
} from "components/materials";
import { majorScale, t } from "helpers/utilities";
import { NOTIFICATION_TYPE } from "helpers/enums";

// Note that env vars are always strings
const CONFIRM_NOTIFICATION_SENDING =
  process.env.REACT_APP_CONFIRM_NOTIFICATION_SENDING === "true";

export function SendNotifications({ sendNotification, user }) {
  const [confirmNotificationType, setConfirmNotificationType] =
    React.useState(null);
  const [sendConfirmed, setSendConfirmed] = React.useState(false);

  function handleSendNotification(notificationType) {
    sendNotification({ variables: { userId: user.id, notificationType } });
  }

  return (
    <React.Fragment>
      <Pane display="flex">
        <Pane width={500} border="1px solid gray" padding={12}>
          <Heading size={400}>Weekly Timeline Report</Heading>
          <Pane>
            <Text>
              The weekly timeline report normally goes out on Monday of each
              week. Use this button to send the report now.
            </Text>
          </Pane>
          <Button
            marginTop={majorScale(1)}
            appearance="primary"
            onClick={() =>
              CONFIRM_NOTIFICATION_SENDING
                ? setConfirmNotificationType(
                    NOTIFICATION_TYPE.WEEKLY_TIMELINE_REPORT
                  )
                : handleSendNotification(
                    NOTIFICATION_TYPE.WEEKLY_TIMELINE_REPORT
                  )
            }
          >
            Send Weekly Timeline Report
          </Button>
        </Pane>

        <Pane
          marginLeft={majorScale(2)}
          width={500}
          border="1px solid gray"
          padding={12}
        >
          <Heading size={400}>Activity Summary</Heading>
          <Pane>
            <Text>
              The activity summary email goes out at a variable frequency
              depending on user configuration. Use this button to send the
              report now.
            </Text>
          </Pane>
          <Button
            marginTop={majorScale(1)}
            appearance="primary"
            onClick={() =>
              CONFIRM_NOTIFICATION_SENDING
                ? setConfirmNotificationType(NOTIFICATION_TYPE.ACTIVITY_SUMMARY)
                : handleSendNotification(NOTIFICATION_TYPE.ACTIVITY_SUMMARY)
            }
          >
            Send Activity Summary
          </Button>
        </Pane>
      </Pane>
      <Pane display="flex" marginTop={majorScale(2)}>
        <Pane width={500} border="1px solid gray" padding={12}>
          <Heading size={400}>Stale Project Reminder</Heading>
          <Pane>
            <Text>
              This notification is typically sent daily at 8am to reviewers on
              projects where there is no active draw and a draw has not been
              marked funded in the last X days. For testing purposes, you can
              send the email here for the most recently created project the user
              has access to, ignoring the usual restrictions.
            </Text>
          </Pane>
          <Button
            marginTop={majorScale(1)}
            appearance="primary"
            onClick={() =>
              CONFIRM_NOTIFICATION_SENDING
                ? setConfirmNotificationType(
                    NOTIFICATION_TYPE.NO_DRAWS_RECEIVED
                  )
                : handleSendNotification(NOTIFICATION_TYPE.NO_DRAWS_RECEIVED)
            }
          >
            Send Stale Project Notification
          </Button>
        </Pane>
      </Pane>
      {!!confirmNotificationType && (
        <Dialog
          isShown
          title="Are you sure you would like to send a notification to this user?"
          intent="danger"
          isConfirmDisabled={!sendConfirmed}
          onConfirm={(close) => {
            handleSendNotification(confirmNotificationType);
            close();
          }}
          onCloseComplete={() => {
            setSendConfirmed(false);
            setConfirmNotificationType(null);
          }}
          confirmLabel="Confirm"
        >
          <Paragraph
            marginBottom={majorScale(1)}
          >{`Please type "SEND" to confirm that you would like to send ${
            user.name
          } a ${t(
            `notificationTypes.${confirmNotificationType}`
          )} email`}</Paragraph>
          <TextInput
            onChange={(e) =>
              e.target.value === "SEND"
                ? setSendConfirmed(true)
                : setSendConfirmed(false)
            }
          />
        </Dialog>
      )}
    </React.Fragment>
  );
}
