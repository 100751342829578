import { ApolloClient, ApolloError } from "apollo-client";
import { ApolloLink } from "apollo-link";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createLink } from "apollo-absinthe-upload-link";
import { captureSentry } from "sentry";
import { toaster } from "helpers/utilities";
import { get, some } from "lodash";
import auth from "auth";
import introspectionQueryResultData from "fragmentTypes.json";

const uri = process.env.REACT_APP_GRAPHQL_URI;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });

const defaultOptions = { watchQuery: { fetchPolicy: "network-only" } };

const link = ApolloLink.from([
  setContext(() => {
    const authorization = auth.getAuthorization();
    return { headers: { authorization } };
  }),
  onError((error) => {
    const apolloError = new ApolloError(error);
    toaster.danger(apolloError.message);
    captureSentry(apolloError);
    const statusCode = get(error, "networkError.statusCode");
    const unauthorized = some(get(error, "graphQLErrors"), [
      "message",
      "unauthorized",
    ]);
    if (statusCode === 401 || unauthorized) {
      auth
        .refresh()
        .then(() => {
          const authorization = auth.getAuthorization();
          error.operation.setContext({ headers: { authorization } });
          return error.forward(error.operation);
        })
        .catch(() => {
          window.location.assign("/logout");
        });
    }
  }),
  createLink({ uri }),
]);

const client = new ApolloClient({ cache, defaultOptions, link });

export default client;
