import React from "react";
import { createRoot } from "react-dom/client";
import { ApolloProvider } from "@apollo/react-components";
import { BrowserRouter } from "react-router-dom";
import { initSentry } from "sentry";
import { initI18n } from "i18n";
import { initInactivity } from "inactivity";
import client from "client";
import routes from "routes";
import "normalize.css";

const container = document.getElementById("root");
const root = createRoot(container);
initSentry();
initI18n();
initInactivity();

root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>{routes}</BrowserRouter>
  </ApolloProvider>
);
