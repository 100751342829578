import gql from "graphql-tag";

export const UPDATE_ORGANIZATION_POST_PAYMENT_SETTINGS_MUTATION = gql`
  mutation UpdateOrganizationPostPaymentSettings(
    $id: String!
    $avidPostPaymentEmailAddress: String
  ) {
    updateOrganizationPostPaymentSettings(
      id: $id
      avidPostPaymentEmailAddress: $avidPostPaymentEmailAddress
    ) {
      id
      avidPostPaymentEmailAddress
    }
  }
`;
