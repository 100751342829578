import React from "react";
import gql from "graphql-tag";
import { Mutation, Query } from "@apollo/react-components";
import { Formik } from "formik";
import { Loading, Segment } from "components/materials";
import { ProjectTemplateForm } from "components/templates";
import { toaster } from "helpers/utilities";

const renderContent = ({ data, error, loading }) => {
  if (loading) return <Loading />;
  if (error) return null;

  const {
    organization: { name, projectTemplate, projectTemplates },
  } = data;

  const organizationCustomFields = projectTemplates.flatMap(
    ({ customFields }) => {
      return customFields;
    }
  );

  return (
    <Segment heading="Basic Info">
      <Mutation
        mutation={ProjectTemplateEditPage.UpdateProjectTemplate}
        onCompleted={() => {
          toaster.success("Project template updated");
        }}
      >
        {(mutation, result) => {
          return (
            <Formik
              initialValues={ProjectTemplateForm.initialValuesEdit(
                projectTemplate
              )}
              validate={ProjectTemplateForm.validate}
              onSubmit={ProjectTemplateForm.onSubmit(mutation)}
            >
              {(form) => (
                <ProjectTemplateForm
                  form={form}
                  result={result}
                  organizationCustomFields={organizationCustomFields}
                  organizationName={name}
                />
              )}
            </Formik>
          );
        }}
      </Mutation>
    </Segment>
  );
};

const ProjectTemplateEditPage = (props) => {
  return (
    <Query query={ProjectTemplateEditPage.Query} variables={props.match.params}>
      {renderContent}
    </Query>
  );
};

ProjectTemplateEditPage.Query = gql`
  query ProjectTemplateEditPageQuery(
    $organizationId: String!
    $projectTemplateId: String!
  ) {
    organization(id: $organizationId) {
      id
      name
      projectTemplates {
        id
        customFields {
          id
          label
          options
          reportTemplateFieldName
          type
        }
      }
      projectTemplate(id: $projectTemplateId) {
        id
        name
        icon
        customFields {
          id
          inUse
          label
          options
          reportTemplateFieldName
          type
          valuesInUse
        }
        rules {
          id
          name
          type
          enabled
        }
      }
    }
  }
`;

ProjectTemplateEditPage.UpdateProjectTemplate = gql`
  mutation UpdateProjectTemplate(
    $id: String!
    $name: String!
    $icon: ProjectIcon
    $rules: [ProjectTemplateRuleInput]!
    $customFields: [TemplateCustomFieldInput]
  ) {
    updateProjectTemplate(
      id: $id
      name: $name
      icon: $icon
      rules: $rules
      customFields: $customFields
    ) {
      id
      customFields {
        id
        label
        options
        reportTemplateFieldName
        type
      }
    }
  }
`;

export default ProjectTemplateEditPage;
