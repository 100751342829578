import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Badge,
  Button,
  CrossIcon,
  Dialog,
  Divider,
  DragAndDrop,
  DragHandleHorizontalIcon,
  Form,
  FormCheckbox,
  FormDropdown,
  FormFieldArray,
  FormGroup,
  FormInput,
  Icon,
  IconButton,
  InfoSignIcon,
  Pane,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  Text,
  Tooltip,
  AirplaneIcon,
  BankAccountIcon,
  BriefcaseIcon,
  BuildIcon,
  CalculatorIcon,
  CellTowerIcon,
  FlagIcon,
  GlassIcon,
  GlobeIcon,
  HomeIcon,
  OfficeIcon,
  OilFieldIcon,
  PeopleIcon,
  ShopIcon,
  TaxiIcon,
  TrainIcon,
  WalkIcon,
} from "components/materials";
import { FieldArray } from "formik";
import {
  isBlank,
  majorScale,
  minorScale,
  preventEventBubbling,
  t,
} from "helpers/utilities";
import { RULE_TYPE } from "helpers/enums";
import { find, get, includes, isEmpty, map, set, uniqBy } from "lodash";
import { v4 as newUUID } from "uuid";
import { css } from "glamor";

const PROJECT_ICON_COMPONENTS = {
  AIRPLANE: AirplaneIcon,
  BANK_ACCOUNT: BankAccountIcon,
  BRIEFCASE: BriefcaseIcon,
  BUILD: BuildIcon,
  CALCULATOR: CalculatorIcon,
  CELL_TOWER: CellTowerIcon,
  FLAG: FlagIcon,
  GLASS: GlassIcon,
  GLOBE: GlobeIcon,
  HOME: HomeIcon,
  OFFICE: OfficeIcon,
  OIL_FIELD: OilFieldIcon,
  PEOPLE: PeopleIcon,
  SHOP: ShopIcon,
  TAXI: TaxiIcon,
  TRAIN: TrainIcon,
  WALK: WalkIcon,
};

const AUTOMATED_RULES = [
  "conditional_lien_waivers_must_match_invoices",
  "cover_sheet_reconciles_with_summary",
  "draw_does_not_have_notice_to_owner",
  "draw_has_cover_sheet",
  "draw_has_inspection_report",
  "draw_has_title_endorsement",
  "duplicate_invoice_numbers",
  "first_hard_cost_draw_requires_foundation_endorsement",
  "general_contractor_pay_applications_covered_by_unconditional_lien_waivers",
  "inspection_line_item_percent_complete",
  "inspection_within_x_percent_of_hard_costs",
  "interest_reserves_remaining",
  "invoices_reconcile_with_summary",
  "line_item_amount_does_not_exceed_agreement_amount",
  "line_items_have_budget_remaining_to_pay_outstanding_agreements",
  "overdrawn_line_items",
  "overdue_tasks",
  "overused_contingency",
  "pay_applications_covered_by_unconditional_lien_waivers",
  "project_behind_schedule",
  "project_has_builders_risk_certificate",
  "project_has_general_liability_insurance_certificate",
  "project_has_performance_bond",
  "request_notice_of_completion_and_certificate_of_occupancy",
  "require_bill_of_sale_for_stored_materials",
  "require_change_order_for_draw_adjustments",
  "require_change_order_for_line_item_adjustments_over_x",
  "require_insurance_for_stored_materials",
  "require_lien_waiver_for_hard_costs_over_x",
  "require_lien_waiver_if_vendor_has_notice_to_owner",
  "require_retainage_release_on_reduced_retainage",
  "retainage_released",
  "retainage_requested_matches_agreement_retainage",
  "subcontractor_invoices_match_gc_pay_application",
  "vendor_line_item_amount_does_not_exceed_agreement_amount",
];

const DEFAULT_ENABLED_AUTOMATED_RULES = [
  "cover_sheet_reconciles_with_summary",
  "draw_has_cover_sheet",
  "duplicate_invoice_numbers",
  "invoices_reconcile_with_summary",
  "overdrawn_line_items",
  "overused_contingency",
  "retainage_released",
  "require_change_order_for_draw_adjustments",
];

const DEFAULT_MANUAL_RULES = [
  "Line items have sufficient remaining budget",
  "G702 has been signed and notarized",
  "Large invoices (>$25k) have been checked",
  "Previous draw issues have been reviewed",
  "Loan requirements have been met",
];

const commonFieldOptions = [
  {
    id: newUUID(),
    label: "Number of units",
    name: "Number of units (common)",
    type: "NUMBER",
    reportTemplateFieldName: "number_of_units",
  },
  {
    id: newUUID(),
    label: "Construction type",
    name: "Construction type (common)",
    type: "TEXT",
    reportTemplateFieldName: "construction_type",
  },
  {
    id: newUUID(),
    label: "GC contract type",
    name: "GC contract type (common)",
    type: "TEXT",
    reportTemplateFieldName: "gc_contract_type",
  },
];

// Previously the New Custom Field option in the dropdown would have a null id temporarily
// and that drove some behaviors. With some package updates that id can no longer be null,
// so we are setting a temporary value that will be replaced when the new field is saved.
const temporaryId = "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa";

const newCustomFieldOption = [
  {
    id: temporaryId,
    label: "Add New Custom Field",
    name: "Add New Custom Field",
    options: [],
    type: "TEXT",
    reportTemplateFieldName: "",
  },
];

const fieldTypeOptions = [
  { id: "TEXT", name: "Text Field" },
  { id: "BOOLEAN", name: "Checkbox" },
  { id: "DATE", name: "Date" },
  { id: "DROPDOWN", name: "Dropdown" },
  { id: "MONEY", name: "Currency (Dollars)" },
  { id: "PERCENT", name: "Percent" },
  { id: "NUMBER", name: "Number" },
  { id: "INTEGER", name: "Count (Positive Whole Numbers Only)" },
];

const RuleContainer = ({ type, name, children }) => (
  <TableRow
    alignItems="center"
    id="ruleContainer"
    {...css({
      "&#ruleContainer:hover": {
        backgroundColor: "#bcc3ca",
      },
    })}
  >
    <TableCell justifyContent="space-between">
      <Text>{name}</Text>
      <Badge color={type === RULE_TYPE.MANUAL ? "green" : "blue"}>{type}</Badge>
    </TableCell>
    {children}
  </TableRow>
);

RuleContainer.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

function prepareFieldItems(fields) {
  return fields.map((field, index) => ({
    ...field,
    key: field.id,
    value: field,
    index,
  }));
}

function CustomField({
  customFieldOptions,
  index,
  item,
  itemHeight,
  form,
  onRemove,
  provided,
  setInUseWarningProps,
  setNewCustomFieldIndex,
  setOpenAddCustomField,
}) {
  const isCommonField = find(
    commonFieldOptions,
    (option) => option.label === item.label
  );
  const errorMessage = get(form, ["errors", "customFields", index, "id"]);

  const firstOccurrenceOfField =
    index ===
    form.values.customFields.findIndex((field) => field.label === item.label);

  return (
    <Pane
      height={itemHeight}
      minWidth={1500}
      paddingTop={majorScale(1)}
      borderBottom
      display="flex"
      alignItems="center"
    >
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={majorScale(6)}
        marginRight={majorScale(4)}
        {...provided.dragHandleProps}
      >
        <Icon icon={DragHandleHorizontalIcon} size={20} />
      </Pane>
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="#DDEBF7"
        color="#084B8A"
        height={40}
        width={40}
        borderRadius={20}
        marginRight={majorScale(4)}
      >
        {item.index + 1}
      </Pane>
      <Pane display="flex" alignItems="center">
        <FormDropdown
          label=""
          validationMessage={errorMessage}
          name={`customFields.${index}.id`}
          marginTop={minorScale(3)}
          maxWidth={250}
          onChange={({ target: { value } }) => {
            const selectedOption = customFieldOptions.find(
              (option) => option.id === value || option.name === value
            );
            setNewCustomFieldIndex(index);
            if (selectedOption.name === "Add New Custom Field") {
              setOpenAddCustomField(true);
            }
            form.setFieldValue(`customFields.${index}`, {
              ...selectedOption,
              position: index,
            });
          }}
          options={customFieldOptions}
        />
      </Pane>
      <Text marginLeft={majorScale(2)} width={225}>
        Report Template Name: {item.reportTemplateFieldName}
      </Text>
      {!isCommonField && (
        <Button
          marginLeft={majorScale(2)}
          onClick={() => {
            setNewCustomFieldIndex(index);
            setOpenAddCustomField(true);
          }}
          type="button"
        >
          Edit
        </Button>
      )}
      {item.type === "DROPDOWN" && (
        <Paragraph marginLeft={majorScale(1)}>
          {`Options: ${item.options.join(", ")}`}
        </Paragraph>
      )}
      <IconButton
        type="button"
        icon={CrossIcon}
        appearance="minimal"
        marginLeft={majorScale(2)}
        onClick={(e) => {
          preventEventBubbling(e);
          if (item.inUse && firstOccurrenceOfField) {
            setInUseWarningProps({
              confirmLabel: "Delete Custom Field",
              content: t("helpText.fieldInUse", { label: item.label }),
              title: "Field In Use",
              onCancel: () => setInUseWarningProps({}),
              onConfirm: () => {
                onRemove(item.key);
                setInUseWarningProps({});
              },
            });
          } else {
            onRemove(item.key);
          }
        }}
      />
    </Pane>
  );
}
const ProjectTemplateForm = ({
  form,
  result,
  organizationCustomFields,
  organizationName,
}) => {
  const [openAddCustomField, setOpenAddCustomField] = useState(false);
  const [newCustomFieldIndex, setNewCustomFieldIndex] = useState(null);
  const [inUseWarningProps, setInUseWarningProps] = useState({});

  const projectTypeCustomFields = form.values.customFields;
  const otherCustomFields = organizationCustomFields
    .filter((field) => !projectTypeCustomFields.includes(field))
    .map((customField) => ({
      ...customField,
      label: customField.label,
      name: `${customField.label} (${organizationName})`,
    }));
  const preparedFields = () => {
    const { customFields } = form.values;
    return uniqBy(
      commonFieldOptions
        .concat(otherCustomFields)
        .filter(({ label }) =>
          customFields.every((templateField) => templateField.label !== label)
        )
        .concat(customFields)
        .concat(newCustomFieldOption),
      "id"
    );
  };
  const [customFieldOptions, setCustomFieldOptions] =
    React.useState(preparedFields);
  const renderAutomatedRules = () => {
    return form.values.automatedRules.map(({ name }, index) => {
      return (
        <RuleContainer
          key={name}
          name={t(`rules.${name}`)}
          type={RULE_TYPE.AUTOMATED}
        >
          <FormCheckbox
            name={`automatedRules.${index}.available`}
            label="Available for use"
          />
          <FormCheckbox
            name={`automatedRules.${index}.enabled`}
            label="Enabled by default"
          />
        </RuleContainer>
      );
    });
  };

  const renderCustomRules = ({ push }) => {
    const customRules = form.values.customRules.map(
      ({ name, isNew }, index) => {
        return isNew ? (
          <FormInput key="custom-rule" name={`customRules.${index}.name`} />
        ) : (
          <RuleContainer key={name} type={RULE_TYPE.MANUAL} name={name}>
            <FormCheckbox
              label="Available for use"
              name={`customRules.${index}.available`}
            />
            <FormCheckbox
              name={`customRules.${index}.enabled`}
              label="Enabled by default"
            />
          </RuleContainer>
        );
      }
    );

    const addNewRule = (
      <Button
        key="addNewRule"
        onClick={(e) => {
          e.preventDefault();
          push({
            available: true,
            enabled: true,
            type: RULE_TYPE.MANUAL,
            isNew: true,
          });
        }}
        marginBottom={majorScale(3)}
      >
        Add another check
      </Button>
    );

    return (
      <React.Fragment>
        {customRules}
        {addNewRule}
      </React.Fragment>
    );
  };

  const renderCustomFields = ({ push, remove }) => {
    const handleUpdate = (updatedValues) => {
      form.setFieldValue(
        "customFields",
        updatedValues.map(({ value }, index) => {
          return { ...value, position: index };
        })
      );
    };

    const handleRemove = ({ index }) => {
      remove(index);
    };
    const customFields =
      form.values.customFields.length === 0 ? (
        <Pane marginY={majorScale(2)}>
          <Text>None</Text>
        </Pane>
      ) : (
        <DragAndDrop
          items={prepareFieldItems(form.values.customFields)}
          onUpdate={handleUpdate}
          onRemove={handleRemove}
          itemHeight={175}
          renderItem={({ index, item, itemHeight, onRemove, provided }) => (
            <CustomField
              customFieldOptions={customFieldOptions}
              index={index}
              item={item}
              itemHeight={itemHeight}
              form={form}
              provided={provided}
              onRemove={onRemove}
              setInUseWarningProps={setInUseWarningProps}
              setNewCustomFieldIndex={setNewCustomFieldIndex}
              setOpenAddCustomField={setOpenAddCustomField}
            />
          )}
        />
      );

    const addNewCustomField = (
      <Button
        key="addCustomField"
        onClick={(e) => {
          e.preventDefault();
          push(commonFieldOptions[0]);
        }}
        marginTop={majorScale(3)}
        marginBottom={majorScale(3)}
      >
        Add custom variable
      </Button>
    );

    return (
      <React.Fragment>
        {customFields}
        {addNewCustomField}
      </React.Fragment>
    );
  };

  const icons = map(PROJECT_ICON_COMPONENTS, (icon, key) => ({
    label: <Icon icon={icon} />,
    value: key,
  }));

  function InUseWarningModal() {
    return (
      <Dialog
        intent="danger"
        isShown={!isEmpty(inUseWarningProps)}
        {...inUseWarningProps}
      >
        <Pane>{inUseWarningProps.content}</Pane>
      </Dialog>
    );
  }

  const customFieldModal = () => {
    const showDropdownOptions =
      get(form.values, `customFields.${newCustomFieldIndex}.type`) ===
      "DROPDOWN";
    const isNewItem =
      get(form.values, `customFields.${newCustomFieldIndex}.id`) ===
      temporaryId;
    const updatedUUID = newUUID(); // This is going to be the new UUID for the new custom field
    return (
      <Dialog
        isShown={openAddCustomField}
        title={isNewItem ? "New Variable" : "Edit Variable"}
        onCancel={() => {
          setNewCustomFieldIndex(null);
          setOpenAddCustomField(false);
        }}
        onConfirm={() => {
          let itemId = get(
            form.values,
            `customFields.${newCustomFieldIndex}.id`
          );
          if (itemId === temporaryId) {
            itemId = updatedUUID;
          }
          const { customFields } = form.values;
          const newItem = {
            ...customFields[newCustomFieldIndex],
            id: itemId,
            name: get(customFields, `${newCustomFieldIndex}.label`),
            value: get(customFields, `${newCustomFieldIndex}.label`),
          };

          if (isNewItem) {
            setCustomFieldOptions(customFieldOptions.concat([newItem]));
          } else {
            const indexToUpdate = customFieldOptions.findIndex(
              (option) => option.id === itemId
            );
            setCustomFieldOptions([
              ...customFieldOptions.slice(0, indexToUpdate),
              newItem,
              ...customFieldOptions.slice(indexToUpdate + 1),
            ]);
            // see comment below
            form.setFieldValue(
              `customFields.${newCustomFieldIndex}.id`,
              updatedUUID
            );
          }
          // yuck, but the only hack that works to get the select to recognize it needs to rerender
          // (change the id it's paired to to a new value and then a 'tick' later, set it correctly)
          setTimeout(() =>
            form.setFieldValue(`customFields.${newCustomFieldIndex}.id`, itemId)
          );

          setNewCustomFieldIndex(null);
          setOpenAddCustomField(false);
        }}
        width={showDropdownOptions ? 700 : 400}
      >
        <Pane display="flex" justifyContent="space-between">
          <Pane
            marginLeft={showDropdownOptions ? 0 : minorScale(15)}
            width={showDropdownOptions ? 200 : "100%"}
          >
            <FormInput
              label="Label"
              name={`customFields.${newCustomFieldIndex}.label`}
            />
            <FormDropdown
              name={`customFields.${newCustomFieldIndex}.type`}
              label="Input Type"
              maxWidth={250}
              options={fieldTypeOptions}
            />
            <FormInput
              label="Report Template Field Name"
              name={`customFields.${newCustomFieldIndex}.reportTemplateFieldName`}
            />
          </Pane>
          {showDropdownOptions && (
            <Pane width={400} maxHeight={225} overflowY="scroll">
              <Text>
                {`${get(
                  form.values,
                  `customFields.${newCustomFieldIndex}.label`
                )} Dropdown Options`}
              </Text>

              <FieldArray name={`customFields.${newCustomFieldIndex}.options`}>
                {({ push, remove, swap }) => {
                  const { options, valuesInUse } =
                    form.values.customFields[newCustomFieldIndex];

                  return (
                    <React.Fragment>
                      <Button
                        marginLeft={majorScale(2)}
                        type="button"
                        onClick={() => push("")}
                      >
                        Add Option
                      </Button>
                      {options.map((option, index) => {
                        const canMoveUp = index > 0;
                        const canMoveDown = index < options.length - 1;
                        const firstOccurrenceOfValue =
                          index === options.findIndex((o) => o === option);
                        const valueInUse =
                          includes(valuesInUse, option) &&
                          firstOccurrenceOfValue;
                        return (
                          <Pane
                            display="flex"
                            marginTop={
                              index > 0 ? -majorScale(2) : minorScale(2)
                            }
                          >
                            <IconButton
                              appearance="minimal"
                              icon={CrossIcon}
                              onClick={() =>
                                valueInUse
                                  ? setInUseWarningProps({
                                      confirmLabel: "Delete Value",
                                      content: t("helpText.valueInUse", {
                                        option,
                                      }),
                                      title: "Field Value In Use",
                                      onCancel: () => setInUseWarningProps({}),
                                      onConfirm: () => {
                                        remove(index);
                                        setInUseWarningProps({});
                                      },
                                    })
                                  : remove(index)
                              }
                            />
                            <FormInput
                              disabled={valueInUse}
                              name={`customFields.${newCustomFieldIndex}.options[${index}]`}
                            />
                            {valueInUse && (
                              <Tooltip
                                content={t("helpText.valueInUseTooltip", {
                                  option,
                                })}
                              >
                                <InfoSignIcon
                                  height={24}
                                  icon={InfoSignIcon}
                                  marginTop={majorScale(1)}
                                  marginLeft={majorScale(1)}
                                />
                              </Tooltip>
                            )}
                            {canMoveDown && (
                              <IconButton
                                appearance="minimal"
                                icon={ArrowDownIcon}
                                marginTop={minorScale(1)}
                                onClick={() => swap(index, index + 1)}
                              />
                            )}
                            {canMoveUp && (
                              <IconButton
                                appearance="minimal"
                                icon={ArrowUpIcon}
                                marginTop={minorScale(1)}
                                onClick={() => swap(index - 1, index)}
                              />
                            )}
                          </Pane>
                        );
                      })}
                    </React.Fragment>
                  );
                }}
              </FieldArray>
            </Pane>
          )}
        </Pane>
      </Dialog>
    );
  };

  return (
    <Form isLoading={result.loading} onSubmit={form.handleSubmit}>
      <FormInput label="Name" name="name" required />
      <FormGroup
        label="Icon"
        name="icon"
        options={icons}
        width="75%"
        minWidth={600}
        marginBottom={majorScale(3)}
      />
      <Table border="none">
        <FormFieldArray
          label="Draw Checks"
          name="automatedRules"
          render={renderAutomatedRules}
          helpText={t(`helpText.drawChecks`)}
        />
      </Table>
      <Divider />
      <Table border="none">
        <FormFieldArray
          label="Custom Rules"
          name="customRules"
          render={renderCustomRules}
          helpText={t("helpText.customRules")}
        />
      </Table>
      <FormFieldArray
        label="Custom Fields"
        name="customFields"
        render={renderCustomFields}
        helpText={t(`helpText.customFields`)}
      />
      {customFieldModal()}
      <InUseWarningModal />
    </Form>
  );
};

ProjectTemplateForm.initialValuesNew = (organizationId) => ({
  organizationId,
  name: "",
  icon: null,
  automatedRules: map(AUTOMATED_RULES, (name) => ({
    name,
    available: true,
    enabled: DEFAULT_ENABLED_AUTOMATED_RULES.includes(name),
    type: RULE_TYPE.AUTOMATED,
  })),
  customRules: map(DEFAULT_MANUAL_RULES, (name) => ({
    name,
    available: true,
    enabled: false,
    type: RULE_TYPE.MANUAL,
  })),
  customFields: [],
});

ProjectTemplateForm.initialValuesEdit = (projectTemplate) => ({
  id: projectTemplate.id,
  name: projectTemplate.name,
  icon: get(projectTemplate, "icon"),
  automatedRules: map(AUTOMATED_RULES, (name) => {
    const foundRule =
      find(projectTemplate.rules, (rule) => rule.name === name) || null;
    return {
      name,
      available: !!foundRule,
      enabled: !!foundRule && foundRule.enabled,
      type: RULE_TYPE.AUTOMATED,
    };
  }),
  customRules: projectTemplate.rules
    .filter((rule) => rule.type === RULE_TYPE.MANUAL)
    .map((rule) => ({ ...rule, available: true, enabled: rule.enabled })),
  customFields: get(projectTemplate, "customFields", []).map(
    (
      { id, inUse, label, options, reportTemplateFieldName, type, valuesInUse },
      index
    ) => {
      const commonSelected = commonFieldOptions.find(
        (option) => option.label === label
      );
      return {
        id,
        inUse,
        label,
        name: get(commonSelected, "label", label),
        options,
        position: index,
        type,
        reportTemplateFieldName,
        valuesInUse,
      };
    }
  ),
});

ProjectTemplateForm.validate = ({ customFields, name }) => {
  const errors = {};
  if (isBlank(name)) errors.name = "Name is required";
  customFields.forEach(
    ({ label, options, reportTemplateFieldName, type }, fieldIndex) => {
      if (!RegExp(/^\S*$/).test(reportTemplateFieldName)) {
        set(
          errors,
          ["customFields", fieldIndex, "reportTemplateFieldName"],
          "cannot contain spaces"
        );
        set(
          errors,
          ["customFields", fieldIndex, "id"],
          "Template name cannot contain spaces"
        );
      }
      if (
        !!reportTemplateFieldName &&
        customFields.findIndex(
          (item) => item.reportTemplateFieldName === reportTemplateFieldName
        ) !== fieldIndex
      ) {
        set(
          errors,
          ["customFields", fieldIndex, "id"],
          "Template Name should be unique"
        );
      }
      if (
        customFields.findIndex((item) => item.label === label) !== fieldIndex
      ) {
        set(
          errors,
          ["customFields", fieldIndex, "id"],
          "Labels should be unique"
        );
      }
      if (type === "DROPDOWN" && options.length === 0) {
        set(
          errors,
          ["customFields", fieldIndex, "id"],
          "Dropdown needs at least 1 option"
        );
      }

      options.forEach((option, optionIndex) => {
        if (option === "") {
          set(
            errors,
            ["customFields", fieldIndex, "options", optionIndex],
            "Must not be blank"
          );
        } else if (
          options.findIndex((item) => item === option) !== optionIndex
        ) {
          set(
            errors,
            ["customFields", fieldIndex, "options", optionIndex],
            "Options should be unique"
          );
          set(
            errors,
            ["customFields", fieldIndex, "id"],
            "Dropdown Options should be unique"
          );
        }
      });
    }
  );
  return errors;
};

ProjectTemplateForm.onSubmit = (mutation) => (formValues) => {
  const variables = {
    ...formValues,
    rules: formValues.automatedRules
      .concat(formValues.customRules)
      .filter(({ available, name }) => !!available && !isBlank(name))
      .map(({ name, type, enabled }) => ({ name, type, enabled })),
    customFields: formValues.customFields
      .filter(({ id }) => id !== undefined)
      .map(
        ({ id, label, options, position, reportTemplateFieldName, type }) => ({
          id,
          label,
          options,
          position,
          reportTemplateFieldName,
          type,
        })
      ),
  };
  return mutation({ variables });
};

ProjectTemplateForm.propTypes = {
  form: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
  organizationCustomFields: PropTypes.array.isRequired,
  organizationName: PropTypes.string.isRequired,
};

export default ProjectTemplateForm;
