import React from "react";
import PropTypes from "prop-types";
import { Icon, Link, Table, TickIcon } from "components/materials";
import {
  formatCurrency,
  formatPercent,
  majorScale,
  t,
} from "helpers/utilities";
import { get } from "lodash";
import { format, DATE_FORMAT } from "helpers/dateHelpers";

const ProjectsTable = (props) => (
  <Table border="default">
    <Table.Head>
      <Table.TextHeaderCell>Name</Table.TextHeaderCell>
      <Table.TextHeaderCell>Street address</Table.TextHeaderCell>
      <Table.TextHeaderCell>Project type</Table.TextHeaderCell>
      <Table.TextHeaderCell>Start Date</Table.TextHeaderCell>
      <Table.TextHeaderCell>Created In Rabbet</Table.TextHeaderCell>
      <Table.TextHeaderCell>Chargeable since</Table.TextHeaderCell>
      <Table.TextHeaderCell>Status</Table.TextHeaderCell>
      <Table.TextHeaderCell width={175}>Created by</Table.TextHeaderCell>
      <Table.TextHeaderCell>Project total</Table.TextHeaderCell>
      <Table.TextHeaderCell>Amount requested</Table.TextHeaderCell>
      <Table.TextHeaderCell>Has pay applications</Table.TextHeaderCell>
      <Table.TextHeaderCell>Project progress</Table.TextHeaderCell>
      <Table.TextHeaderCell>Current draw</Table.TextHeaderCell>
      <Table.TextHeaderCell />
    </Table.Head>
    <Table.Body>
      {props.projects.map((project) => (
        <Table.Row key={project.id}>
          <Table.TextCell>
            <Link
              to={`/organizations/${props.organizationId}/projects/${project.id}/edit`}
            >
              {project.name}
            </Link>
          </Table.TextCell>
          <Table.TextCell>
            {project.streetAddress ? project.streetAddress : "-"}
          </Table.TextCell>
          <Table.Cell>
            <Link
              to={`/organizations/${props.organizationId}/project_templates/${project.template.id}/edit`}
            >
              {project.template.name}
            </Link>
          </Table.Cell>
          <Table.TextCell>
            {project.startDate !== null
              ? format(new Date(project.startDate), DATE_FORMAT)
              : null}
          </Table.TextCell>
          <Table.TextCell>
            {format(new Date(project.insertedAt), DATE_FORMAT)}
          </Table.TextCell>
          <Table.TextCell>
            {project.activatedAt
              ? format(new Date(project.activatedAt), DATE_FORMAT)
              : "Not Activated"}
          </Table.TextCell>
          <Table.TextCell>
            {t(`projectStatuses.${project.status}`)}
          </Table.TextCell>
          <Table.TextCell width={175}>
            {get(project, "createdByUser.email")}
          </Table.TextCell>
          <Table.TextCell>{formatCurrency(project.amount)}</Table.TextCell>
          <Table.TextCell>
            {formatCurrency(project.requestedAmount)}
          </Table.TextCell>
          <Table.TextCell>
            {project.payApplicationCount > 0 && (
              <React.Fragment>
                <Icon icon={TickIcon} marginRight={majorScale(1)} />
                {`(${project.payApplicationCount})`}
              </React.Fragment>
            )}
          </Table.TextCell>
          <Table.TextCell>
            {formatPercent(
              get(project, "disbursedAmount", 0) / get(project, "amount", 0)
            )}
          </Table.TextCell>
          <Table.TextCell>
            {get(project, "recentDraw.name", "-")}
          </Table.TextCell>
          <Table.Cell>
            <Link
              to={`/organizations/${props.organizationId}/projects/${project.id}/edit`}
            >
              Edit
            </Link>
          </Table.Cell>
        </Table.Row>
      ))}
      {props.viewAll ? (
        <Table.Row>
          <Table.Cell>
            <Link to={`/organizations/${props.organizationId}/projects`}>
              View All
            </Link>
          </Table.Cell>
        </Table.Row>
      ) : null}
    </Table.Body>
  </Table>
);

ProjectsTable.propTypes = {
  viewAll: PropTypes.bool,
  organizationId: PropTypes.string.isRequired,
  projects: PropTypes.array.isRequired,
};

export default ProjectsTable;
