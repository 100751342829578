export const ORGANIZATION_TYPE = {
  ACCOUNTANT: "ACCOUNTANT",
  ARCHITECT: "ARCHITECT",
  BORROWER: "BORROWER",
  BROKER: "BROKER",
  CIVIL_ENGINEER: "CIVIL_ENGINEER",
  CLIENT: "CLIENT",
  CONSTRUCTION_SERVICES: "CONSTRUCTION_SERVICES",
  CONSULTANT: "CONSULTANT",
  CONTRACTOR: "CONTRACTOR",
  DEVELOPER: "DEVELOPER",
  DEVELOPMENT_MANAGER: "DEVELOPMENT_MANAGER",
  ENGINEER: "ENGINEER",
  EQUITY_PARTNER: "EQUITY_PARTNER",
  INSPECTOR: "INSPECTOR",
  LAWYER: "LAWYER",
  LENDER: "LENDER",
  MUNICIPALITY: "MUNICIPALITY",
  OTHER: "OTHER",
  SITE_CONTACT: "SITE_CONTACT",
  SUBCONTRACTOR: "SUBCONTRACTOR",
  TITLE_COMPANY: "TITLE_COMPANY",
  VENDOR: "VENDOR",
};
