import React from "react";
import { Button, EvergreenLink, Table } from "components/materials";

export function ReportTemplatesTable({
  deleteReportTemplate,
  loading,
  reportTemplates,
}) {
  return (
    <Table border="default">
      <Table.Head>
        <Table.TextHeaderCell>Name</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {reportTemplates.map((template) => {
          return (
            <Table.Row key={template.id}>
              <Table.TextCell>{template.name}</Table.TextCell>
              <Table.Cell>
                <EvergreenLink href={template.url}>Download</EvergreenLink>
              </Table.Cell>
              <Table.Cell>
                <Button
                  isLoading={loading}
                  onClick={() =>
                    deleteReportTemplate({
                      variables: { id: template.id },
                    })
                  }
                >
                  Delete
                </Button>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
