import gql from "graphql-tag";

export default gql`
  fragment ProjectsTableFragment on AdminOrganization {
    projects {
      id
      createdByUser {
        id
        email
      }
      name
      streetAddress
      startDate
      amount
      requestedAmount
      disbursedAmount
      recentDraw {
        id
        name
      }
      template {
        id
        name
      }
      payApplicationCount
      insertedAt
      activatedAt
      status
    }
  }
`;
