/* eslint class-methods-use-this: 0 */
import auth0 from "auth0-js";
import { setSentry } from "sentry";

const KEYS = {
  ACCESS_TOKEN: "access_token",
  EXPIRES_AT: "expires_at",
  USER_EMAIL: "user_email",
};

class Auth {
  constructor() {
    if (!Auth.instance) {
      this.auth0 = new auth0.WebAuth({
        audience: process.env.REACT_APP_AUTH_AUDIENCE,
        clientID: process.env.REACT_APP_AUTH_CLIENT_ID,
        domain: process.env.REACT_APP_AUTH_DOMAIN,
        redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
        configurationBaseUrl: "https://cdn.auth0.com",
        responseType: "token id_token",
        scope: "openid profile",
      });
      Auth.instance = this;
    }
    return Auth.instance;
  }

  login = () => {
    return this.auth0.authorize();
  };

  logout = () => {
    localStorage.clear();
    return this.auth0.logout();
  };

  refresh = () =>
    new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) {
          reject(err);
        } else {
          const expiresAt = Date.now() + authResult.expiresIn * 1000;
          localStorage.setItem(KEYS.ACCESS_TOKEN, authResult.accessToken);
          localStorage.setItem(KEYS.EXPIRES_AT, expiresAt);
          localStorage.setItem(KEYS.USER_EMAIL, authResult.idTokenPayload.name);
          setSentry(authResult);
          resolve();
        }
      });
    });

  handleCallback = () =>
    new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) {
          reject(err);
        } else {
          const expiresAt = Date.now() + authResult.expiresIn * 1000;
          localStorage.setItem(KEYS.ACCESS_TOKEN, authResult.accessToken);
          localStorage.setItem(KEYS.EXPIRES_AT, expiresAt);
          localStorage.setItem(KEYS.USER_EMAIL, authResult.idTokenPayload.name);
          setSentry(authResult);
          resolve();
        }
      });
    });

  isAuthenticated = () => {
    const expiresAt = localStorage.getItem(KEYS.EXPIRES_AT);
    return Date.now() < expiresAt;
  };

  getAuthorization = () => {
    const accessToken = localStorage.getItem(KEYS.ACCESS_TOKEN);
    return accessToken ? `Bearer ${accessToken}` : "";
  };

  getEmail = () => {
    return localStorage.getItem(KEYS.USER_EMAIL);
  };
}

const instance = new Auth();
Object.freeze(instance);
export default instance;
