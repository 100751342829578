import React from "react";
import { Formik } from "formik";
import { Form, FormFile, FormInput, Pane } from "components/materials";
import { isBlank, majorScale } from "helpers/utilities";

export function ReportTemplateUploadForm({
  mutationLoading: loading,
  organizationId,
  uploadReportTemplate,
}) {
  return (
    <Pane marginTop={majorScale(3)}>
      <Formik
        initialValues={{ file: null, name: "" }}
        validate={(values) => {
          const errors = {};
          if (isBlank(values.file)) errors.file = "File is required";
          if (isBlank(values.name)) errors.name = "Template name is required";
          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          uploadReportTemplate({
            variables: {
              file: values.file,
              name: values.name,
              organizationId,
            },
          }).then(() => resetForm());
        }}
      >
        {(form) => (
          <Form isLoading={loading} onSubmit={form.handleSubmit}>
            {!loading && <FormFile accept=".docx" label="File" name="file" />}
            <FormInput
              name="name"
              type="text"
              marginTop={majorScale(1)}
              label="Template Name"
            />
          </Form>
        )}
      </Formik>
    </Pane>
  );
}
