import React from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Form,
  FormCheckbox,
  FormFieldArray,
  FormInput,
  Pane,
} from "components/materials";
import {
  ORGANIZATION_TYPE,
  PERMISSION_ACTION,
  PRICING_PLAN_OPTION,
} from "helpers/enums";
import {
  ACCOUNTS_PAYABLE_FEATURES,
  ACCOUNTS_PAYABLE_OPTIONS,
  CREATION_FEATURES,
  DEFAULT_DISABLED_DEVELOPER_FEATURES,
  DEFAULT_DISABLED_FEATURES,
  DEVELOPER_FEATURES,
  DOCUMENT_FEATURES,
  FUNDING_FEATURES,
  GENERAL_FEATURES,
  LENDER_FEATURES,
} from "helpers/permissionSectionHelpers";
import { isBlank, invalidEmail, majorScale, t } from "helpers/utilities";
import { map, set } from "lodash";
import { isValid } from "date-fns";
import { OrganizationPermissionsForm } from "./OrganizationPermissionsForm";
import { OrganizationTypeForm } from "./OrganizationTypeForm";

const OrganizationNewForm = ({ form, result }) => {
  return (
    <Form isLoading={result.loading} onSubmit={form.handleSubmit}>
      <FormInput label="Name" name="name" required />
      <FormInput label="Partner Support Email" name="email" required />
      <FormInput
        label="Partner Support Password"
        name="password"
        type="password"
        required
      />
      <Pane display="flex">
        <FormCheckbox
          label="Demo Account"
          name="demoAccount"
          helpText={t("helpText.demoAccount")}
        />
        <FormCheckbox
          label="Inactive Account"
          marginLeft={majorScale(5)}
          name="inactiveAccount"
          helpText={t("helpText.inactiveAccount")}
        />
        <FormCheckbox
          label="Pursuit Bundle"
          marginLeft={majorScale(5)}
          name="pursuitBundle"
          helpText={t("helpText.pursuitBundle")}
        />
      </Pane>
      <OrganizationTypeForm form={form} />
      <FormFieldArray
        label="Organization Features"
        name="permissions"
        render={OrganizationPermissionsForm}
        showAstrix
      />
      <Alert
        intent="warning"
        title="After you create this organization, make sure you add a project type to it."
        marginTop={majorScale(2)}
      />
    </Form>
  );
};

OrganizationNewForm.initialValues = () => {
  // We're disabling the the disabled developer features by default here
  // because Developer (Borrower) is the initial organization type
  const defaultNotEnabled = [
    ...DEFAULT_DISABLED_FEATURES,
    ...DEFAULT_DISABLED_DEVELOPER_FEATURES,
  ];

  return {
    name: "",
    email: "",
    password: "",
    demoAccount: false,
    type: ORGANIZATION_TYPE.BORROWER,
    pricingPlan: "",
    pursuitBundle: false,
    contractStartDate: new Date(0),
    projectMonthCredits: null,
    accountsPayablePermissions: map(ACCOUNTS_PAYABLE_FEATURES, (action) => ({
      action,
      enabled: false,
    })),
    accountsPayableOptionsPermissions: map(
      ACCOUNTS_PAYABLE_OPTIONS,
      (action) => ({
        action,
        enabled: false,
      })
    ),
    creationPermissions: map(CREATION_FEATURES, (action) => ({
      action,
      enabled: defaultNotEnabled.indexOf(action) === -1,
    })),
    developerPermissions: map(DEVELOPER_FEATURES, (action) => ({
      action,
      enabled: true,
    })),
    documentPermissions: map(DOCUMENT_FEATURES, (action) => ({
      action,
      enabled: defaultNotEnabled.indexOf(action) === -1,
    })),
    fundingPermissions: map(FUNDING_FEATURES, (action) => ({
      action,
      enabled: defaultNotEnabled.indexOf(action) === -1,
    })),
    generalPermissions: map(GENERAL_FEATURES, (action) => ({
      action,
      enabled: defaultNotEnabled.indexOf(action) === -1,
    })),
    lenderPermissions: map(LENDER_FEATURES, (action) => ({
      action,
      enabled: false,
    })),
  };
};

OrganizationNewForm.validate = (values, avidPostPaymentEmailAddress = "") => {
  const errors = {};

  const enabledPayableFeatures = values.accountsPayablePermissions.filter(
    (value) => value.enabled
  );

  const hasTooManyAccountsPayablePermissions =
    enabledPayableFeatures.length > 1;

  if (hasTooManyAccountsPayablePermissions) {
    set(
      errors,
      "accountsPayableFeatures",
      t("organizationForm.validate.allowOnlyOneAccountsPayableFeatures")
    );
  }

  // Checks that the avid post payment email is set if turning on the avid flag.
  // This is needed since we use that avid email when posting payments.
  values.accountsPayablePermissions.forEach((apPerms) => {
    const hasAvidEnabled =
      apPerms.action === PERMISSION_ACTION.AVID_AP_INTEGRATION &&
      apPerms.enabled;
    if (hasAvidEnabled && isBlank(avidPostPaymentEmailAddress)) {
      set(
        errors,
        "accountsPayableFeatures",
        t("organizationForm.validate.requireAvidEmail")
      );
    }
  });
  if (isBlank(values.name)) errors.name = "Name is required";
  if (isBlank(values.type)) errors.type = "Type is required";
  if (isBlank(values.email)) {
    errors.email = "Email is required";
  } else if (invalidEmail(values.email)) {
    errors.email = `${values.email} must be a valid email`;
  }
  if (isBlank(values.password)) {
    errors.password = "Password is required";
  } else if (values.password.length < 8) {
    errors.password = "Password must be at least 8 characters";
  }
  if (
    values.pricingPlan &&
    !Object.values(PRICING_PLAN_OPTION).includes(values.pricingPlan)
  ) {
    errors.pricingPlan = "Pricing Plan must be Lite, Standard, or Premium";
  }
  if (values.contractStartDate && !isValid(values.contractStartDate)) {
    errors.contractStartDate = "Contract Start Date must be a valid date";
  }
  if (
    values.projectMonthCredits &&
    !Number.isInteger(values.projectMonthCredits)
  ) {
    errors.projectMonthCredits = "Project Month Credits must be a whole number";
  }
  return errors;
};

OrganizationNewForm.onSubmit =
  (mutation) =>
  ({
    accountsPayablePermissions,
    accountsPayableOptionsPermissions,
    creationPermissions,
    developerPermissions,
    documentPermissions,
    fundingPermissions,
    generalPermissions,
    lenderPermissions,
    ...rest
  }) => {
    return mutation({
      variables: {
        ...rest,
        permissions: creationPermissions
          .concat(accountsPayablePermissions)
          .concat(accountsPayableOptionsPermissions)
          .concat(developerPermissions)
          .concat(documentPermissions)
          .concat(documentPermissions)
          .concat(fundingPermissions)
          .concat(generalPermissions)
          .concat(lenderPermissions),
      },
    });
  };

OrganizationNewForm.propTypes = {
  form: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
};

export default OrganizationNewForm;
