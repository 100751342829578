import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { cloneDeep, find } from "lodash";
import {
  CrossIcon,
  DragHandleHorizontalIcon,
  Icon,
  Pane,
  Text,
} from "evergreen-ui";
import { majorScale } from "helpers/utilities";

const dragItemStyles = (itemHeight) => ({
  display: "flex",
  alignItems: "center",
  height: itemHeight,
  backgroundColor: "#fafafa",
  borderBottom: true,
  justifyContent: "center",
});

function DragItem({ index, item, itemHeight, onRemove, renderItem }) {
  return (
    <Draggable draggableId={item.key} index={index}>
      {(provided) => {
        return (
          <Pane ref={provided.innerRef} {...provided.draggableProps}>
            {renderItem ? (
              renderItem({ index, item, itemHeight, onRemove, provided })
            ) : (
              <Pane {...dragItemStyles(itemHeight)}>
                <Pane
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  minWidth={majorScale(6)}
                  {...provided.dragHandleProps}
                >
                  <Icon icon={DragHandleHorizontalIcon} />
                </Pane>
                <Text
                  flexGrow="1"
                  fontWeight="bold"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {item.text}
                </Text>
                <Pane
                  display="flex"
                  alignItems="center"
                  paddingX={majorScale(2)}
                >
                  {item.secondaryText ? (
                    <Text color="muted">{item.secondaryText}</Text>
                  ) : (
                    onRemove && (
                      <Icon
                        size={12}
                        cursor="pointer"
                        onClick={() => onRemove(item.key)}
                        icon={CrossIcon}
                      />
                    )
                  )}
                </Pane>
              </Pane>
            )}
          </Pane>
        );
      }}
    </Draggable>
  );
}

export function DragAndDrop({
  items,
  onUpdate,
  onRemove,
  itemHeight,
  renderItem,
  ...props
}) {
  const constructReorderedItems = (key, source, destination) => {
    const movedItem = find(items, (item) => item.key === key);
    const newItems = cloneDeep(items);
    newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, movedItem);
    return newItems;
  };

  const handleUpdate = ({ draggableId, source, destination }) => {
    if (!destination || destination.index === source.index) return;
    const newItems = constructReorderedItems(draggableId, source, destination);
    onUpdate(newItems);
  };

  const handleRemove = (key) => {
    const removedItem = find(items, (item) => item.key === key);
    onRemove(removedItem);
  };

  return (
    <Pane {...props}>
      <DragDropContext onDragEnd={handleUpdate}>
        <Droppable droppableId="dropColumn">
          {(provided) => (
            <Pane
              display="flex"
              flexDirection="column"
              minHeight={itemHeight * items.length}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {items.map((item, index) => (
                <DragItem
                  key={item.key}
                  index={index}
                  item={item}
                  itemHeight={itemHeight}
                  onRemove={handleRemove}
                  renderItem={renderItem}
                />
              ))}
              {provided.placeholder}
            </Pane>
          )}
        </Droppable>
      </DragDropContext>
    </Pane>
  );
}
