import React from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import {
  ButtonLink,
  Loading,
  Pane,
  Segment,
  TabbedSegments,
  TabbedSegment,
  Text,
} from "components/materials";
import {
  CobrandingForm,
  IntegrationsTable,
  NexusPaymentSettingsForm,
  OrganizationEditForm,
  AvidPaymentSettingsForm,
  BillComPaymentSettingsForm,
  MriPaymentSettingsForm,
  ProjectsTable,
  ProjectTemplatesTable,
  QuickBooksOnlinePaymentSettingsForm,
  ReportTemplatesTable,
  ReportTemplateUploadForm,
  UsersTable,
  DocumentTemplates,
  YardiPaymentSettingsForm,
} from "components/templates";
import { ProjectsTableFragment } from "helpers/fragments";
import { majorScale, t, toaster } from "helpers/utilities";
import { get } from "lodash";
import {
  initialValues,
  validate,
  onSubmit,
} from "../templates/OrganizationEditForm";

const QUERY = gql`
  query OrganizationEditPageQuery($organizationId: String!) {
    organization(id: $organizationId) {
      id
      demoAccount
      inactiveAccount
      name
      type
      primaryColor
      headerColor
      tier
      pricingPlan
      contractStartDate
      pursuitBundle
      projectMonthCredits
      avidPostPaymentEmailAddress
      billComUrl
      mriClientId
      mriDatabaseName
      mriPartnerKey
      mriPartnerName
      mriPassword
      mriUrl
      mriUserId
      nexusClientCode
      nexusUserName
      nexusURL
      nexusKey
      nexusSecret
      qboClientId
      qboClientSecret
      qboUrl
      yardiUrl
      yardiUserName
      yardiPassword
      yardiServerName
      yardiDatabase
      yardiPlatform
      accountsPayableSettings {
        dayRangeForPullingPayables
      }
      primaryLogo {
        url
      }
      alternateLogo {
        url
      }
      totalAddressableProjectCount
      integrations {
        id
        trigger
        webhookUrl
        webhookPayload
        enabled
      }
      teams {
        id
        name
        hasProjects
      }
      users {
        id
        name
        email
      }
      permissionConfig
      projectTemplates {
        id
        name
        projectCount
      }
      drawAssessmentQuestions {
        id
        label
        description
        fieldType
        hasResponses
        reportTemplateFieldName
      }
      inspectionReportQuestions {
        id
        label
        description
        fieldType
        hasResponses
        reportTemplateFieldName
      }
      reportTemplates {
        id
        name
        url
      }
      documentTemplates {
        id
        type
        name
        url
        drawPackageDefault
        convertToPdfUrl
      }
      ...ProjectsTableFragment
    }
  }
  ${ProjectsTableFragment}
`;

const UPDATE_ORGANIZATION_MUTATION = gql`
  mutation UpdateOrganization(
    $id: String!
    $demoAccount: Boolean
    $drawAssessmentQuestions: [ReportFieldInput]!
    $teams: [TeamInput]!
    $inactiveAccount: Boolean
    $inspectionReportQuestions: [ReportFieldInput]!
    $name: String!
    $permissions: [PermissionInput]!
    $tier: TierOption
    $totalAddressableProjectCount: Int
    $type: OrganizationType!
    $pricingPlan: PricingPlanOption
    $contractStartDate: Date
    $pursuitBundle: Boolean
  ) {
    updateOrganization(
      id: $id
      demoAccount: $demoAccount
      drawAssessmentQuestions: $drawAssessmentQuestions
      inactiveAccount: $inactiveAccount
      inspectionReportQuestions: $inspectionReportQuestions
      name: $name
      permissions: $permissions
      teams: $teams
      tier: $tier
      totalAddressableProjectCount: $totalAddressableProjectCount
      type: $type
      pricingPlan: $pricingPlan
      contractStartDate: $contractStartDate
      pursuitBundle: $pursuitBundle
    ) {
      id
    }
  }
`;

const UPDATE_ORGANIZATION_COBRANDING_MUTATION = gql`
  mutation UpdateOrganizationCobranding(
    $id: String!
    $headerColor: String
    $primaryColor: String
    $primaryLogo: Upload
    $alternateLogo: Upload
  ) {
    updateOrganizationCobranding(
      id: $id
      headerColor: $headerColor
      primaryColor: $primaryColor
      primaryLogo: $primaryLogo
      alternateLogo: $alternateLogo
    ) {
      id
      headerColor
      primaryColor
      primaryLogo {
        url
      }
      alternateLogo {
        url
      }
    }
  }
`;

const UPLOAD_REPORT_TEMPLATE_MUTATION = gql`
  mutation UploadReportTemplate(
    $file: Upload!
    $name: String!
    $organizationId: String!
  ) {
    uploadReportTemplate(
      file: $file
      name: $name
      organizationId: $organizationId
    ) {
      id
    }
  }
`;

const UPLOAD_DOCUMENT_TEMPLATE_MUTATION = gql`
  mutation UploadDocumentTemplate(
    $file: Upload!
    $organizationId: String!
    $drawPackageDefault: Boolean!
    $type: DocumentTypeName!
  ) {
    uploadDocumentTemplate(
      file: $file
      organizationId: $organizationId
      drawPackageDefault: $drawPackageDefault
      type: $type
    ) {
      id
    }
  }
`;

const UPDATE_DOCUMENT_TEMPLATE_MUTATION = gql`
  mutation UpdateDocumentTemplate($id: String!, $drawPackageDefault: Boolean!) {
    updateDocumentTemplate(id: $id, drawPackageDefault: $drawPackageDefault) {
      id
      drawPackageDefault
    }
  }
`;

const DELETE_REPORT_TEMPLATE_MUTATION = gql`
  mutation DeleteReportTemplate($id: String!) {
    deleteReportTemplate(id: $id) {
      status
    }
  }
`;

const DELETE_DOCUMENT_TEMPLATE_MUTATION = gql`
  mutation DeleteDocumentTemplate($id: String!) {
    deleteDocumentTemplate(id: $id) {
      id
    }
  }
`;

const DELETE_LOGO_MUTATION = gql`
  mutation DeleteLogo($organizationId: String!, $type: String!) {
    deleteLogo(organizationId: $organizationId, type: $type) {
      status
    }
  }
`;

const OrganizationEditPage = ({ match }) => {
  const refetchQueries = [
    {
      query: QUERY,
      variables: match.params,
    },
  ];
  const { data, error, loading } = useQuery(QUERY, {
    variables: match.params,
  });

  const [updateOrganization, updateOrganizationResult] = useMutation(
    UPDATE_ORGANIZATION_MUTATION,
    {
      onCompleted: () => toaster.success("Organization updated"),
      refetchQueries,
    }
  );

  const [updateOrganizationCobranding, updateOrganizationCobrandingResult] =
    useMutation(UPDATE_ORGANIZATION_COBRANDING_MUTATION, {
      onCompleted: () => toaster.success("Organization updated"),
    });

  const [uploadReportTemplate, uploadReportTemplateResult] = useMutation(
    UPLOAD_REPORT_TEMPLATE_MUTATION,
    {
      onCompleted: () => toaster.success("Report template created"),
      refetchQueries,
    }
  );

  const [deleteReportTemplate, deleteReportTemplateResult] = useMutation(
    DELETE_REPORT_TEMPLATE_MUTATION,
    {
      onCompleted: () => toaster.success("Report template deleted"),
      refetchQueries,
    }
  );

  const [uploadDocumentTemplate, uploadDocumentTemplateResult] = useMutation(
    UPLOAD_DOCUMENT_TEMPLATE_MUTATION,
    {
      onCompleted: () => toaster.success("Document template created"),
      refetchQueries,
    }
  );

  const [updateDocumentTemplate] = useMutation(
    UPDATE_DOCUMENT_TEMPLATE_MUTATION,
    {
      onCompleted: () => toaster.success("Document template updated"),
      refetchQueries,
    }
  );

  const [deleteDocumentTemplate, deleteDocumentTemplateResult] = useMutation(
    DELETE_DOCUMENT_TEMPLATE_MUTATION,
    {
      onCompleted: () => toaster.success("Document template deleted"),
      refetchQueries,
    }
  );

  const [deleteLogo, deleteLogoResult] = useMutation(DELETE_LOGO_MUTATION, {
    onCompleted: () => toaster.success("Logo deleted"),
    refetchQueries,
  });

  if (loading) return <Loading />;
  if (error) return null;
  const { organization } = data;

  const users = get(organization, "users", []);
  const newProjectTemplateAction = (
    <ButtonLink to={`/organizations/${organization.id}/project_templates/new`}>
      New Project Type
    </ButtonLink>
  );
  const hasCobranding = get(organization, "permissionConfig.useCobranding");

  return (
    <React.Fragment>
      <TabbedSegments heading={organization.name}>
        <TabbedSegment heading="Basic info">
          <Formik
            enableReinitialize
            initialValues={initialValues(organization)}
            validate={(values) =>
              validate(values, organization.avidPostPaymentEmailAddress)
            }
            onSubmit={onSubmit(updateOrganization)}
          >
            {(form) => (
              <OrganizationEditForm
                form={form}
                result={updateOrganizationResult}
              />
            )}
          </Formik>
        </TabbedSegment>
        <TabbedSegment
          heading="Cobranding"
          helpText={t(
            `helpText.cobrandingFlag${hasCobranding ? "Active" : "Inactive"}`
          )}
        >
          <CobrandingForm
            alternateLogo={organization.alternateLogo}
            deleteLogo={deleteLogo}
            loading={
              deleteLogoResult.loading ||
              updateOrganizationCobrandingResult.loading
            }
            organization={organization}
            primaryLogo={organization.primaryLogo}
            updateCobranding={updateOrganizationCobranding}
          />
        </TabbedSegment>
        <TabbedSegment heading="Accounts Payable Settings" tabbedHeadingOnly>
          <Segment
            heading="Avid Email"
            paddingBottom={majorScale(2)}
            borderBottom="1px solid gray"
          >
            <Text>
              We will send invoices to this email address for the Avid
              Integration
            </Text>
            <AvidPaymentSettingsForm
              avidPostPaymentEmailAddress={
                organization.avidPostPaymentEmailAddress
              }
              organizationId={organization.id}
              hasIntegration={get(
                organization,
                "permissionConfig.avidIntegration"
              )}
            />
          </Segment>
          <Segment
            heading="Deltek-Vision"
            paddingBottom={majorScale(2)}
            borderBottom="1px solid gray"
          >
            <Pane paddingBottom={majorScale(1)}>
              <ButtonLink
                to={`/organizations/${organization.id}/integrations/new`}
              >
                New Webhook
              </ButtonLink>
            </Pane>
            <IntegrationsTable
              organizationId={organization.id}
              integrations={get(organization, "integrations") || []}
            />
          </Segment>
          <Segment
            heading="Yardi"
            paddingBottom={majorScale(2)}
            borderBottom="1px solid gray"
          >
            <YardiPaymentSettingsForm
              accountsPayableSettings={organization.accountsPayableSettings}
              organizationId={organization.id}
              yardiUrl={organization.yardiUrl}
              yardiUserName={organization.yardiUserName}
              yardiPassword={organization.yardiPassword}
              yardiServerName={organization.yardiServerName}
              yardiDatabase={organization.yardiDatabase}
              yardiPlatform={organization.yardiPlatform}
            />
          </Segment>
          <Segment
            heading="Nexus"
            paddingBottom={majorScale(2)}
            borderBottom="1px solid gray"
          >
            <NexusPaymentSettingsForm
              nexusClientCode={organization.nexusClientCode}
              nexusUserName={organization.nexusUserName}
              nexusURL={organization.nexusURL}
              nexusKey={organization.nexusKey}
              nexusSecret={organization.nexusSecret}
              organizationId={organization.id}
            />
          </Segment>
          <Segment
            heading="QuickBooks Online"
            paddingBottom={majorScale(2)}
            borderBottom="1px solid gray"
          >
            <QuickBooksOnlinePaymentSettingsForm
              organizationId={organization.id}
              qboClientId={organization.qboClientId}
              qboClientSecret={organization.qboClientSecret}
              qboUrl={organization.qboUrl}
            />
          </Segment>
          <Segment
            heading="Bill.com"
            paddingBottom={majorScale(2)}
            borderBottom="1px solid gray"
          >
            <BillComPaymentSettingsForm
              organizationId={organization.id}
              billComUrl={organization.billComUrl}
            />
          </Segment>
          <Segment
            heading="Mri"
            paddingBottom={majorScale(2)}
            borderBottom="1px solid gray"
          >
            <MriPaymentSettingsForm
              organizationId={organization.id}
              mriClientId={organization.mriClientId}
              mriDatabaseName={organization.mriDatabaseName}
              mriPartnerKey={organization.mriPartnerKey}
              mriPartnerName={organization.mriPartnerName}
              mriPassword={organization.mriPassword}
              mriUrl={organization.mriUrl}
              mriUserId={organization.mriUserId}
            />
          </Segment>
        </TabbedSegment>
        <TabbedSegment heading="Report Templates">
          <ReportTemplatesTable
            deleteReportTemplate={deleteReportTemplate}
            loading={deleteReportTemplateResult.loading}
            reportTemplates={organization.reportTemplates}
          />
          <ReportTemplateUploadForm
            mutationLoading={uploadReportTemplateResult.loading}
            organizationId={organization.id}
            uploadReportTemplate={uploadReportTemplate}
          />
        </TabbedSegment>
        <TabbedSegment heading="Document Templates">
          <DocumentTemplates
            deleteDocumentTemplate={deleteDocumentTemplate}
            deleteLoading={deleteDocumentTemplateResult.loading}
            documentTemplates={organization.documentTemplates}
            uploadDocumentTemplate={uploadDocumentTemplate}
            uploadLoading={uploadDocumentTemplateResult.loading}
            updateDocumentTemplate={updateDocumentTemplate}
            organizationId={organization.id}
          />
        </TabbedSegment>
        <TabbedSegment heading="Users" helpText={t(`helpText.users`)}>
          <UsersTable users={users} />
        </TabbedSegment>
        <TabbedSegment
          heading="Project Types"
          action={newProjectTemplateAction}
          helpText={t(`helpText.projectTypes`)}
        >
          <ProjectTemplatesTable
            organizationId={organization.id}
            projectTemplates={get(organization, "projectTemplates", [])}
          />
        </TabbedSegment>
        <TabbedSegment
          heading="Recent Projects"
          helpText={t(`helpText.recentProjects`)}
        >
          <ProjectsTable
            viewAll={get(organization, "projects", []).length > 10}
            organizationId={organization.id}
            projects={get(organization, "projects", []).slice(0, 10)}
          />
        </TabbedSegment>
      </TabbedSegments>
    </React.Fragment>
  );
};

export default OrganizationEditPage;
