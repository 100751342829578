import React from "react";
import PropTypes from "prop-types";
import { Heading, Text, Tooltip } from "evergreen-ui";

function getDynamicFontSize(
  defaultFontSize,
  limit,
  textLength,
  minimumFontSize
) {
  const dynamicFontSize =
    textLength > limit
      ? defaultFontSize * (limit / textLength)
      : defaultFontSize;

  return dynamicFontSize >= minimumFontSize ? dynamicFontSize : minimumFontSize;
}

export function Shortener({
  fontSize: defaultFontSize,
  text,
  limit,
  isLink,
  minimumFontSize,
  tooltip,
  useDynamicFontSize,
  ...props
}) {
  if (!text) return "";
  const fontSize = useDynamicFontSize
    ? getDynamicFontSize(defaultFontSize, limit, text.length, minimumFontSize)
    : defaultFontSize;
  const colorProp = isLink ? { color: "selected" } : {};
  const TextComponent = props.size > 500 ? Heading : Text;
  const styleProps = props ? { ...props, fontSize } : { fontSize };

  if (text.length <= limit) {
    return (
      <TextComponent {...colorProp} {...styleProps}>
        {text}
      </TextComponent>
    );
  }

  const short = text.substring(0, limit).concat("...");

  return tooltip ? (
    <Tooltip content={text}>
      <TextComponent {...colorProp} {...styleProps}>
        {short}
      </TextComponent>
    </Tooltip>
  ) : (
    <TextComponent {...colorProp} {...styleProps}>
      {short}
    </TextComponent>
  );
}

export default Shortener;

Shortener.propTypes = {
  text: PropTypes.string,
  limit: PropTypes.number.isRequired,
  isLink: PropTypes.bool,
  minimumFontSize: PropTypes.number,
  tooltip: PropTypes.bool,
};

Shortener.defaultProps = {
  minimumFontSize: 12,
  tooltip: true,
};
