import { format, parse, parseISO, getTime } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export { format } from "date-fns";

export const DATE_FORMAT = "MM/dd/yyyy";

const SERVER_DATE_FORMAT = "yyyy-MM-dd";

export const DATETIME_FORMAT = "yyyy-MM-dd hh:mm aa";

export function formatDate(date, blankValue) {
  if (!date) return blankValue;
  return format(date, DATE_FORMAT);
}

export function timeInMilliseconds(date) {
  const parsedDate = parse(date);
  return getTime(parsedDate, DATE_FORMAT);
}

export function isInvalidDate(date) {
  if (date === null || date === undefined) return false;

  return (
    formatDate(date) === "Invalid Date" || date.length !== DATE_FORMAT.length
  );
}

export function dateServerToForm(date) {
  if (!date) return "";
  const parsedDate = typeof date === "string" ? parseServerDate(date) : date;
  return format(parsedDate, DATE_FORMAT);
}

export function dateFormToServer(date) {
  if (!date) return null;
  const parsedDate =
    typeof date === "string" ? parse(date, DATE_FORMAT, new Date()) : date;
  return format(parsedDate, "yyyy-MM-dd");
}

export function datetimeServerToForm(datetime) {
  if (!datetime) return null;

  // Call to `substr` is to strip off fractions of a second
  const isoDateTime = `${datetime.substr(0, 19)}+00:00`;
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Convert from the server's UTC time to the local time for the current user
  return utcToZonedTime(parseISO(isoDateTime), currentTimezone);
}

export function formatDateTime(dateTime, blankValue = "-") {
  if (!dateTime) return blankValue;
  return format(dateTime, DATETIME_FORMAT);
}

export function parseServerDate(date) {
  if (!date) return date;
  return parse(date, SERVER_DATE_FORMAT, new Date());
}

export {
  addMonths,
  differenceInMonths,
  max,
  min,
  parse,
  parseISO,
  compareAsc,
} from "date-fns";
