import React from "react";
import PropTypes from "prop-types";
import { Heading, NavBar, NavLink, Pane } from "components/materials";
import { majorScale } from "helpers/utilities";
import auth from "auth";

const AppLayout = (props) => (
  <Pane minHeight="100vh">
    <NavBar alignItems="center" display="flex" zIndex={2}>
      <NavLink marginRight={majorScale(2)} to="/">
        Home
      </NavLink>
      <NavLink marginRight={majorScale(2)} to="/organizations">
        Organizations
      </NavLink>
      <NavLink marginRight={majorScale(2)} to="/user_lookup">
        User Lookup
      </NavLink>
      <NavLink marginRight={majorScale(2)} to="/project_connections">
        Project Connections
      </NavLink>
      <NavLink marginRight={majorScale(2)} to="/jinja">
        Jinja Data Viewer
      </NavLink>
      <NavLink marginRight="auto" to="/admin">
        Admin
      </NavLink>
      <Heading size={100}>{auth.getEmail()}</Heading>
      <NavLink marginLeft={majorScale(2)} to="/logout">
        Logout
      </NavLink>
    </NavBar>
    <Pane padding={majorScale(4)} zIndex={1}>
      {props.children}
    </Pane>
  </Pane>
);

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppLayout;
