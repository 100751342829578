import React from "react";
import { Link, Pane, Table, Text } from "components/materials";
import { formatCurrency, minorScale, t } from "helpers/utilities";
import { orderBy, uniqBy, flatMap, filter } from "lodash";
import { subtract } from "helpers/math";
import { DRAW_STATE } from "helpers/enums";

const prepareConnections = (connections) => {
  const sortedConnections = orderBy(
    connections,
    (connection) => connection.recentDraw.updatedAt,
    ["desc"]
  );

  const uniqueRecentConnections = uniqBy(
    sortedConnections,
    (connection) => connection.correlationId
  );

  return flatMap(uniqueRecentConnections, ({ correlationId }) =>
    filter(sortedConnections, ["correlationId", correlationId])
  );
};

const ProjectConnectionsTable = (props) => {
  const renderState = (draw) => {
    if (draw.state === DRAW_STATE.SUBMITTED) {
      return (
        <Table.TextCell>
          {t(`drawStates.${draw.state}`)}{" "}
          <Text
            marginLeft={minorScale(1)}
            color="muted"
            fontStyle="italic"
            size="small"
          >
            {draw.submittedDate}
          </Text>
        </Table.TextCell>
      );
    }

    if (draw.state === DRAW_STATE.FUNDED) {
      return (
        <Table.TextCell>
          {t(`drawStates.${draw.state}`)}{" "}
          <Text
            marginLeft={minorScale(1)}
            color="muted"
            fontStyle="italic"
            size="small"
          >
            {draw.fundedDate}
          </Text>
        </Table.TextCell>
      );
    }

    return <Table.TextCell>{t(`drawStates.${draw.state}`)}</Table.TextCell>;
  };

  const preparedConnections = prepareConnections(props.projectConnections);
  return (
    <Pane display="flex">
      <Table border="default" width="65%">
        <Table.Head />
        <Table.Head>
          <Table.TextHeaderCell>Project Connection ID</Table.TextHeaderCell>
          <Table.TextHeaderCell>Project Name</Table.TextHeaderCell>
          <Table.TextHeaderCell>Organization Name</Table.TextHeaderCell>
          <Table.TextHeaderCell>Project Total</Table.TextHeaderCell>
          <Table.TextHeaderCell>Funding Sources Remaining</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {preparedConnections.map((project, index) => {
            const nextProject = preparedConnections[index + 1];
            const border =
              nextProject && project.correlationId === nextProject.correlationId
                ? "none"
                : "2px solid black";
            return (
              <Table.Row key={project.id} borderBottom={border}>
                <Table.TextCell>{project.correlationId}</Table.TextCell>
                <Table.TextCell>
                  {project.owner ? (
                    <Link
                      to={`/organizations/${project.owner.id}/projects/${project.id}/edit`}
                    >
                      {project.name}
                    </Link>
                  ) : (
                    project.name
                  )}
                </Table.TextCell>
                <Table.TextCell>
                  {project.owner ? (
                    <Link to={`/organizations/${project.owner.id}/edit`}>
                      {project.owner.name}
                    </Link>
                  ) : (
                    "-"
                  )}
                </Table.TextCell>
                <Table.TextCell>
                  {formatCurrency(project.amount)}
                </Table.TextCell>
                <Table.TextCell>
                  {formatCurrency(
                    subtract(
                      project.fundingSourcesTotal,
                      project.fundingSourcesUsed
                    )
                  )}
                </Table.TextCell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Table border="default" width="35%">
        <Table.Head>
          <Table.TextHeaderCell>Most Recent Draw</Table.TextHeaderCell>
        </Table.Head>
        <Table.Head>
          <Table.TextHeaderCell>Draw Name</Table.TextHeaderCell>
          <Table.TextHeaderCell>Draw Amount</Table.TextHeaderCell>
          <Table.TextHeaderCell>Draw Status</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {preparedConnections.map((project, index) => {
            const nextProject = preparedConnections[index + 1];
            const border =
              nextProject && project.correlationId === nextProject.correlationId
                ? "none"
                : "2px solid black";
            return (
              <Table.Row key={project.id} borderBottom={border}>
                <Table.TextCell>
                  {project.owner ? (
                    <Link
                      to={`/organizations/${project.owner.id}/projects/${project.id}/edit`}
                    >
                      {project.recentDraw.name}
                    </Link>
                  ) : (
                    project.recentDraw.name
                  )}
                </Table.TextCell>
                <Table.TextCell>
                  {formatCurrency(project.recentDraw.requestedAmount)}
                </Table.TextCell>
                {renderState(project.recentDraw)}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Pane>
  );
};

export default ProjectConnectionsTable;
