import React from "react";
import PropTypes from "prop-types";
import { Route, Link } from "react-router-dom";
import { Button } from "evergreen-ui";

const NavLink = ({ to, ...rest }) => (
  <Route exact path={to}>
    {({ match }) => (
      <Button
        appearance="minimal"
        is={Link}
        isActive={!!match}
        to={to}
        {...rest}
      />
    )}
  </Route>
);

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default NavLink;
