import React from "react";
import PropTypes from "prop-types";
import { Button, Pane } from "evergreen-ui";
import { majorScale } from "helpers/utilities";

const Form = ({
  children,
  isLoading,
  onCancel,
  onSubmit,
  textCancel,
  textSubmit,
  ...rest
}) => (
  <Pane
    is="form"
    autoComplete="off"
    onReset={onCancel}
    onSubmit={onSubmit}
    {...rest}
  >
    {children}
    <Pane marginTop={majorScale(2)}>
      {onCancel && (
        <Button type="reset" marginRight={majorScale(2)}>
          {textCancel}
        </Button>
      )}
      {onSubmit && (
        <Button type="submit" appearance="primary" isLoading={isLoading}>
          {textSubmit}
        </Button>
      )}
    </Pane>
  </Pane>
);

Form.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  textCancel: PropTypes.string,
  textSubmit: PropTypes.string,
};

Form.defaultProps = {
  isLoading: false,
  onCancel: null,
  textCancel: "Cancel",
  textSubmit: "Submit",
};

export default Form;
