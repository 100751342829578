import React from "react";
import PropTypes from "prop-types";
import { Button, Dialog, Table, TextInput } from "components/materials";
import { formatCurrency, t } from "helpers/utilities";

const DrawsTable = (props) => {
  const [isShown, setIsShown] = React.useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = React.useState(false);

  const handleConfirm = (draw, deleteDrawMutation) => {
    deleteDrawMutation({ variables: { drawId: draw.id } });
    setIsShown(false);
  };

  const renderDelete = (draw, deleteDrawMutation) => (
    <React.Fragment>
      <Dialog
        isShown={isShown}
        title="Warning deleting a draw will lose all information attached to this draw and can not be reverted. Please type 'DELETE' to confirm."
        intent="danger"
        isConfirmDisabled={!deleteConfirmed}
        onConfirm={() => handleConfirm(draw, deleteDrawMutation)}
        onCloseComplete={() => setIsShown(false)}
        confirmLabel="Confirm"
      >
        <TextInput
          onChange={(e) =>
            e.target.value === "DELETE"
              ? setDeleteConfirmed(true)
              : setDeleteConfirmed(false)
          }
        />
      </Dialog>
      <Button onClick={() => setIsShown(true)} intent="danger">
        Delete
      </Button>
    </React.Fragment>
  );

  return (
    <Table border="default">
      <Table.Head>
        <Table.TextHeaderCell>Name</Table.TextHeaderCell>
        <Table.TextHeaderCell>Approved amount</Table.TextHeaderCell>
        <Table.TextHeaderCell>Requested amount</Table.TextHeaderCell>
        <Table.TextHeaderCell>Status</Table.TextHeaderCell>
        <Table.TextHeaderCell>Delete most recent draw</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {props.draws.map((draw) => (
          <Table.Row key={draw.id}>
            <Table.TextCell>{draw.name}</Table.TextCell>
            <Table.TextCell>
              {formatCurrency(draw.disbursedAmount)}
            </Table.TextCell>
            <Table.TextCell>
              {formatCurrency(draw.requestedAmount)}
            </Table.TextCell>
            <Table.TextCell>{t(`drawStates.${draw.state}`)}</Table.TextCell>
            <Table.TextCell>
              {props.draws.indexOf(draw) === 0 &&
                renderDelete(draw, props.deleteDrawMutation)}
            </Table.TextCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

DrawsTable.propTypes = {
  deleteDrawMutation: PropTypes.func.isRequired,
  draws: PropTypes.array.isRequired,
};

export default DrawsTable;
