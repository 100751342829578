import React from "react";
import { Button, Pane, TextInputField } from "evergreen-ui";
import { majorScale } from "helpers/utilities";
import DatePicker from "react-datepicker";
import { get } from "lodash";
import { format, DATE_FORMAT, DATETIME_FORMAT } from "helpers/dateHelpers";
import { downloadCsv } from "helpers/downloadHelpers";

const exportToCSV = (projects) => {
  const values = [
    [
      "Project Name",
      "Street Address",
      "Project Template Name",
      "Project Start Date",
      "Creation Date",
      "Chargable Since",
      "Creator Email",
      "Project Total",
      "Requested Amount",
    ],
  ].concat(
    projects.map((project) => {
      const {
        amount: projectTotal,
        insertedAt: creationDate,
        activatedAt,
        name,
        requestedAmount,
        startDate,
        streetAddress,
      } = project;
      const template = get(project, "template.name");
      const createdBy = get(project, "createdByUser.email");

      return [
        name,
        streetAddress,
        template,
        startDate !== null ? format(new Date(startDate), DATE_FORMAT) : null,
        format(new Date(creationDate), DATETIME_FORMAT),
        activatedAt,
        createdBy,
        projectTotal,
        requestedAmount,
      ];
    })
  );
  downloadCsv(values, "ProjectExport.csv");
};

const ProjectFilterControls = ({ projects, setTableConfig, tableConfig }) => {
  const { projectName, endDate, startDate } = tableConfig;

  return (
    <Pane display="flex" marginBottom={majorScale(2)} marginTop={majorScale(2)}>
      <TextInputField
        label="Project Name"
        marginRight={majorScale(2)}
        onChange={({ target: { value } }) =>
          setTableConfig((oldConfig) => ({
            ...oldConfig,
            projectName: value,
          }))
        }
        value={projectName}
      />
      <DatePicker
        customInput={
          <TextInputField
            label="Start Date (creation)"
            marginRight={majorScale(2)}
          />
        }
        onChange={(value) =>
          setTableConfig((oldConfig) => ({ ...oldConfig, startDate: value }))
        }
        selected={startDate}
      />
      <DatePicker
        customInput={
          <TextInputField
            label="End Date (creation)"
            marginRight={majorScale(4)}
          />
        }
        label="End Date (creation)"
        onChange={(value) => {
          value.setHours(23, 59, 59);
          setTableConfig((oldConfig) => ({ ...oldConfig, endDate: value }));
        }}
        selected={endDate}
      />
      <Button
        type="button"
        marginTop={majorScale(3)}
        marginRight={majorScale(1)}
        onClick={() =>
          setTableConfig({
            projectName: "",
          })
        }
      >
        Reset
      </Button>
      <Button
        type="button"
        marginTop={majorScale(3)}
        onClick={() => exportToCSV(projects)}
      >
        Export to CSV
      </Button>
    </Pane>
  );
};

export default ProjectFilterControls;
