import React from "react";
import PropTypes from "prop-types";
import { Icon, Link, Table, TickIcon } from "components/materials";
import { formatCurrency, formatPercent, majorScale } from "helpers/utilities";
import { format, DATE_FORMAT } from "helpers/dateHelpers";
import { get } from "lodash";

function RecentProjectsTable(props) {
  return (
    <Table border="default">
      <Table.Head>
        <Table.TextHeaderCell>Project name</Table.TextHeaderCell>
        <Table.TextHeaderCell>Organization name</Table.TextHeaderCell>
        <Table.TextHeaderCell>Organization type</Table.TextHeaderCell>
        <Table.TextHeaderCell>Street address</Table.TextHeaderCell>
        <Table.TextHeaderCell>Start Date</Table.TextHeaderCell>
        <Table.TextHeaderCell>Created in Rabbet</Table.TextHeaderCell>
        <Table.TextHeaderCell width={175}>Created by</Table.TextHeaderCell>
        <Table.TextHeaderCell>Project total</Table.TextHeaderCell>
        <Table.TextHeaderCell>Amount requested</Table.TextHeaderCell>
        <Table.TextHeaderCell>Has pay applications</Table.TextHeaderCell>
        <Table.TextHeaderCell>Project progress</Table.TextHeaderCell>
        <Table.TextHeaderCell>Current draw</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {props.projects.map((project) => (
          <Table.Row key={project.id}>
            <Table.TextCell>
              <Link
                to={`/organizations/${project.owner?.id}/projects/${project.id}/edit`}
              >
                {project.name}
              </Link>
            </Table.TextCell>
            <Table.TextCell>
              {project.owner ? project.owner.name : "-"}
            </Table.TextCell>
            <Table.TextCell>
              {project.owner ? project.owner.type : "-"}
            </Table.TextCell>
            <Table.TextCell>
              {project.streetAddress ? project.streetAddress : "-"}
            </Table.TextCell>
            <Table.TextCell>
              {project.startDate !== null
                ? format(new Date(project.startDate), DATE_FORMAT)
                : null}
            </Table.TextCell>
            <Table.TextCell>
              {format(new Date(project.insertedAt), DATE_FORMAT)}
            </Table.TextCell>
            <Table.TextCell width={175}>
              {get(project, "createdByUser.email")}
            </Table.TextCell>
            <Table.TextCell>{formatCurrency(project.amount)}</Table.TextCell>
            <Table.TextCell>
              {formatCurrency(project.requestedAmount)}
            </Table.TextCell>
            <Table.TextCell>
              {project.payApplicationCount > 0 && (
                <React.Fragment>
                  <Icon icon={TickIcon} marginRight={majorScale(1)} />
                  {`(${project.payApplicationCount})`}
                </React.Fragment>
              )}
            </Table.TextCell>
            <Table.TextCell>
              {formatPercent(
                get(project, "disbursedAmount", 0) / get(project, "amount", 0)
              )}
            </Table.TextCell>
            <Table.TextCell>
              {get(project, "recentDraw.name", "-")}
            </Table.TextCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

RecentProjectsTable.propTypes = {
  projects: PropTypes.array.isRequired,
};

export default RecentProjectsTable;
