import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  FormDropdown,
  FormFieldArray,
  FormInput,
  FormTextarea,
  FormCheckbox,
  Pane,
} from "components/materials";
import { isBlank, majorScale, t } from "helpers/utilities";
import { omit, values } from "lodash";
import { INTEGRATION_TRIGGER } from "helpers/enums";

function isValidURL(string) {
  try {
    new URL(string); // eslint-disable-line
  } catch (error) {
    return false;
  }

  return true;
}

const operators = [{ id: "=", name: "=" }];

function HeadersFields({ form, push, remove }) {
  return (
    <Pane>
      {form.values.headers.map((header, index) => (
        <Pane display="flex">
          <FormInput
            placeholder="Name"
            name={`headers.${index}.name`}
            maxWidth={150}
            marginRight={majorScale(1)}
          />
          <FormInput
            placeholder="Value"
            name={`headers.${index}.value`}
            maxWidth={150}
            marginRight={majorScale(1)}
          />
          <Button
            onClick={(e) => {
              e.preventDefault();
              remove(index);
            }}
            marginTop={4}
          >
            Remove
          </Button>
        </Pane>
      ))}

      <Button
        onClick={(e) => {
          e.preventDefault();

          push({
            name: "",
            value: "",
          });
        }}
        marginBottom={majorScale(3)}
      >
        Add Header
      </Button>
    </Pane>
  );
}

function ConditionsFields({ form, push, remove }) {
  return (
    <Pane>
      {form.values.conditions.map((condition, index) => (
        <Pane display="flex">
          <FormInput
            placeholder="Property"
            name={`conditions.${index}.property`}
            maxWidth={150}
            marginRight={majorScale(1)}
          />
          <FormDropdown
            name={`conditions.${index}.operator`}
            options={operators}
            maxWidth={100}
            marginRight={majorScale(1)}
          />
          <FormInput
            placeholder="Value"
            name={`conditions.${index}.value`}
            maxWidth={150}
            marginRight={majorScale(1)}
          />
          <Button
            onClick={(e) => {
              e.preventDefault();
              remove(index);
            }}
            marginTop={4}
          >
            Remove
          </Button>
        </Pane>
      ))}

      <Button
        onClick={(e) => {
          e.preventDefault();

          push({
            property: "",
            operator: "=",
            value: "",
          });
        }}
        marginBottom={majorScale(3)}
      >
        Add Condition
      </Button>
    </Pane>
  );
}

function IntegrationForm({ form, result }) {
  return (
    <Form isLoading={result.loading} onSubmit={form.handleSubmit}>
      <FormDropdown
        label="Trigger"
        name="trigger"
        maxWidth={250}
        options={values(INTEGRATION_TRIGGER).map((trigger) => ({
          id: trigger,
          name: t(`integrationTriggers.${trigger}`),
        }))}
        helpText={t(`helpText.triggers`)}
      />
      <FormInput
        label="Webhook URL"
        name="webhookUrl"
        placeholder="https://..."
        required
      />
      <FormFieldArray
        label="Headers"
        name="headers"
        render={(props) => <HeadersFields {...props} />}
      />
      <FormFieldArray
        label="Conditions"
        name="conditions"
        render={(props) => <ConditionsFields {...props} />}
      />
      <FormTextarea label="Webhook Payload" name="webhookPayload" required />
      <FormTextarea label="Response Payload" name="responsePayload" required />
      <FormCheckbox label="Enabled?" name="enabled" required />
    </Form>
  );
}

IntegrationForm.initialValuesNew = (organizationId) => ({
  organizationId,
  webhookUrl: "",
  webhookPayload: "{}",
  responsePayload: "{}",
  trigger: INTEGRATION_TRIGGER.VENDOR_CREATED,
  enabled: true,
  conditions: [],
  headers: [],
});

IntegrationForm.initialValuesEdit = (integration) => ({
  ...integration,
  conditions: integration.conditions.map((condition) =>
    omit(condition, "__typename")
  ),
  headers: integration.headers.map((header) => omit(header, "__typename")),
});

IntegrationForm.validate = (formValues) => {
  const errors = {};
  if (isBlank(formValues.trigger)) errors.trigger = "Trigger is required";
  if (isBlank(formValues.webhookUrl))
    errors.webhookUrl = "Webhook URL is required";
  if (!isValidURL(formValues.webhookUrl))
    errors.webhookUrl = "Webhook URL is not a valid URL";
  if (isBlank(formValues.webhookPayload))
    errors.webhookPayload = "Webhook Payload is required";
  if (isBlank(formValues.responsePayload))
    errors.responsePayload = "Webhook Payload is required";
  return errors;
};

IntegrationForm.onSubmit = (mutation) => (formValues) => {
  return mutation({
    variables: formValues,
  });
};

IntegrationForm.propTypes = {
  form: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
};

export default IntegrationForm;
