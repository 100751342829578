import React from "react";
import { Form } from "components/materials";
import PropTypes from "prop-types";

const ProjectEditForm = ({ form, result, projectType }) => {
  return <Form isLoading={result.loading} onSubmit={form.handleSubmit} />;
};

ProjectEditForm.initialValues = (project) => ({
  projectId: project.id,
});

ProjectEditForm.onSubmit = (mutation) => (values) => {
  const variables = {
    ...values,
  };
  return mutation({ variables });
};

ProjectEditForm.propTypes = {
  form: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
};

export default ProjectEditForm;
