import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useForm } from "react-hook-form";
import { format as formatDate, startOfMonth, addMonths } from "date-fns/fp";
import { pipe } from "ramda";
import { majorScale } from "helpers/utilities";
import {
  Button,
  Heading,
  Link,
  Loading,
  Segment,
  Table,
  Text,
} from "../materials";
import * as Organizations from "../../Organizations";

const PROJECT_MONTH_CREDITS_ACTIVITY_FRAGMENT = gql`
  fragment ProjectMonthCreditsActivity_attrs on AdminProjectMonthCreditsActivity {
    id
    insertedAt
    activityType
    projectMonthCreditsChange
    notes
    projects {
      id
    }
  }
`;

const CREATE_PROJECT_MONTH_CREDITS_ACTIVITY_MUTATION = gql`
  mutation CreateProjectMonthCreditsActivity(
    $organizationId: ID!
    $activityType: ProjectMonthCreditsActivityType!
    $projectMonthCreditsChange: Int!
    $notes: String
  ) {
    createProjectMonthCreditsActivity(
      organizationId: $organizationId
      activityType: $activityType
      projectMonthCreditsChange: $projectMonthCreditsChange
      notes: $notes
    ) {
      ...ProjectMonthCreditsActivity_attrs
    }
  }
  ${PROJECT_MONTH_CREDITS_ACTIVITY_FRAGMENT}
`;

const CreateProjectMonthCreditsActivity = ({ organizationId, onCreate }) => {
  const [createProjectMonthCreditsActivity, { loading, error }] = useMutation(
    CREATE_PROJECT_MONTH_CREDITS_ACTIVITY_MUTATION,
    {
      onCompleted: async (_result) => {
        await onCreate();
      },
    }
  );
  const { register, handleSubmit, reset, getValues } = useForm({
    defaultValues: {
      activityType: "CORRECTION",
    },
  });
  const onSubmit = (data) => {
    return createProjectMonthCreditsActivity({
      variables: {
        organizationId,
        ...data,
      },
    }).then((res) => {
      reset();
      return res;
    });
  };

  if (error) throw new Error(error);

  /* eslint-disable jsx-a11y/label-has-associated-control */
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading>Create Activity</Heading>
      <label
        htmlFor="activityType"
        style={{
          display: "inline-block",
          width: "auto",
          marginRight: majorScale(2),
        }}
      >
        <Text style={{ marginRight: majorScale(1) }}>Activity Type</Text>
        <select
          style={{ marginRight: majorScale(1) }}
          {...register("activityType", { required: true })}
        >
          <option key="CORRECTION" value="CORRECTION">
            Correction
          </option>
          <option key="RENEWAL" value="RENEWAL">
            Renewal
          </option>
        </select>
      </label>
      <label
        htmlFor="projectMonthCreditsChange"
        style={{ marginRight: majorScale(2) }}
      >
        <Text style={{ marginRight: majorScale(1) }}>
          Project Month Credits Change:
        </Text>
        <input
          type="number"
          {...register("projectMonthCreditsChange", {
            required: true,
            valueAsNumber: true,
            validate: (value) => {
              const activityType = getValues("activityType");
              if (activityType === "RENEWAL") {
                return value > 0;
              }
              if (activityType === "CORRECTION") {
                return value !== 0;
              }
              return false;
            },
          })}
        />
      </label>
      <label htmlFor="notes" style={{ marginRight: majorScale(2) }}>
        <Text style={{ marginRight: majorScale(1) }}>Notes:</Text>
        <input type="textarea" {...register("notes")} />
      </label>
      <Button type="submit" isLoading={loading}>
        Submit
      </Button>
    </form>
  );
};

const ORGANIZATION_PROJECT_MONTH_CREDITS_ACTIVITIES_QUERY = gql`
  query OrganizationProjectMonthCreditsActivities($organizationId: String!) {
    organization(id: $organizationId) {
      id
      name
      contractStartDate
      projectMonthCredits
      nextProjectMonthCreditsDeduction
      projectMonthCreditsActivities {
        ...ProjectMonthCreditsActivity_attrs
      }
    }
  }
  ${PROJECT_MONTH_CREDITS_ACTIVITY_FRAGMENT}
`;

const CHARGE_ORGANIZATION_FOR_CURRENT_MONTH_MUTATION = gql`
  mutation ChargeOrganizationForCurrentMonth($organizationId: ID!) {
    chargeOrganizationForCurrentMonth(organizationId: $organizationId) {
      id
      name
      contractStartDate
      projectMonthCredits
      nextProjectMonthCreditsDeduction
      projectMonthCreditsActivities {
        ...ProjectMonthCreditsActivity_attrs
      }
    }
  }
  ${PROJECT_MONTH_CREDITS_ACTIVITY_FRAGMENT}
`;

const ProjectMonthCreditsActivitiesPage = ({ match }) => {
  const { data, loading, error, refetch } = useQuery(
    ORGANIZATION_PROJECT_MONTH_CREDITS_ACTIVITIES_QUERY,
    {
      variables: { organizationId: match.params.organizationId },
    }
  );

  const [
    chargeOrganizationForCurrentMonth,
    { loading: chargeLoading, error: chargeError },
  ] = useMutation(CHARGE_ORGANIZATION_FOR_CURRENT_MONTH_MUTATION, {
    onCompleted: (_result) => refetch(),
  });

  if (loading) return <Loading />;
  if (error) {
    return <React.Fragment>Error: {JSON.stringify(error)}</React.Fragment>;
  }

  return (
    <Segment
      heading={
        <React.Fragment>
          <span style={{ marginRight: majorScale(2) }}>
            {`${data.organization.name} Project Month Credits Activities`}
          </span>
          <Link to={`/organizations/${data.organization.id}/edit`}>
            (Back to edit)
          </Link>
        </React.Fragment>
      }
      action={
        <CreateProjectMonthCreditsActivity
          organizationId={match.params.organizationId}
          onCreate={() => refetch()}
        />
      }
    >
      <Text>
        Current Project Month Credits Balance:{" "}
        {data.organization.projectMonthCredits === null
          ? "None"
          : data.organization.projectMonthCredits}
        , Projected deduction on{" "}
        {pipe(addMonths(1), startOfMonth, formatDate("PPP"))(new Date())}:{" "}
        {data.organization.nextProjectMonthCreditsDeduction === 0
          ? "None"
          : data.organization.nextProjectMonthCreditsDeduction}
      </Text>{" "}
      {Organizations.isChargeableOrganization(data.organization) &&
        Organizations.isContractStartDatePassed(data.organization) && (
          <React.Fragment>
            <div>
              <Button
                onClick={() =>
                  chargeOrganizationForCurrentMonth({
                    variables: { organizationId: match.params.organizationId },
                  })
                }
                isLoading={chargeLoading}
              >
                Run Charge for{" "}
                {pipe(startOfMonth, formatDate("PPP"))(new Date())}
              </Button>
              {chargeError && (
                <Text style={{ color: "red" }}>
                  {JSON.stringify(chargeError)}
                </Text>
              )}
            </div>
          </React.Fragment>
        )}
      <Table border="default" marginTop={majorScale(3)}>
        <Table.Head>
          <Table.TextHeaderCell>Date</Table.TextHeaderCell>
          <Table.TextHeaderCell>Activity Type</Table.TextHeaderCell>
          <Table.TextHeaderCell>Credits Change</Table.TextHeaderCell>
          <Table.TextHeaderCell>
            Number of Relevant Projects
          </Table.TextHeaderCell>
          <Table.TextHeaderCell>Notes</Table.TextHeaderCell>
        </Table.Head>

        {data.organization.projectMonthCreditsActivities.length > 0 ? (
          <Table.Body>
            {data.organization.projectMonthCreditsActivities.map((activity) => (
              <Table.Row key={activity.id}>
                <Table.TextCell>
                  {formatDate("PPpp", new Date(activity.insertedAt))}
                </Table.TextCell>
                <Table.TextCell>{activity.activityType}</Table.TextCell>
                <Table.TextCell>
                  {activity.projectMonthCreditsChange}
                </Table.TextCell>
                <Table.TextCell>
                  {activityCanHaveProjects(activity)
                    ? activity.projects.length
                    : "N/A"}
                </Table.TextCell>
                <Table.TextCell>{activity.notes}</Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        ) : (
          <Text>None found</Text>
        )}
      </Table>
    </Segment>
  );
};

const activityCanHaveProjects = ({ activityType }) =>
  !["RENEWAL", "CORRECTION"].includes(activityType);

export default ProjectMonthCreditsActivitiesPage;
