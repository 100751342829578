export function downloadUrl(url, filename) {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function getUrl(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const url = window.URL.createObjectURL(blob);
    downloadUrl(url, filename);
    window.URL.revokeObjectURL(url);
  }
}

// https://en.wikipedia.org/wiki/Comma-separated_values#Basic_rules
function escapeCsv(item) {
  const string = (item === null || item === undefined ? "" : item)
    .toString()
    .replace(/(\r\n|\n|\r)/g, "")
    .replace(/"/g, '""')
    .trim();
  return `"${string}"`;
}

export function downloadCsv(data, filename) {
  const content = data.map((row) => row.map(escapeCsv).join(",")).join("\n");
  const type = "text/csv;charset=utf-8;";
  const blob = new Blob([content], { type });
  getUrl(blob, filename);
}
