export const formatBoolean = (value) => {
  return value ? "Yes" : "No";
};

export const formatCurrency = (value, defaultValue = "-") => {
  if (value === undefined || value === null) return defaultValue;
  const number = Number(value);
  if (!Number.isFinite(number)) return defaultValue;
  return number.toLocaleString("en-US", { style: "currency", currency: "USD" });
};

export const formatPercent = (value, defaultValue = "-") => {
  if (value === undefined || value === null) return defaultValue;
  const number = Number(value);
  if (!Number.isFinite(number)) return defaultValue;
  return number.toLocaleString("en-US", { style: "percent" });
};
