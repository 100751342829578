import React from "react";
import { BarGraph, Heading, LineGraph, Pane, Text } from "components/materials";
import { formatCurrency } from "helpers/utilities";

const allColors = [
  "#3AAEE3",
  "#FDB952",
  "#4753A4",
  "#299CD8",
  "#E8A603",
  "#5A85F2",
];

const formats = {
  Number: (num) => num.toLocaleString("en"),
  Currency: formatCurrency,
  Percentage: (num) => `${num}%`,
};

const formatTooltip = (tooltipItem, data) => {
  let displayType = "Number";
  if (data.label.includes("Dollar") || data.label.includes("Value")) {
    displayType = "Currency";
  }
  if (data.label.includes("Percentage")) {
    displayType = "Percentage";
  }

  const { label } = data.datasets[tooltipItem.datasetIndex];
  const value = formats[displayType](tooltipItem.yLabel);

  return `${label}: ${value}`;
};

function Metric(props) {
  const { metric, colors, width } = props;
  switch (metric.type) {
    case "bar":
      return (
        <BarGraph
          colors={colors}
          metric={metric}
          width={width}
          format={formatTooltip}
        />
      );
    case "graph":
      return (
        <LineGraph
          colors={colors}
          metric={metric}
          width={width}
          format={formatTooltip}
        />
      );
    case "percentage":
      return (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          width={width}
        >
          <Pane display="flex" flexFlow="column">
            {metric.datasets.map((dataset, j) => (
              <Pane padding="20px" textAlign="center" key={dataset.label}>
                <Heading size={900} color={colors[j]}>
                  {parseFloat(dataset.data).toFixed(2)}%{" "}
                </Heading>
                <Text color={colors[j]}>
                  {dataset.label} (target {metric.target}%)
                </Text>
              </Pane>
            ))}
          </Pane>
        </Pane>
      );
    default:
      return null;
  }
}

function DashboardMetrics({ metrics }) {
  return (
    <Pane display="flex" flexFlow="row wrap">
      {metrics.map((metric, i) => (
        <Metric
          key={metric.name}
          colors={allColors.slice(
            i % allColors.length,
            (i % allColors.length) + metric.datasets.length
          )}
          width={`${Math.max(100 / metrics.length, 20)}%`}
          metric={metric}
        />
      ))}
    </Pane>
  );
}

export default DashboardMetrics;
