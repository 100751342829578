import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { ProjectsTable } from "components/templates";
import { Loading, ProjectFilterControls, Segment } from "components/materials";
import { ProjectsTableFragment } from "helpers/fragments";
import { get } from "lodash";

const filterProjects = (projects, filters) => {
  const EARLIEST_DATE = new Date(-8640000000000000);
  const LATEST_DATE = new Date(8640000000000000);
  const endDate = get(filters, "endDate", LATEST_DATE);
  const startDate = get(filters, "startDate", EARLIEST_DATE);
  const projectName = get(filters, "projectName", "");

  return projects.filter((project) => {
    const createdOn = new Date(project.insertedAt);
    return (
      createdOn >= startDate &&
      createdOn <= endDate &&
      project.name.toLowerCase().includes(projectName.toLowerCase())
    );
  });
};

const ProjectsPage = ({ match: { params: variables } }) => {
  const [tableConfig, setTableConfig] = useState({});

  const { data, error, loading } = useQuery(ProjectsPage.Query, {
    variables,
  });

  if (loading) return <Loading />;
  if (error) return null;
  const { organization } = data;
  const projects = get(organization, "projects", []);

  const filteredProjects = filterProjects(projects, tableConfig);

  return (
    <Segment heading="Projects">
      <ProjectFilterControls
        projects={filteredProjects}
        setTableConfig={setTableConfig}
        tableConfig={tableConfig}
      />
      <ProjectsTable
        organizationId={organization.id}
        projects={filteredProjects}
      />
    </Segment>
  );
};
ProjectsPage.Query = gql`
  query ProjectsPageQuery($organizationId: String!) {
    organization(id: $organizationId) {
      id
      ...ProjectsTableFragment
    }
  }
  ${ProjectsTableFragment}
`;

export default ProjectsPage;
