import React from "react";
import PropTypes from "prop-types";
import { Icon, Link, Table, TickIcon } from "components/materials";
import {
  formatPercent,
  formatCurrency,
  formatBoolean,
  majorScale,
} from "helpers/utilities";
import * as Organizations from "../../Organizations";

export function OrganizationsTable({ organizations }) {
  return (
    <Table border="default">
      <Table.Head>
        <Table.TextHeaderCell>Name</Table.TextHeaderCell>
        <Table.TextHeaderCell>User count</Table.TextHeaderCell>
        <Table.TextHeaderCell>Total Project count</Table.TextHeaderCell>
        <Table.TextHeaderCell>Active Projects</Table.TextHeaderCell>
        <Table.TextHeaderCell>Inactive Projects</Table.TextHeaderCell>
        <Table.TextHeaderCell>Pre-development Projects</Table.TextHeaderCell>
        <Table.TextHeaderCell>
          Projects with pay applications
        </Table.TextHeaderCell>
        <Table.TextHeaderCell>Credits Balance</Table.TextHeaderCell>
        <Table.TextHeaderCell>Portfolio adoption %</Table.TextHeaderCell>
        <Table.TextHeaderCell>Draw count</Table.TextHeaderCell>
        <Table.TextHeaderCell>Project value</Table.TextHeaderCell>
        <Table.TextHeaderCell>Draw value</Table.TextHeaderCell>
        <Table.TextHeaderCell>Inactive?</Table.TextHeaderCell>
        <Table.TextHeaderCell>Demo account?</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {organizations.map((organization) => (
          <Table.Row key={organization.id}>
            <Table.Cell>
              <Link to={`/organizations/${organization.id}/edit`}>
                {organization.name}
              </Link>
            </Table.Cell>
            <Table.TextCell>{organization.usersCount}</Table.TextCell>
            <Table.TextCell>
              {organization.projectsCountByStatus.total}
            </Table.TextCell>
            <Table.TextCell>
              {organization.projectsCountByStatus.active}
            </Table.TextCell>
            <Table.TextCell>
              {organization.projectsCountByStatus.inactive}
            </Table.TextCell>
            <Table.TextCell>
              {organization.projectsCountByStatus.preDevelopment}
            </Table.TextCell>
            <Table.TextCell>
              {organization.projectsWithPayApplicationsCount > 0 && (
                <React.Fragment>
                  <Icon icon={TickIcon} marginRight={majorScale(1)} />
                  {`(${organization.projectsWithPayApplicationsCount})`}
                </React.Fragment>
              )}
            </Table.TextCell>
            <ForwardCreditsAvailable organization={organization} />
            <Table.TextCell>
              {organization.totalAddressableProjectCount > 0
                ? formatPercent(
                    organization.projectsCountByStatus.total /
                      organization.totalAddressableProjectCount
                  )
                : "N/A"}
            </Table.TextCell>
            <Table.TextCell>{organization.drawsCount}</Table.TextCell>
            <Table.TextCell>
              {formatCurrency(organization.projectsAmount)}
            </Table.TextCell>
            <Table.TextCell>
              {formatCurrency(organization.drawsAmount)}
            </Table.TextCell>
            <Table.TextCell>
              {organization.inactiveAccount ? "Inactive" : "Active"}
            </Table.TextCell>
            <Table.TextCell>
              {formatBoolean(organization.demoAccount)}
            </Table.TextCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

OrganizationsTable.propTypes = {
  organizations: PropTypes.array.isRequired,
};

const ForwardCreditsAvailable = ({ organization }) => {
  if (Organizations.isChargeableOrganization(organization)) {
    return (
      <Table.TextCell>
        <Link
          to={`/organizations/${organization.id}/project_month_credits_activities`}
        >
          {organization.projectMonthCredits}
        </Link>
      </Table.TextCell>
    );
  }
  if (!Organizations.isContractStartDateAssigned(organization)) {
    return (
      <Table.TextCell>
        <Link to={`/organizations/${organization.id}/edit`}>
          Missing Contract Start Date
        </Link>
      </Table.TextCell>
    );
  }
  if (typeof organization.projectMonthCredits !== "number") {
    return (
      <Table.TextCell>
        <Link
          to={`/organizations/${organization.id}/project_month_credits_activities`}
        >
          Credits must be added
        </Link>
      </Table.TextCell>
    );
  }
  throw new Error(
    `Unexpected projectMonthCredits or contractStartDate values for Organization ${organization.id}`
  );
};
