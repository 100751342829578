import React from "react";
import {
  Button,
  Dialog,
  DragAndDrop,
  DragHandleHorizontalIcon,
  Icon,
  Pane,
  Paragraph,
  FormDropdown,
  FormInput,
  FormTextarea,
} from "components/materials";
import { majorScale, preventEventBubbling, t } from "helpers/utilities";
import { capitalize, get, isNull } from "lodash";

function addCustomField(push) {
  push({
    label: null,
    description: null,
    fieldType: "text",
    hasResponses: false,
  });
}

function prepareFieldItems(fields) {
  return fields.map((field, index) => ({
    ...field,
    key: field.id,
    value: field,
    index,
  }));
}

function CustomField({
  customFieldName,
  field,
  form,
  itemHeight,
  onRemove,
  provided,
}) {
  return (
    <Pane
      height={itemHeight}
      minWidth={1500}
      paddingTop={majorScale(1)}
      borderBottom
      display="flex"
      alignItems="center"
    >
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={majorScale(6)}
        marginRight={majorScale(4)}
        {...provided.dragHandleProps}
      >
        <Icon icon={DragHandleHorizontalIcon} size={20} />
      </Pane>
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="#DDEBF7"
        color="#084B8A"
        height={40}
        width={40}
        borderRadius={20}
        marginRight={majorScale(4)}
      >
        {field.index + 1}
      </Pane>
      <FormTextarea
        label="Questionnaire Prompt"
        name={`${customFieldName}.${field.index}.label`}
        marginRight={majorScale(2)}
      />
      <FormTextarea
        label="Description or Example"
        name={`${customFieldName}.${field.index}.description`}
        width={325}
        marginRight={majorScale(2)}
      />
      <FormDropdown
        label="Field Type"
        name={`${customFieldName}.${field.index}.fieldType`}
        options={[
          { id: "text", name: "text" },
          { id: "textarea", name: "textarea" },
        ]}
        marginRight={majorScale(2)}
      />
      <Pane>
        <FormInput
          label="Template Name (ex: my_new_field)"
          name={`${customFieldName}.${field.index}.reportTemplateFieldName`}
          marginRight={majorScale(2)}
        />
        {customFieldName === "drawAssessmentQuestions" && (
          <Paragraph marginTop={-majorScale(2)}>
            <Paragraph>Formatting for Draw Report:</Paragraph>
            <Paragraph maxWidth={250}>
              {(
                get(
                  form,
                  `values.${customFieldName}.${field.index}.reportTemplateFieldName`
                ) || ""
              )
                .split("_")
                .map((string) => capitalize(string))
                .join(" ")}
            </Paragraph>
          </Paragraph>
        )}
      </Pane>
      <Button
        onClick={(e) => {
          preventEventBubbling(e);
          onRemove(field.key);
        }}
        height={32}
      >
        Remove Field
      </Button>
    </Pane>
  );
}

export function CustomFieldsForm({
  addButtonCopy,
  customFieldName,
  deleteWarningMessage,
  deleteWarningTitle,
  form,
  push,
  remove,
}) {
  const [showConfirmDeleteIndex, setShowConfirmDeleteIndex] =
    React.useState(null);

  const handleUpdate = (updatedValues) => {
    form.setFieldValue(
      customFieldName,
      updatedValues.map(({ value }) => value)
    );
  };

  const handleRemove = ({ hasResponses, index }) => {
    if (hasResponses) setShowConfirmDeleteIndex(index);
    else remove(index);
  };

  return (
    <React.Fragment>
      <Pane>
        <Paragraph fontSize={12} marginBottom={majorScale(1)}>
          {t(`helpText.${customFieldName}`)}
        </Paragraph>
        <DragAndDrop
          items={prepareFieldItems(form.values[customFieldName])}
          onUpdate={handleUpdate}
          onRemove={handleRemove}
          itemHeight={175}
          renderItem={({
            index,
            item: field,
            itemHeight,
            onRemove,
            provided,
          }) => (
            <CustomField
              customFieldName={customFieldName}
              field={field}
              form={form}
              index={index}
              itemHeight={itemHeight}
              onRemove={onRemove}
              provided={provided}
            />
          )}
        />
        <Button
          height={24}
          type="button"
          marginY={majorScale(1)}
          onClick={() => addCustomField(push)}
        >
          {addButtonCopy}
        </Button>
      </Pane>
      {!isNull(showConfirmDeleteIndex) && (
        <Dialog
          isShown
          hasFooter
          onCloseComplete={() => setShowConfirmDeleteIndex(null)}
          onConfirm={(close) => {
            remove(showConfirmDeleteIndex);
            close();
          }}
          title={deleteWarningTitle}
        >
          {deleteWarningMessage}
        </Dialog>
      )}
    </React.Fragment>
  );
}
