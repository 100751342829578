import React from "react";
import PropTypes from "prop-types";
import { FastField, getIn } from "formik";
import { FormField, minorScale, Pane, Radio, Text } from "evergreen-ui";
import { map } from "lodash";

const FormRadioGroup = ({
  label,
  marginRight,
  name,
  onChange,
  options,
  optionText,
  size,
  stackedOptions,
  stackedOptionText,
  ...props
}) => {
  return (
    <FastField name={name}>
      {({ field, form }) => {
        const error = getIn(form.errors, name);

        return (
          <FormField
            label={label}
            isRequired
            validationMessage={!!error && error}
            {...props}
          >
            {optionText ? <Text fontSize={12}>{optionText}</Text> : null}
            <Pane display="flex" role="group">
              {map(options, (item) => (
                <Radio
                  {...field}
                  checked={item.value === field.value}
                  disabled={item.isDisabled}
                  key={item.value}
                  label={item.label}
                  marginRight={marginRight}
                  onChange={({ target }) => onChange(target)}
                  size={size}
                  value={item.value}
                />
              ))}
            </Pane>
            {stackedOptionText ? (
              <Text fontSize={12} fontStyle="italic">
                {stackedOptionText}
              </Text>
            ) : null}
            {stackedOptions.length > 0 && (
              <Pane display="flex" flexWrap="wrap" role="group">
                {map(stackedOptions, (stackedItem) => (
                  <Radio
                    {...field}
                    checked={stackedItem.value === field.value}
                    disabled={stackedItem.isDisabled}
                    key={stackedItem.value}
                    label={stackedItem.label}
                    marginRight={marginRight}
                    onChange={({ target }) => onChange(target)}
                    size={size}
                    value={stackedItem.value}
                  />
                ))}
              </Pane>
            )}
          </FormField>
        );
      }}
    </FastField>
  );
};

FormRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  labelProps: PropTypes.object,
  marginRight: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  optionText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.string.isRequired,
      isDisabled: PropTypes.bool,
    }).isRequired
  ).isRequired,
  size: PropTypes.number,
  stackedOptionText: PropTypes.string,
  stackedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.string.isRequired,
      isDisabled: PropTypes.bool,
    })
  ),
};

FormRadioGroup.defaultProps = {
  labelProps: {},
  marginRight: minorScale(5),
  size: minorScale(3),
  stackedOptions: {},
};

export default FormRadioGroup;
