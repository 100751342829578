import React from "react";
import gql from "graphql-tag";
import { Query, Mutation } from "@apollo/react-components";
import { Formik } from "formik";
import { Segment, Loading } from "components/materials";
import { ProjectTemplateForm } from "components/templates";
import { toaster } from "helpers/utilities";

const ProjectTemplateNewPage = (props) => {
  const { history } = props;
  const { organizationId } = props.match.params;

  const renderContent = ({ data, error, loading }) => {
    if (loading) return <Loading />;
    if (error) return null;

    const { organization } = data;

    const organizationCustomFields = organization.projectTemplates.flatMap(
      ({ customFields }) => {
        return customFields;
      }
    );
    return (
      <Mutation
        mutation={ProjectTemplateNewPage.CreateProjectTemplate}
        onCompleted={({ createProjectTemplate }) => {
          history.push(
            `/organizations/${organizationId}/project_templates/${createProjectTemplate.id}/edit`
          );
          toaster.success("Project template created");
        }}
      >
        {(mutation, result) => {
          return (
            <Formik
              initialValues={ProjectTemplateForm.initialValuesNew(
                organizationId
              )}
              validate={ProjectTemplateForm.validate}
              onSubmit={ProjectTemplateForm.onSubmit(mutation)}
            >
              {(form) => (
                <ProjectTemplateForm
                  form={form}
                  result={result}
                  organizationCustomFields={organizationCustomFields}
                  organizationName={organization.name}
                />
              )}
            </Formik>
          );
        }}
      </Mutation>
    );
  };

  return (
    <Segment heading="Basic Info">
      <Query
        query={ProjectTemplateNewPage.Query}
        variables={{ organizationId }}
      >
        {renderContent}
      </Query>
    </Segment>
  );
};

ProjectTemplateNewPage.Query = gql`
  query ProjectTemplateNewPageQuery($organizationId: String!) {
    organization(id: $organizationId) {
      id
      name
      projectTemplates {
        id
        customFields {
          id
          label
          options
          reportTemplateFieldName
          type
        }
      }
    }
  }
`;

ProjectTemplateNewPage.CreateProjectTemplate = gql`
  mutation CreateProjectTemplate(
    $organizationId: String!
    $name: String!
    $icon: ProjectIcon
    $rules: [ProjectTemplateRuleInput]!
    $customFields: [TemplateCustomFieldInput]
  ) {
    createProjectTemplate(
      organizationId: $organizationId
      name: $name
      icon: $icon
      rules: $rules
      customFields: $customFields
    ) {
      id
    }
  }
`;

export default ProjectTemplateNewPage;
