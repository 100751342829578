import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import {
  OrganizationPermissionsTable,
  OrganizationsTable,
} from "components/templates";
import {
  Button,
  ButtonLink,
  CaretDownIcon,
  Checkbox,
  FormInput,
  IconButton,
  Loading,
  Pane,
  Paragraph,
  Radio,
  SelectMenu,
  Segment,
  TextInput,
} from "components/materials";
import { isBlank, majorScale, t } from "helpers/utilities";
import { PERMISSION_ACTION } from "helpers/enums";
import { trim, values, xor } from "lodash";

const QUERY = gql`
  query OrganizationsPageQuery(
    $filterPermissions: [String]
    $filterType: Boolean
    $includeDemoAccounts: Boolean
    $includeInactiveAccounts: Boolean
    $organizationName: String
  ) {
    organizations(
      filterPermissions: $filterPermissions
      filterType: $filterType
      includeDemoAccounts: $includeDemoAccounts
      includeInactiveAccounts: $includeInactiveAccounts
      organizationName: $organizationName
    ) {
      id
      drawsAmount
      drawsCount
      name
      totalAddressableProjectCount
      projectMonthCredits
      projectsAmount
      projectsCountByStatus
      usersCount
      demoAccount
      inactiveAccount
      projectsWithPayApplicationsCount
      contractStartDate
    }
  }
`;

const PERMISSIONS_QUERY = gql`
  query OrganizationPermissionsQuery(
    $filterPermissions: [String]
    $filterType: Boolean
    $includeDemoAccounts: Boolean
    $organizationName: String
  ) {
    organizations(
      filterPermissions: $filterPermissions
      filterType: $filterType
      includeDemoAccounts: $includeDemoAccounts
      organizationName: $organizationName
    ) {
      id
      name
      permissionConfig
    }
  }
`;

const filterTypeOptions = [
  { label: "Developer", value: true },
  { label: "Not Developer", value: false },
  { label: "All", value: null },
];

const getPermissionOptions = () =>
  values(PERMISSION_ACTION).map((permission) => ({
    label: t(`permissions.${permission}`),
    value: permission,
  }));

const Organizations = ({ filterVariables }) => {
  const { data, error, loading } = useQuery(QUERY, {
    variables: filterVariables,
  });

  if (loading) return <Loading />;
  if (error) return null;

  return <OrganizationsTable organizations={data.organizations} />;
};

function OrganizationPermissions({ filterVariables }) {
  const { data, error, loading } = useQuery(PERMISSIONS_QUERY, {
    variables: filterVariables,
  });

  if (loading) return <Loading />;
  if (error) return null;

  return <OrganizationPermissionsTable organizations={data.organizations} />;
}

export function OrganizationsPage() {
  const [showPermissionsTable, setShowPermissionsTable] = React.useState(false);
  const [includeDemoAccounts, setIncludeDemoAccounts] = React.useState(false);
  const [includeInactiveAccounts, setIncludeInactiveAccounts] =
    React.useState(false);
  const [organizationName, setOrganizationName] = React.useState(null);
  const [searchButtonStyle, setSearchButtonStyle] = React.useState("default");
  const [filterPermissions, setFilterPermissions] = React.useState([]);
  const [filterType, setFilterType] = React.useState(null);

  const filterVariables = {
    includeDemoAccounts,
    includeInactiveAccounts,
    organizationName,
    filterType,
    filterPermissions,
  };

  return (
    <Segment
      heading="Organizations"
      action={
        <ButtonLink to="/organizations/new">Add new organization</ButtonLink>
      }
    >
      <Pane display="flex" alignItems="center">
        <Formik
          initialValues={{ search: "" }}
          onSubmit={(formValues) => {
            setOrganizationName(
              isBlank(formValues.search) ? null : trim(formValues.search)
            );
            setSearchButtonStyle("default");
          }}
        >
          {(form) => (
            <Pane
              display="flex"
              alignItems="flex-end"
              marginRight={majorScale(5)}
            >
              <FormInput
                label="Organization Search"
                name="search"
                type="text"
                marginBottom={0}
                marginRight={majorScale(1)}
                onChange={() => setSearchButtonStyle("primary")}
                placeholder="Enter name - partial matches ok"
              />
              <Button
                appearance={searchButtonStyle}
                onClick={form.handleSubmit}
              >
                Search by name
              </Button>
            </Pane>
          )}
        </Formik>
        <Pane display="flex" flexDirection="column" marginRight={majorScale(5)}>
          <Checkbox
            checked={includeDemoAccounts}
            label="Include demo accounts?"
            marginBottom={0}
            onChange={(e) => setIncludeDemoAccounts(e.target.checked)}
          />
          <Checkbox
            checked={includeInactiveAccounts}
            label="Include inactive accounts?"
            onChange={(e) => setIncludeInactiveAccounts(e.target.checked)}
          />
        </Pane>
        <Pane display="flex" flexDirection="column" marginRight={majorScale(5)}>
          {filterTypeOptions.map(({ label, value }) => (
            <Radio
              checked={filterType === value}
              key={label}
              label={label}
              onChange={() => setFilterType(value)}
            />
          ))}
        </Pane>
        <SelectMenu
          isMultiSelect
          hasFilter={false}
          hasTitle={false}
          options={getPermissionOptions()}
          width={350}
          selected={filterPermissions}
          onSelect={({ value }) =>
            setFilterPermissions(xor(filterPermissions, [value]))
          }
          onDeselect={({ value }) =>
            setFilterPermissions(xor(filterPermissions, [value]))
          }
        >
          <Pane
            display="flex"
            alignItems="flex-end"
            marginRight={majorScale(5)}
          >
            <TextInput
              color={filterPermissions.length === 0 ? "muted" : null}
              onChange={() => {}}
              value={
                filterPermissions.length === 0
                  ? "Filter by enabled features..."
                  : t("organizationSearch.numSelected", {
                      count: filterPermissions.length,
                    })
              }
            />
            <IconButton type="button" icon={CaretDownIcon} />
          </Pane>
        </SelectMenu>
        <Button onClick={() => setShowPermissionsTable((state) => !state)}>
          {showPermissionsTable ? "Default Table" : "Permissions Table"}
        </Button>
      </Pane>
      <Paragraph marginTop={majorScale(1)}>
        {filterPermissions.length > 0 &&
          `All Organizations below have the following Permissions enabled: ${filterPermissions
            .map((permission) => t(`permissions.${permission}`))
            .join(", ")}`}
      </Paragraph>
      <Pane marginTop={majorScale(2)}>
        {showPermissionsTable ? (
          <OrganizationPermissions filterVariables={filterVariables} />
        ) : (
          <Organizations filterVariables={filterVariables} />
        )}
      </Pane>
    </Segment>
  );
}
