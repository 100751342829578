import React from "react";
import gql from "graphql-tag";
import { Formik } from "formik";
import { Mutation } from "@apollo/react-components";
import { OrganizationNewForm } from "components/templates";
import { Segment } from "components/materials";
import { toaster } from "helpers/utilities";

const handleCompleted = (history) => (data) => {
  history.push(`/organizations/${data.addOrganization.id}/edit`);
  toaster.success("Organization was created");
};

const OrganizationNewPage = (props) => {
  return (
    <Segment heading="Basic info">
      <Mutation
        mutation={OrganizationNewPage.NewOrganization}
        onCompleted={handleCompleted(props.history)}
      >
        {(mutation, result) => (
          <Formik
            validateOnBlur={false}
            initialValues={OrganizationNewForm.initialValues()}
            validate={OrganizationNewForm.validate}
            onSubmit={OrganizationNewForm.onSubmit(mutation)}
          >
            {(form) => <OrganizationNewForm form={form} result={result} />}
          </Formik>
        )}
      </Mutation>
    </Segment>
  );
};

OrganizationNewPage.NewOrganization = gql`
  mutation AddOrganization(
    $demoAccount: Boolean
    $email: String!
    $inactiveAccount: Boolean
    $name: String!
    $password: String!
    $permissions: [PermissionInput]!
    $type: OrganizationType!
  ) {
    addOrganization(
      demoAccount: $demoAccount
      email: $email
      inactiveAccount: $inactiveAccount
      name: $name
      password: $password
      permissions: $permissions
      type: $type
    ) {
      id
    }
  }
`;

export default OrganizationNewPage;
