import React from "react";
import gql from "graphql-tag";
import { Mutation } from "@apollo/react-components";
import { Formik } from "formik";
import { Segment } from "components/materials";
import { IntegrationForm } from "components/templates";
import { toaster } from "helpers/utilities";

function IntegrationNewPage(props) {
  const { history } = props;
  const { organizationId } = props.match.params;

  return (
    <Segment heading="Configure Integration">
      <Mutation
        mutation={IntegrationNewPage.CreateIntegration}
        onCompleted={({ createIntegration }) => {
          history.push(
            `/organizations/${organizationId}/integrations/${createIntegration.id}/edit`
          );
          toaster.success("Integration created");
        }}
      >
        {(mutation, result) => {
          return (
            <Formik
              initialValues={IntegrationForm.initialValuesNew(organizationId)}
              validate={IntegrationForm.validate}
              onSubmit={IntegrationForm.onSubmit(mutation)}
            >
              {(form) => <IntegrationForm form={form} result={result} />}
            </Formik>
          );
        }}
      </Mutation>
    </Segment>
  );
}

IntegrationNewPage.CreateIntegration = gql`
  mutation CreateIntegration(
    $organizationId: String!
    $webhookUrl: String!
    $webhookPayload: String!
    $responsePayload: String!
    $trigger: IntegrationTrigger!
    $conditions: [IntegrationConditionInput]
    $headers: [IntegrationHeaderInput]
    $enabled: Boolean!
  ) {
    createIntegration(
      organizationId: $organizationId
      webhookUrl: $webhookUrl
      webhookPayload: $webhookPayload
      responsePayload: $responsePayload
      trigger: $trigger
      conditions: $conditions
      headers: $headers
      enabled: $enabled
    ) {
      id
    }
  }
`;

export default IntegrationNewPage;
