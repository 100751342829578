import React from "react";
import PropTypes from "prop-types";
import { Heading, HelpIcon, Icon, Pane, Tooltip } from "evergreen-ui";
import { majorScale } from "helpers/utilities";

function Segment({ children, heading, action, helpText, ...rest }) {
  return (
    <Pane marginBottom={majorScale(2)} {...rest}>
      <Pane
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        marginBottom={majorScale(1)}
      >
        <Pane flex="1 1 auto" alignItems="center" display="flex">
          <Heading marginRight={5}>{heading}</Heading>
          {helpText && (
            <Tooltip content={helpText}>
              <Icon icon={HelpIcon} size={15} />
            </Tooltip>
          )}
        </Pane>
        {action}
      </Pane>
      {children}
    </Pane>
  );
}

Segment.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node.isRequired,
  heading: PropTypes.string,
};

Segment.defaultProps = {
  action: null,
};

export default Segment;
