import React from "react";
import { Formik } from "formik";
import {
  Button,
  Checkbox,
  EvergreenLink,
  Form,
  FormCheckbox,
  FormDropdown,
  FormFile,
  Pane,
  Paragraph,
  Shortener,
  Table,
} from "components/materials";
import { groupBy } from "lodash";
import { DOCUMENT_TYPE_NAME } from "helpers/enums";
import { majorScale, minorScale, t } from "helpers/utilities";

export function DocumentTemplates({
  deleteDocumentTemplate,
  deleteLoading,
  documentTemplates,
  uploadDocumentTemplate,
  uploadLoading,
  updateDocumentTemplate,
  organizationId,
}) {
  const {
    [DOCUMENT_TYPE_NAME.DRAW_COVER_SHEET]: drawCoverSheets,
    [DOCUMENT_TYPE_NAME.INVOICE]: invoices,
  } = groupBy(documentTemplates, "type");

  return (
    <Pane paddingTop={majorScale(2)}>
      {drawCoverSheets && (
        <Pane marginBottom={majorScale(3)}>
          <Paragraph fontWeight={600} marginBottom={majorScale(1)}>
            Draw Cover Sheets
          </Paragraph>
          <Table border="default">
            <Table.Body>
              {drawCoverSheets.map((drawCoverSheetTemplate) => (
                <Table.Row key={drawCoverSheetTemplate.id}>
                  <Table.Cell>
                    <Shortener
                      limit={25}
                      fontSize={12}
                      text={drawCoverSheetTemplate.name}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Checkbox
                      label="Draw Package"
                      checked={drawCoverSheetTemplate.drawPackageDefault}
                      onChange={(event) => {
                        updateDocumentTemplate({
                          variables: {
                            id: drawCoverSheetTemplate.id,
                            drawPackageDefault:
                              !drawCoverSheetTemplate.drawPackageDefault,
                          },
                        });
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <EvergreenLink href={drawCoverSheetTemplate.url}>
                      Download
                    </EvergreenLink>
                  </Table.Cell>
                  <Table.Cell>
                    <EvergreenLink
                      href={drawCoverSheetTemplate.convertToPdfUrl}
                      target="_blank"
                    >
                      Download as PDF
                    </EvergreenLink>
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      isLoading={deleteLoading}
                      onClick={() =>
                        deleteDocumentTemplate({
                          variables: { id: drawCoverSheetTemplate.id },
                        })
                      }
                    >
                      Delete
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Paragraph
            marginTop={minorScale(1)}
            paddingLeft={majorScale(1)}
            fontStyle="italic"
          >
            * docx to pdf conversion is not perfect - please check the
            conversion after uploading.
          </Paragraph>
        </Pane>
      )}
      {invoices && (
        <Pane marginBottom={majorScale(3)}>
          <Paragraph fontWeight={600} marginBottom={majorScale(1)}>
            Invoices
          </Paragraph>
          <Table border="default">
            <Table.Body>
              {invoices.map((invoiceTemplate) => (
                <Table.Row key={invoiceTemplate.id}>
                  <Table.Cell minWidth={225}>
                    <Shortener
                      limit={30}
                      fontSize={12}
                      text={invoiceTemplate.name}
                    />
                  </Table.Cell>
                  <Table.Cell />
                  <Table.Cell>
                    <EvergreenLink href={invoiceTemplate.url} target="_blank">
                      Download
                    </EvergreenLink>
                  </Table.Cell>
                  <Table.Cell />
                  <Table.Cell>
                    <Button
                      isLoading={deleteLoading}
                      onClick={() =>
                        deleteDocumentTemplate({
                          variables: { id: invoiceTemplate.id },
                        })
                      }
                    >
                      Delete
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Pane>
      )}
      <DocumentTemplateForm
        uploadDocumentTemplate={uploadDocumentTemplate}
        uploadLoading={uploadLoading}
        organizationId={organizationId}
      />
    </Pane>
  );
}

function DocumentTemplateForm({
  uploadDocumentTemplate,
  uploadLoading,
  organizationId,
}) {
  const handleUpload = React.useCallback(
    (values, { resetForm }) => {
      const isDrawCoverSheet =
        values.type === DOCUMENT_TYPE_NAME.DRAW_COVER_SHEET;
      uploadDocumentTemplate({
        variables: {
          ...values,
          drawPackageDefault: isDrawCoverSheet
            ? values.drawPackageDefault
            : false,
          organizationId,
        },
      }).then(() => resetForm());
    },
    [uploadDocumentTemplate, organizationId]
  );

  return (
    <Pane>
      <Paragraph fontWeight={600} marginBottom={majorScale(1)}>
        Upload New Template
      </Paragraph>
      <Formik
        initialValues={{
          type: DOCUMENT_TYPE_NAME.DRAW_COVER_SHEET,
          drawPackageDefault: false,
          file: null,
        }}
        validate={validate}
        onSubmit={handleUpload}
      >
        {(form) => (
          <Form isLoading={uploadLoading} onSubmit={form.handleSubmit}>
            <FormDropdown
              label="Type"
              name="type"
              options={getTypeOptions()}
              // the two templates we support require different file types
              // changing the type will make any selected file invalid, so we will reset it
              onChange={() => {
                form.setFieldValue("file", null);
              }}
            />
            {!uploadLoading && (
              <FormFile
                // the key prevents the file name from showing in the text box
                // after the file has been reset to null
                key={form.values.type}
                label="File"
                name="file"
                accept={
                  form.values.type === DOCUMENT_TYPE_NAME.DRAW_COVER_SHEET
                    ? ".docx"
                    : ".pdf"
                }
                marginBottom={majorScale(1)}
              />
            )}
            {form.values.type === DOCUMENT_TYPE_NAME.DRAW_COVER_SHEET && (
              <FormCheckbox
                label="Include in Default Draw Package"
                name="drawPackageDefault"
              />
            )}
          </Form>
        )}
      </Formik>
    </Pane>
  );
}

function getTypeOptions() {
  return [
    {
      id: DOCUMENT_TYPE_NAME.DRAW_COVER_SHEET,
      name: t(`documentTypes.${DOCUMENT_TYPE_NAME.DRAW_COVER_SHEET}`),
    },
    {
      id: DOCUMENT_TYPE_NAME.INVOICE,
      name: t(`documentTypes.${DOCUMENT_TYPE_NAME.INVOICE}`),
    },
  ];
}

function validate(values) {
  const errors = {};
  if (!values.id && !values.file) errors.file = "File is required";
  return errors;
}
