import React from "react";
import PropTypes from "prop-types";
import { Button, Table, Text } from "components/materials";
import { datetimeServerToForm } from "helpers/dateHelpers";

export function ScheduledNotificationsTable({
  notifications,
  deleteNotification,
  expireNotification,
}) {
  return (
    <Table border="default">
      <Table.Head>
        <Table.TextHeaderCell>Notification Description</Table.TextHeaderCell>
        <Table.TextHeaderCell>Sent At</Table.TextHeaderCell>
        <Table.TextHeaderCell>Expired?</Table.TextHeaderCell>
        <Table.TextHeaderCell>Action</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {notifications.map((notification) => (
          <Table.Row key={notification.id}>
            <Table.Cell>
              <Text>{notification.description}</Text>
            </Table.Cell>
            <Table.Cell>
              <Text>{`${datetimeServerToForm(notification.lastSentAt)}`}</Text>
            </Table.Cell>
            <Table.Cell>
              <Text>{notification.expired ? "Yes" : "No"}</Text>
            </Table.Cell>
            <Table.Cell>
              {!notification.lastSentAt ? (
                <Button
                  onClick={() =>
                    deleteNotification({
                      variables: { id: notification.id },
                    })
                  }
                >
                  Delete Notification
                </Button>
              ) : null}
              {!!notification.lastSentAt && !notification.expired ? (
                <Button
                  onClick={() =>
                    expireNotification({
                      variables: { id: notification.id },
                    })
                  }
                >
                  Expire Notification
                </Button>
              ) : null}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

ScheduledNotificationsTable.propTypes = {
  notifications: PropTypes.array.isRequired,
};
