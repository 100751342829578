import React from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  NotificationSettingsForm,
  ScheduledNotificationsTable,
} from "components/templates";
import {
  Link,
  Loading,
  Pane,
  Paragraph,
  Segment,
  Text,
} from "components/materials";
import { majorScale, toaster } from "helpers/utilities";
import { SendNotifications } from "./SendNotifications";

export function UserPage({ match }) {
  const { userId } = match.params;
  const { data, error, loading } = useQuery(USER_PAGE_QUERY, {
    variables: { id: userId },
  });

  const [updateNotificationSettings, updateResult] = useMutation(
    UPDATE_NOTIFICATION_SETTINGS,
    {
      onCompleted: () => toaster.success("User notification settings updated"),
    }
  );

  const refetchQueries = [
    {
      query: USER_PAGE_QUERY,
      variables: { id: userId },
    },
  ];

  const [deleteNotification] = useMutation(DELETE_SCHEDULED_NOTIFICATION, {
    onCompleted: () => toaster.success("Notification Deleted"),
    awaitRefetchQueries: true,
    refetchQueries,
  });

  const [expireNotification] = useMutation(EXPIRE_SCHEDULED_NOTIFICATION, {
    onCompleted: () => toaster.success("Notification Expired"),
    awaitRefetchQueries: true,
    refetchQueries,
  });

  const [sendNotification] = useMutation(SEND_NOTIFICATION, {
    onCompleted: () => toaster.success("Notification sent"),
    onError: () => toaster.success("Error sending notification"),
    awaitRefetchQueries: true,
    refetchQueries,
  });

  if (loading) return <Loading />;
  if (error) return null;

  const { user } = data;

  return (
    <Pane>
      <Segment heading="User Details">
        <Paragraph>Name: {user.name}</Paragraph>
        <Paragraph>Email: {user.email}</Paragraph>
        <Pane>
          <Text>Organization: </Text>
          <Link to={`/organizations/${user.organization.id}/edit`}>
            {user.organization.name}
          </Link>
        </Pane>
      </Segment>
      <Segment heading="User Notification Settings" marginTop={majorScale(5)}>
        <NotificationSettingsForm
          user={user}
          updateNotificationSettings={updateNotificationSettings}
          result={updateResult}
        />
      </Segment>
      <Segment heading="Send Notifications" marginTop={majorScale(5)}>
        <SendNotifications sendNotification={sendNotification} user={user} />
      </Segment>
      <Segment heading="Recently Sent Notifications" marginTop={majorScale(5)}>
        <ScheduledNotificationsTable
          notifications={user.scheduledNotifications}
          deleteNotification={deleteNotification}
          expireNotification={expireNotification}
        />
      </Segment>
    </Pane>
  );
}

const USER_PAGE_QUERY = gql`
  query UserPageQuery($id: String!) {
    user(id: $id) {
      id
      name
      email
      scheduledNotifications {
        id
        description
        expired
        lastSentAt
      }
      notificationSettings {
        notificationType
        enabled
        dayOffset
        frequency
      }
      organization {
        id
        name
        permissionConfig
      }
    }
  }
`;

const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation UpdateNotificationSettings(
    $userId: String!
    $notificationSettings: [NotificationSettingInput]
  ) {
    updateNotificationSettings(
      userId: $userId
      notificationSettings: $notificationSettings
    ) {
      id
    }
  }
`;

const EXPIRE_SCHEDULED_NOTIFICATION = gql`
  mutation ExpireScheduledNotification($id: String!) {
    expireScheduledNotification(id: $id) {
      id
    }
  }
`;

const DELETE_SCHEDULED_NOTIFICATION = gql`
  mutation DeleteScheduledNotification($id: String!) {
    deleteScheduledNotification(id: $id) {
      id
    }
  }
`;

const SEND_NOTIFICATION = gql`
  mutation SendNotification(
    $userId: String!
    $notificationType: NotificationType!
  ) {
    sendNotification(userId: $userId, notificationType: $notificationType) {
      id
    }
  }
`;
