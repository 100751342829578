import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Loading, Pane, Text } from "components/materials";
import { majorScale } from "helpers/utilities";
import { ProjectConnectionsTable } from "components/templates";
import { get } from "lodash";

const ProjectConnectionsPage = () => {
  const { data, error, loading } = useQuery(ProjectConnectionsPage.Query);

  if (loading) return <Loading />;
  if (error) return null;

  return (
    <React.Fragment>
      <Pane display="flex" flexDirection="column" width="35%">
        <Text marginBottom={majorScale(1)}>
          Project connections associated under the same ID will appear adjacent
          in this table.
        </Text>
        <Text>
          We will only show Project Connection ID’s with activity{" "}
          <Text fontWeight="bold">within the last 90 days.</Text>
        </Text>
        <Text>
          Project Connection ID’s will be sorted by most recent activity.
        </Text>
        <Text>
          This table{" "}
          <Text fontWeight="bold">
            does not include projects under demo accounts.
          </Text>
        </Text>
      </Pane>
      <ProjectConnectionsTable
        projectConnections={get(data, "projectConnections")}
      />
    </React.Fragment>
  );
};

ProjectConnectionsPage.Query = gql`
  query ListProjectConnections {
    projectConnections {
      id
      amount
      correlationId
      name
      fundingSourcesUsed
      fundingSourcesTotal
      owner {
        id
        name
      }
      recentDraw {
        id
        fundedDate
        name
        requestedAmount
        state
        submittedDate
        updatedAt
      }
    }
  }
`;

export default ProjectConnectionsPage;
