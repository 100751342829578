/* eslint-disable no-console */

import { captureException, configureScope, init } from "@sentry/browser";

const dsn = process.env.REACT_APP_SENTRY_DSN;
const release = process.env.REACT_APP_RELEASE;

console.log(release);

export const initSentry = () => init({ dsn, release });

export const setSentry = (authResult) =>
  configureScope((scope) =>
    scope.setUser({
      email: authResult.idTokenPayload.name,
      id: authResult.idTokenPayload.sub,
    })
  );

export const captureSentry = (error) => captureException(error);
