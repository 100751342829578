import React from "react";
import gql from "graphql-tag";
import { Mutation, Query } from "@apollo/react-components";
import { Formik } from "formik";
import { Divider, Table, Text, Loading, Segment } from "components/materials";
import { toaster } from "helpers/utilities";
import { IntegrationForm } from "components/templates";

function renderContent({ data, error, loading }) {
  if (loading) return <Loading />;
  if (error) return null;

  const {
    organization: { integration },
  } = data;

  return (
    <Segment heading="Basic Info">
      <Mutation
        mutation={IntegrationEditPage.UpdateIntegration}
        onCompleted={() => {
          toaster.success("Integration updated");
        }}
      >
        {(mutation, result) => {
          return (
            <Formik
              initialValues={IntegrationForm.initialValuesEdit(integration)}
              validate={IntegrationForm.validate}
              onSubmit={IntegrationForm.onSubmit(mutation)}
            >
              {(form) => <IntegrationForm form={form} result={result} />}
            </Formik>
          );
        }}
      </Mutation>

      <Divider />

      {integration.results && (
        <Segment heading="Results">
          <Table border="default">
            <Table.Head>
              <Table.TextHeaderCell>Code</Table.TextHeaderCell>
              <Table.TextHeaderCell>Payload</Table.TextHeaderCell>
              <Table.TextHeaderCell>Message</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {integration.results.map(({ code, payload, message }) => (
                <Table.Row key={integration.id}>
                  <Table.Cell>
                    <Text>{code}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{payload}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{message}</Text>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      )}
    </Segment>
  );
}

function IntegrationEditPage(props) {
  return (
    <Query query={IntegrationEditPage.Query} variables={props.match.params}>
      {renderContent}
    </Query>
  );
}

IntegrationEditPage.Query = gql`
  query IntegrationEditPageQuery(
    $organizationId: String!
    $integrationId: String!
  ) {
    organization(id: $organizationId) {
      id
      integration(id: $integrationId) {
        id
        results {
          id
          message
          code
          payload
        }
        conditions {
          id
          property
          operator
          value
        }
        headers {
          id
          name
          value
        }
        webhookUrl
        webhookPayload
        responsePayload
        trigger
        enabled
      }
    }
  }
`;

IntegrationEditPage.UpdateIntegration = gql`
  mutation UpdateIntegration(
    $id: String!
    $trigger: IntegrationTrigger
    $webhookUrl: String
    $webhookPayload: String
    $responsePayload: String
    $conditions: [IntegrationConditionInput]
    $headers: [IntegrationHeaderInput]
    $enabled: Boolean
  ) {
    updateIntegration(
      id: $id
      trigger: $trigger
      webhookUrl: $webhookUrl
      webhookPayload: $webhookPayload
      responsePayload: $responsePayload
      conditions: $conditions
      headers: $headers
      enabled: $enabled
    ) {
      id
    }
  }
`;

export default IntegrationEditPage;
