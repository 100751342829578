import React from "react";
import PropTypes from "prop-types";
import { FastField } from "formik";
import {
  Checkbox,
  HelpIcon,
  Icon,
  Pane,
  Tooltip,
  WarningSignIcon,
} from "evergreen-ui";
import { majorScale } from "helpers/utilities";

const FormCheckbox = ({
  alertText,
  checked,
  helpText,
  onChange,
  validationMessage,
  ...props
}) => {
  const handleChange = (form, name, target) => {
    form.setFieldValue(name, target.checked);
  };

  return (
    <FastField name={props.name}>
      {({ field, form }) => (
        <Pane display="flex" alignItems="center" height={32}>
          <Checkbox
            {...props}
            {...field}
            checked={checked || field.value}
            value={undefined}
            marginRight={majorScale(1)}
            onChange={({ target }) =>
              (onChange && onChange(target)) ||
              handleChange(form, props.name, target)
            }
          />
          {!!helpText && (
            <Tooltip content={helpText}>
              <Icon icon={HelpIcon} size={10} />
            </Tooltip>
          )}
          {!!alertText && (
            <Tooltip content={alertText}>
              <Icon
                icon={WarningSignIcon}
                color="warning"
                size={10}
                marginLeft={helpText && majorScale(1)}
              />
            </Tooltip>
          )}
        </Pane>
      )}
    </FastField>
  );
};

FormCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default FormCheckbox;
