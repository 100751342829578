import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { FormInput, Form } from "components/materials";
import { Formik } from "formik";
import { isBlank, t, toaster } from "helpers/utilities";
import { UPDATE_ORGANIZATION_POST_PAYMENT_SETTINGS_MUTATION } from "./graphql-queries";

export function AvidPaymentSettingsForm({
  organizationId,
  avidPostPaymentEmailAddress,
  hasIntegration,
}) {
  const [updatePostPaymentSettings, updatePostPaymentSettingsResult] =
    useMutation(UPDATE_ORGANIZATION_POST_PAYMENT_SETTINGS_MUTATION, {
      onCompleted: () => toaster.success("Organization updated"),
    });

  const validate = (formData) => {
    const errors = {};
    if (isBlank(formData.avidPostPaymentEmailAddress) && hasIntegration) {
      errors.avidPostPaymentEmailAddress = t(
        "accountsPayableSettingsForm.validate.requireAvidEmail"
      );
    }
    return errors;
  };

  return (
    <Formik
      initialValues={{
        avidPostPaymentEmailAddress: avidPostPaymentEmailAddress || undefined,
        id: organizationId,
      }}
      onSubmit={(data) => updatePostPaymentSettings({ variables: data })}
      validate={validate}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form
          isLoading={updatePostPaymentSettingsResult.loading}
          onSubmit={handleSubmit}
        >
          <FormInput
            placeholder="Avid Post Payment Email Address"
            name="avidPostPaymentEmailAddress"
            onChange={(e) =>
              setFieldValue("avidPostPaymentEmailAddress", e.target.value)
            }
          />
        </Form>
      )}
    </Formik>
  );
}
