import React from "react";
import PropTypes from "prop-types";
import { FastField } from "formik";
import { Button, FormField, Group } from "evergreen-ui";
import { preventEventBubbling } from "helpers/utilities";

const FormGroup = ({ label: groupLabel, name, options, ...rest }) => (
  <FastField name={name}>
    {({ field, form }) => {
      return (
        <FormField label={groupLabel}>
          <Group
            onChange={(value) => form.setFieldValue(name, value)}
            value={field.value}
            {...rest}
          >
            {options.map(({ label, value }) => {
              return (
                <Button
                  isActive={field.value === value}
                  key={value}
                  onClick={(e) => {
                    preventEventBubbling(e);
                    form.setFieldValue(name, value);
                  }}
                >
                  {label}
                </Button>
              );
            })}
          </Group>
        </FormField>
      );
    }}
  </FastField>
);

FormGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FormGroup;
