import { debounce } from "lodash";
import auth from "auth";

const expirationTime = 30 * 60 * 1000; // 30 minutes

const timer = debounce(auth.logout, expirationTime);

export const initInactivity = () => {
  timer();
  window.addEventListener("click", timer);
};
