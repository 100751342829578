import React from "react";
import PropTypes from "prop-types";
import { TextInputField } from "evergreen-ui";
import { FastField, getIn } from "formik";

const FormInput = ({ name, onChange, ...rest }) => (
  <FastField name={name}>
    {({ field, form }) => {
      const error = getIn(form.errors, name);
      const handleChange = (e) => {
        field.onChange(e);
        onChange(e);
      };
      return (
        <TextInputField
          {...rest}
          {...field}
          autoComplete="off"
          isInvalid={!!error}
          onChange={handleChange}
          validationMessage={error}
        />
      );
    }}
  </FastField>
);

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.number,
};

FormInput.defaultProps = {
  label: "",
  onChange: () => {},
  width: 240,
};

export default FormInput;
