import React from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { FormInput, FormDropdown, Form } from "components/materials";
import { Formik } from "formik";
import { YARDI_PLATFORM_TYPE } from "helpers/enums";
import { t, toaster } from "helpers/utilities";

export function YardiPaymentSettingsForm({
  accountsPayableSettings,
  organizationId,
  yardiUrl,
  yardiUserName,
  yardiPassword,
  yardiServerName,
  yardiDatabase,
  yardiPlatform,
}) {
  const [updateYardiPaymentSettings, updateYardiPaymentSettingsResult] =
    useMutation(UPDATE_ORGANIZATION_YARDI_PAYMENT_SETTINGS_MUTATION, {
      onCompleted: () => toaster.success("Organization updated"),
    });

  return (
    <Formik
      initialValues={{
        dayRangeForPullingPayables:
          accountsPayableSettings?.dayRangeForPullingPayables
            ? accountsPayableSettings.dayRangeForPullingPayables
            : 365,
        yardiUrl: yardiUrl || undefined,
        yardiUserName: yardiUserName || undefined,
        yardiPassword: yardiPassword || undefined,
        yardiServerName: yardiServerName || undefined,
        yardiDatabase: yardiDatabase || undefined,
        yardiPlatform:
          yardiPlatform === null
            ? YARDI_PLATFORM_TYPE.SQL_SERVER
            : yardiPlatform || undefined,
        id: organizationId,
      }}
      onSubmit={(data) => {
        const trimmedData = {
          ...data,
          yardiUrl: data.yardiUrl ? data.yardiUrl.trim() : "",
          yardiUserName: data.yardiUserName ? data.yardiUserName.trim() : "",
          yardiPassword: data.yardiPassword ? data.yardiPassword.trim() : "",
          yardiServerName: data.yardiServerName
            ? data.yardiServerName.trim()
            : "",
          yardiDatabase: data.yardiDatabase ? data.yardiDatabase.trim() : "",
          accountsPayableSettings: {
            dayRangeForPullingPayables: data.dayRangeForPullingPayables,
          },
        };
        return updateYardiPaymentSettings({ variables: trimmedData });
      }}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form
          isLoading={updateYardiPaymentSettingsResult.loading}
          onSubmit={handleSubmit}
        >
          <FormInput
            label="Root URL (ex: https://www.yardipcv.com/8223tp7s7dev)"
            name="yardiUrl"
            width={500}
          />
          <FormInput
            label="Interface Username (API user)"
            name="yardiUserName"
          />
          <FormInput
            label="API user's Password"
            name="yardiPassword"
            type="password"
          />
          <FormInput
            label="Database Server"
            placeholder="(Same as Database Name)"
            name="yardiServerName"
          />
          <FormInput
            label="Database Name"
            placeholder="(Same as Database Server)"
            name="yardiDatabase"
          />
          <FormDropdown
            label="Yardi Platform"
            name="yardiPlatform"
            options={[
              {
                id: YARDI_PLATFORM_TYPE.SQL_SERVER,
                name: t(`yardiPlatformTypes.${YARDI_PLATFORM_TYPE.SQL_SERVER}`),
              },
              {
                id: YARDI_PLATFORM_TYPE.ORACLE,
                name: t(`yardiPlatformTypes.${YARDI_PLATFORM_TYPE.ORACLE}`),
              },
            ]}
          />
          <FormInput
            label="Day Range to Pull Payables from Yardi (NOTE: this must be an integer)"
            name="dayRangeForPullingPayables"
            type="number"
          />
        </Form>
      )}
    </Formik>
  );
}

const UPDATE_ORGANIZATION_YARDI_PAYMENT_SETTINGS_MUTATION = gql`
  mutation UpdateOrganizationYardiPaymentSetttings(
    $id: String!
    $yardiUrl: String
    $yardiUserName: String
    $yardiPassword: String
    $yardiServerName: String
    $yardiDatabase: String
    $yardiPlatform: YardiPlatformType
    $accountsPayableSettings: AccountsPayableSettingsInput
  ) {
    updateOrganizationYardiPaymentSetttings(
      id: $id
      yardiUrl: $yardiUrl
      yardiUserName: $yardiUserName
      yardiPassword: $yardiPassword
      yardiServerName: $yardiServerName
      yardiDatabase: $yardiDatabase
      yardiPlatform: $yardiPlatform
      accountsPayableSettings: $accountsPayableSettings
    ) {
      id
      yardiUrl
      yardiUserName
      yardiPassword
      yardiServerName
      yardiDatabase
      yardiPlatform
      accountsPayableSettings {
        dayRangeForPullingPayables
      }
    }
  }
`;
