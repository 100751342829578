import React from "react";
import PropTypes from "prop-types";
import Segment from "components/materials/Segment";

function TabbedSegment({ isSelected, heading, tabbedHeadingOnly, ...rest }) {
  return (
    <Segment
      aria-labelledby={heading}
      aria-hidden={!isSelected}
      display={isSelected ? "block" : "none"}
      heading={tabbedHeadingOnly ? "" : heading}
      {...rest}
    />
  );
}

TabbedSegment.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
};

export default TabbedSegment;
