import { isEmpty, trim } from "lodash";

export const invalidEmail = (value) => {
  const re =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  return !re.test(value);
};

export const isBlank = (value) => {
  return isEmpty(trim(value));
};

export const isInvalidHexCode = (val) => !/^#([A-Fa-f0-9]{6})$/.test(val);
