import React from "react";
import { Button, Dialog, FormInput, Pane } from "components/materials";
import { majorScale, preventEventBubbling } from "helpers/utilities";
import { get, isNull } from "lodash";

export function OrganizationTeamsForm({ fieldArray }) {
  const teams = get(fieldArray, "form.values.teams");
  const { push, remove } = fieldArray;
  const [showConfirmDeleteIndex, setShowConfirmDeleteIndex] =
    React.useState(null);
  const handleRemove = ({ team, index }) => {
    if (team.hasProjects) setShowConfirmDeleteIndex(index);
    else remove(index);
  };
  return (
    <Pane paddingLeft={majorScale(2)} paddingTop={majorScale(2)}>
      {teams.map((team, index) => (
        <Pane display="flex" alignItems="center">
          <FormInput name={`teams.${index}.name`} />
          <Button
            marginLeft={majorScale(2)}
            intent="danger"
            onClick={(e) => {
              preventEventBubbling(e);
              handleRemove({ team, index });
            }}
          >
            Delete Team
          </Button>
        </Pane>
      ))}
      <Pane>
        <Button
          onClick={(e) => {
            preventEventBubbling(e);
            push({ id: null, name: "" });
          }}
        >
          Add Team
        </Button>
      </Pane>
      {!isNull(showConfirmDeleteIndex) && (
        <Dialog
          isShown
          hasFooter
          onCloseComplete={() => setShowConfirmDeleteIndex(null)}
          onConfirm={(close) => {
            remove(showConfirmDeleteIndex);
            close();
          }}
          title="Delete Team?"
        >
          This team has projects associated with it. If you delete this team,
          those projects will no longer be associated with any team. Are you
          sure you wish to delete this team?
        </Dialog>
      )}
    </Pane>
  );
}
