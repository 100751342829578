export const NOTIFICATION_TYPE = {
  ACTIVITY_SUMMARY: "ACTIVITY_SUMMARY",
  DOCUMENT_APPROVALS: "DOCUMENT_APPROVALS",
  DOCUMENT_PROCESSING_FINISHED: "DOCUMENT_PROCESSING_FINISHED",
  DOCUMENTS_RECEIVED_VIA_EMAIL: "DOCUMENTS_RECEIVED_VIA_EMAIL",
  DRAW_APPROVED: "DRAW_APPROVED",
  DRAW_SUBMISSION_UPDATED: "DRAW_SUBMISSION_UPDATED",
  DRAW_SUBMITTED: "DRAW_SUBMITTED",
  MENTIONED_BY_ANOTHER_USER: "MENTIONED_BY_ANOTHER_USER",
  NO_DRAWS_RECEIVED: "NO_DRAWS_RECEIVED",
  REVIEW_RESET: "REVIEW_RESET",
  WEEKLY_TIMELINE_REPORT: "WEEKLY_TIMELINE_REPORT",
};
