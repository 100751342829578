import React from "react";
import { Pane } from "evergreen-ui";
import { Bar, Line, Scatter } from "react-chartjs-2";

const getOptions = (format) => ({
  scales: {
    yAxes: [
      {
        ticks: {
          callback: (value) => value.toLocaleString("en"),
          beginAtZero: true,
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: format,
    },
  },
});

const getScatterOptions = (format) => ({
  scales: {
    xAxes: [
      {
        type: "linear",
        position: "bottom",
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: format,
    },
  },
});

// Widths and heights just set the aspect ratios of the graphs. They don't directly set the width and height
// of the resulting element. That's set by the parent.
export const BarGraph = ({ max, metric, colors, width, format }) => {
  const data = {
    label: metric.name,
    labels: metric.labels,
    datasets: [
      ...metric.datasets.map((dataset, i) => ({
        ...dataset,
        fill: false,
        backgroundColor: colors[i],
        borderColor: colors[i],
      })),
    ],
  };

  return (
    <Pane width={width}>
      <Bar
        height={400}
        width={600}
        data={data}
        options={getOptions(format, max)}
      />
    </Pane>
  );
};

export const LineGraph = ({ metric, colors, width, format }) => {
  const data = {
    label: metric.name,
    labels: metric.labels,
    datasets: [
      ...(metric.target
        ? [
            {
              fill: true,
              backgroundColor: "rgba(235, 185, 82, 0.2)",
              borderColor: "rgba(235, 185, 82, 0.2)",
              borderDash: [5, 5],
              data: [...Array(metric.labels.length)].map(() => metric.target),
              label: "Target",
            },
          ]
        : []),
      ...metric.datasets.map((dataset, i) => ({
        ...dataset,
        fill: false,
        backgroundColor: colors[i],
        borderColor: colors[i],
      })),
    ],
  };

  return (
    <Pane width={width}>
      <Line height={400} width={600} data={data} options={getOptions(format)} />
    </Pane>
  );
};

export const ScatterPlot = ({ metric, colors, width, format }) => {
  const data = {
    label: metric.name,
    datasets: metric.datasets.map((dataset, i) => ({
      ...dataset,
      fill: false,
      backgroundColor: colors[i],
      borderColor: colors[i],
    })),
  };

  return (
    <Pane width={width}>
      <Scatter
        height={400}
        width={600}
        data={data}
        options={getScatterOptions(format)}
      />
    </Pane>
  );
};
