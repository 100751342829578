import React from "react";
import PropTypes from "prop-types";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  Form,
  FormCheckbox,
  FormDateInput,
  FormDropdown,
  FormFieldArray,
  FormInput,
  Icon,
  Link,
  Pane,
  Text,
} from "components/materials";
import {
  ACCOUNTS_PAYABLE_FEATURES,
  ACCOUNTS_PAYABLE_OPTIONS,
  CREATION_FEATURES,
  DEVELOPER_FEATURES,
  DOCUMENT_FEATURES,
  FUNDING_FEATURES,
  GENERAL_FEATURES,
  LENDER_FEATURES,
} from "helpers/permissionSectionHelpers";
import { isBlank, majorScale, t } from "helpers/utilities";
import {
  PERMISSION_ACTION,
  TIER_OPTION,
  PRICING_PLAN_OPTION,
} from "helpers/enums";
import { find, map, set, get, camelCase, pick } from "lodash";
import { isValid } from "date-fns";
import { dateFormToServer, parseServerDate } from "helpers/dateHelpers";
import { OrganizationPermissionsForm } from "./OrganizationPermissionsForm";
import { OrganizationTypeForm } from "./OrganizationTypeForm";
import { CustomFieldsForm } from "./CustomFieldsForm";
import { OrganizationTeamsForm } from "./OrganizationTeamsForm";

export function OrganizationEditForm({ form, result }) {
  const [showDrawAssessment, setShowDrawAssessment] = React.useState(false);
  const [showInspectionFields, setShowInspectionFields] = React.useState(false);
  const [showTeams, setShowTeams] = React.useState(false);
  const hasInspectionReport = !!find(form.values.generalPermissions, {
    action: PERMISSION_ACTION.INSPECTION_REPORT_WORKFLOW,
    enabled: true,
  });
  const hasDrawAssessmentForm = !!find(form.values.generalPermissions, {
    action: PERMISSION_ACTION.DRAW_ASSESSMENT_QUESTIONS_FORM,
    enabled: true,
  });
  const hasTeams = !!find(form.values.generalPermissions, {
    action: PERMISSION_ACTION.TEAM_MANAGEMENT,
    enabled: true,
  });

  const tierOptions = [{ id: "", name: "" }].concat(
    Object.values(TIER_OPTION).map((tier) => ({
      id: tier,
      name: t(`tiers.${tier}`),
    }))
  );
  const pricingPlanOptions = [{ id: "", name: "" }].concat(
    Object.values(PRICING_PLAN_OPTION).map((plan) => ({
      id: plan,
      name: t(`pricingPlans.${plan}`),
    }))
  );
  return (
    <React.Fragment>
      <Form isLoading={result.loading} onSubmit={form.handleSubmit}>
        <FormInput label="Name" name="name" required />
        <FormInput
          type="number"
          label="Total Addressable Portfolio"
          name="totalAddressableProjectCount"
        />
        <FormDropdown label="Tier" name="tier" options={tierOptions} />
        <FormDropdown
          label="Pricing Plan"
          name="pricingPlan"
          options={pricingPlanOptions}
        />
        <FormDateInput
          name="contractStartDate"
          label="Contract Start Date"
          showTimeSelect={false}
        />
        <div style={{ marginTop: majorScale(3), marginBottom: majorScale(3) }}>
          <Link
            to={`/organizations/${form.values.id}/project_month_credits_activities`}
            style={{ fontWeight: "bold" }}
          >
            Project Month Credits Activity:
            {form.values.projectMonthCredits
              ? ` ${form.values.projectMonthCredits}`
              : " None"}
          </Link>
        </div>
        <Pane display="flex">
          <FormCheckbox
            label="Demo Account"
            name="demoAccount"
            helpText={t("helpText.demoAccount")}
          />
          <FormCheckbox
            label="Inactive Account"
            marginLeft={majorScale(5)}
            name="inactiveAccount"
            helpText={t("helpText.inactiveAccount")}
          />
          <FormCheckbox
            label="Pursuit Bundle"
            marginLeft={majorScale(5)}
            name="pursuitBundle"
          />
        </Pane>
        <OrganizationTypeForm form={form} />
        <FormFieldArray
          label="Organization Features"
          name="permissions"
          render={OrganizationPermissionsForm}
          showAstrix
        />
        {hasInspectionReport && showInspectionFields && (
          <FormFieldArray
            containerProps={{ marginTop: majorScale(4) }}
            label={
              <Pane display="flex" alignItems="center">
                <Icon
                  icon={ChevronDownIcon}
                  marginRight={majorScale(1)}
                  onClick={() => setShowInspectionFields(false)}
                />
                <Text onClick={() => setShowInspectionFields(false)}>
                  Inspection Report Fields
                </Text>
              </Pane>
            }
            name="inspectionReportQuestions"
          >
            {(fieldArray) => (
              <CustomFieldsForm
                addButtonCopy="Add Inspection Report Field"
                customFieldName="inspectionReportQuestions"
                deleteWarningMessage="Are you sure you would like to remove this field? It has already been used on at least one inspection report. This will remove the field from all projects and all reports."
                deleteWarningTitle="Remove Inspection Report Questionnaire Field"
                {...fieldArray}
              />
            )}
          </FormFieldArray>
        )}
        {hasInspectionReport && !showInspectionFields && (
          <Pane display="flex" alignItems="center" marginTop={majorScale(4)}>
            <Icon
              icon={ChevronRightIcon}
              marginRight={majorScale(1)}
              onClick={() => setShowInspectionFields(true)}
            />
            <Text onClick={() => setShowInspectionFields(true)}>
              Inspection Report Fields
            </Text>
          </Pane>
        )}
        {hasDrawAssessmentForm && showDrawAssessment && (
          <FormFieldArray
            containerProps={{ marginTop: majorScale(2) }}
            label={
              <Pane display="flex" alignItems="center">
                <Icon
                  icon={ChevronDownIcon}
                  marginRight={majorScale(1)}
                  onClick={() => setShowDrawAssessment(false)}
                />
                <Text onClick={() => setShowDrawAssessment(false)}>
                  Draw Assessment Questions
                </Text>
              </Pane>
            }
            name="drawAssessmentQuestions"
          >
            {(fieldArray) => (
              <CustomFieldsForm
                addButtonCopy="Add Draw Assessment Question"
                customFieldName="drawAssessmentQuestions"
                deleteWarningMessage="Are you sure you would like to remove this question? It has already been used on at least one draw. This will remove the question from all projects and draws."
                deleteWarningTitle="Remove Draw Assessment Question"
                {...fieldArray}
              />
            )}
          </FormFieldArray>
        )}
        {hasDrawAssessmentForm && !showDrawAssessment && (
          <Pane display="flex" alignItems="center" marginTop={majorScale(2)}>
            <Icon
              icon={ChevronRightIcon}
              marginRight={majorScale(1)}
              onClick={() => setShowDrawAssessment(true)}
            />
            <Text onClick={() => setShowDrawAssessment(true)}>
              Draw Assessment Questions
            </Text>
          </Pane>
        )}
        {hasTeams && showTeams && (
          <FormFieldArray
            containerProps={{ marginTop: majorScale(2) }}
            label={
              <Pane display="flex" alignItems="center">
                <Icon
                  icon={ChevronDownIcon}
                  marginRight={majorScale(1)}
                  onClick={() => setShowTeams(false)}
                />
                <Text onClick={() => setShowTeams(false)}>Teams</Text>
              </Pane>
            }
            name="teams"
          >
            {(fieldArray) => <OrganizationTeamsForm fieldArray={fieldArray} />}
          </FormFieldArray>
        )}
        {hasTeams && !showTeams && (
          <Pane display="flex" alignItems="center" marginTop={majorScale(2)}>
            <Icon
              icon={ChevronRightIcon}
              marginRight={majorScale(1)}
              onClick={() => setShowTeams(true)}
            />
            <Text onClick={() => setShowTeams(true)}>Teams</Text>
          </Pane>
        )}
      </Form>
    </React.Fragment>
  );
}

function permissionEnabled(organization, permission) {
  return get(organization.permissionConfig, camelCase(permission), false);
}

export function initialValues(organization) {
  return {
    id: organization.id,
    demoAccount: organization.demoAccount,
    inactiveAccount: organization.inactiveAccount,
    name: organization.name,
    type: organization.type,
    tier: organization.tier || undefined,
    totalAddressableProjectCount:
      organization.totalAddressableProjectCount || undefined,
    inspectionReportQuestions: organization.inspectionReportQuestions,
    drawAssessmentQuestions: organization.drawAssessmentQuestions,
    teams: organization.teams,
    pricingPlan: organization.pricingPlan || undefined,
    pursuitBundle: organization.pursuitBundle,
    contractStartDate:
      parseServerDate(organization.contractStartDate) || undefined,
    projectMonthCredits: organization.projectMonthCredits || undefined,
    nextProjectMonthCreditsDeduction:
      organization.nextProjectMonthCreditsDeduction || undefined,
    accountsPayablePermissions: map(ACCOUNTS_PAYABLE_FEATURES, (action) => ({
      action,
      enabled: permissionEnabled(organization, action),
    })),
    accountsPayableOptionsPermissions: map(
      ACCOUNTS_PAYABLE_OPTIONS,
      (action) => ({
        action,
        enabled: permissionEnabled(organization, action),
      })
    ),
    creationPermissions: map(CREATION_FEATURES, (action) => ({
      action,
      enabled: permissionEnabled(organization, action),
    })),
    developerPermissions: map(DEVELOPER_FEATURES, (action) => ({
      action,
      enabled: permissionEnabled(organization, action),
    })),
    documentPermissions: map(DOCUMENT_FEATURES, (action) => ({
      action,
      enabled: permissionEnabled(organization, action),
    })),
    fundingPermissions: map(FUNDING_FEATURES, (action) => ({
      action,
      enabled: permissionEnabled(organization, action),
    })),
    generalPermissions: map(GENERAL_FEATURES, (action) => ({
      action,
      enabled: permissionEnabled(organization, action),
    })),
    lenderPermissions: map(LENDER_FEATURES, (action) => ({
      action,
      enabled: permissionEnabled(organization, action),
    })),
  };
}
export function validate(values, avidPostPaymentEmailAddress = "") {
  const errors = {};

  const agreementManagementEnabled = values.developerPermissions.find(
    ({ action }) => action === PERMISSION_ACTION.AGREEMENT_MANAGEMENT
  ).enabled;

  const trackCostToAgreementsEnabled = values.developerPermissions.find(
    ({ action }) => action === PERMISSION_ACTION.TRACK_COST_TO_AGREEMENTS
  ).enabled;

  const limitTrackedAgreementWarnings = values.developerPermissions.find(
    ({ action }) =>
      action === PERMISSION_ACTION.LIMIT_TRACKED_AGREEMENT_WARNINGS
  ).enabled;

  if (!agreementManagementEnabled && trackCostToAgreementsEnabled) {
    set(
      errors,
      "developerFeatures",
      t(
        "organizationForm.validate.requireAgreementManagementForTrackCostToAgreements"
      )
    );
  }

  if (
    (!agreementManagementEnabled || !trackCostToAgreementsEnabled) &&
    limitTrackedAgreementWarnings
  ) {
    set(
      errors,
      "developerFeatures",
      t(
        "organizationForm.validate.requireAgreementManagementForLimitTrackAgreementWarnings"
      )
    );
  }

  const taskManagementEnabled = values.generalPermissions.find(
    ({ action }) => action === PERMISSION_ACTION.TASK_MANAGEMENT
  ).enabled;

  const dependencyTrackingEnabled = values.generalPermissions.find(
    ({ action }) => action === PERMISSION_ACTION.DEPENDENCY_TRACKING
  ).enabled;

  if (!taskManagementEnabled && dependencyTrackingEnabled) {
    set(
      errors,
      "generalFeatures",
      t("organizationForm.validate.requireTaskManagementForDependencyTracking")
    );
  }

  const enabledPayableFeatures = values.accountsPayablePermissions.filter(
    (value) => value.enabled
  );

  const hasTooManyAccountsPayablePermissions =
    enabledPayableFeatures.length > 1;

  if (hasTooManyAccountsPayablePermissions) {
    set(
      errors,
      "accountsPayableFeatures",
      t("organizationForm.validate.allowOnlyOneAccountsPayableFeatures")
    );
  }

  // Checks that the avid post payment email is set if turning on the avid flag.
  // This is needed since we use that avid email when posting payments.
  values.accountsPayablePermissions.forEach((apPerms) => {
    const hasAvidEnabled =
      apPerms.action === PERMISSION_ACTION.AVID_AP_INTEGRATION &&
      apPerms.enabled;
    if (hasAvidEnabled && isBlank(avidPostPaymentEmailAddress)) {
      set(
        errors,
        "accountsPayableFeatures",
        t("organizationForm.validate.requireAvidEmail")
      );
    }
  });

  if (isBlank(values.name)) errors.name = "Name is required";
  values.inspectionReportQuestions.forEach(
    ({ label, reportTemplateFieldName }, index) => {
      if (isBlank(label)) {
        set(
          errors,
          ["inspectionReportQuestions", index, "label"],
          "field must have a prompt"
        );
      }

      if (isBlank(reportTemplateFieldName)) {
        set(
          errors,
          ["inspectionReportQuestions", index, "reportTemplateFieldName"],
          "cannot be blank"
        );
      }

      if (
        !isBlank(reportTemplateFieldName) &&
        !RegExp(/^\S*$/).test(reportTemplateFieldName)
      ) {
        set(
          errors,
          ["inspectionReportQuestions", index, "reportTemplateFieldName"],
          "cannot contain spaces"
        );
      }
    }
  );
  values.drawAssessmentQuestions.forEach(
    ({ label, reportTemplateFieldName }, index) => {
      if (isBlank(label)) {
        set(
          errors,
          ["drawAssessmentQuestions", index, "label"],
          "field must have a prompt"
        );
      }

      if (isBlank(reportTemplateFieldName)) {
        set(
          errors,
          ["drawAssessmentQuestions", index, "reportTemplateFieldName"],
          "cannot be blank"
        );
      }

      if (
        !isBlank(reportTemplateFieldName) &&
        !RegExp(/^\S*$/).test(reportTemplateFieldName)
      ) {
        set(
          errors,
          ["drawAssessmentQuestions", index, "reportTemplateFieldName"],
          "cannot contain spaces"
        );
      }
    }
  );
  values.teams.forEach(({ name }, index) => {
    if (isBlank(name)) {
      set(errors, ["teams", index, "name"], "team must have a name");
    }
  });
  if (
    values.pricingPlan &&
    !Object.values(PRICING_PLAN_OPTION).includes(values.pricingPlan)
  ) {
    errors.pricingPlan = `Pricing Plan must be Lite, Standard, or Premium`;
  }
  if (values.contractStartDate && !isValid(values.contractStartDate)) {
    errors.contractStartDate = "Contract Start Date must be a valid date";
  }
  return errors;
}
export function onSubmit(mutation) {
  return ({
    accountsPayablePermissions,
    accountsPayableOptionsPermissions,
    creationPermissions,
    developerPermissions,
    documentPermissions,
    fundingPermissions,
    generalPermissions,
    lenderPermissions,
    teams,
    drawAssessmentQuestions,
    inspectionReportQuestions,
    tier,
    projectMonthCredits,
    contractStartDate,
    totalAddressableProjectCount,
    ...organizationFields
  }) => {
    const variables = {
      ...organizationFields,
      tier: tier || undefined,
      projectMonthCredits: projectMonthCredits || undefined,
      contractStartDate: dateFormToServer(contractStartDate),
      totalAddressableProjectCount: totalAddressableProjectCount || undefined,
      permissions: creationPermissions
        .concat(accountsPayablePermissions)
        .concat(accountsPayableOptionsPermissions)
        .concat(developerPermissions)
        .concat(documentPermissions)
        .concat(documentPermissions)
        .concat(fundingPermissions)
        .concat(generalPermissions)
        .concat(lenderPermissions),
      teams: teams.map((team) => pick(team, ["id", "name"])),
      inspectionReportQuestions: inspectionReportQuestions.map(
        (
          { id, label, description, fieldType, reportTemplateFieldName },
          position
        ) => ({
          id,
          label,
          fieldType,
          description: isBlank(description) ? undefined : description,
          reportTemplateFieldName,
          position,
        })
      ),
      drawAssessmentQuestions: drawAssessmentQuestions.map(
        (
          { id, label, description, fieldType, reportTemplateFieldName },
          position
        ) => ({
          id,
          label,
          fieldType,
          description: isBlank(description) ? undefined : description,
          reportTemplateFieldName,
          position,
        })
      ),
    };

    mutation({
      variables,
    });
  };
}

OrganizationEditForm.propTypes = {
  form: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
};
