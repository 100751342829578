export const DOCUMENT_TYPE_NAME = {
  ADDENDUM: "ADDENDUM",
  AGREEMENT_CONTRACTOR_BORROWER: "AGREEMENT_CONTRACTOR_BORROWER",
  BILL_OF_SALE: "BILL_OF_SALE",
  BUILDERS_RISK_CERTIFICATE: "BUILDERS_RISK_CERTIFICATE",
  CONDITIONAL_LIEN_RELEASE: "CONDITIONAL_LIEN_RELEASE",
  CONTRACT: "CONTRACT",
  DEPRECATED_DRAW_SUMMARY: "DEPRECATED_DRAW_SUMMARY",
  DISBURSEMENT_BREAKDOWN: "DISBURSEMENT_BREAKDOWN",
  DRAW_COVER_SHEET: "DRAW_COVER_SHEET",
  DRAW_SUMMARY: "DRAW_SUMMARY",
  EMAIL_TEXT: "EMAIL_TEXT",
  EXECUTED_CHANGE_ORDER: "EXECUTED_CHANGE_ORDER",
  EXPENSE_SHEET: "EXPENSE_SHEET",
  EXPOSURE: "EXPOSURE",
  FINANCIAL_STATEMENT: "FINANCIAL_STATEMENT",
  GC_AGREEMENT: "GC_AGREEMENT",
  GENERAL_LIABILITY_INSURANCE_CERTIFICATE:
    "GENERAL_LIABILITY_INSURANCE_CERTIFICATE",
  HUD_2448: "HUD_2448",
  HUD_5372: "HUD_5372",
  HUD_92403_PAGE_1: "HUD_92403_PAGE_1",
  HUD_92403_PAGE_2: "HUD_92403_PAGE_2",
  HUD_92464: "HUD_92464",
  INSPECTION_IMAGE: "INSPECTION_IMAGE",
  INSPECTION_REPORT: "INSPECTION_REPORT",
  INSURANCE_CERTIFICATE: "INSURANCE_CERTIFICATE",
  INVESTOR_COMMUNICATION: "INVESTOR_COMMUNICATION",
  INVOICE: "INVOICE",
  INVOICE_SUMMARY: "INVOICE_SUMMARY",
  LABOR_RELEASE: "LABOR_RELEASE",
  NOTICE_TO_OWNER: "NOTICE_TO_OWNER",
  OTHER: "OTHER",
  PAY_APPLICATION: "PAY_APPLICATION",
  PAYMENT_VOUCHER: "PAYMENT_VOUCHER",
  PERFORMANCE_BOND: "PERFORMANCE_BOND",
  POTENTIAL_CHANGE_ORDER: "POTENTIAL_CHANGE_ORDER",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  RECEIPT: "RECEIPT",
  RETAINAGE_RELEASE_FORM: "RETAINAGE_RELEASE_FORM",
  SCHEDULE: "SCHEDULE",
  SUBCONTRACTOR_AGREEMENT: "SUBCONTRACTOR_AGREEMENT",
  SUBCONTRACTOR_CHANGE_ORDER: "SUBCONTRACTOR_CHANGE_ORDER",
  SWORN_AFFIDAVIT: "SWORN_AFFIDAVIT",
  TITLE_REPORT: "TITLE_REPORT",
  UNCONDITIONAL_LIEN_RELEASE: "UNCONDITIONAL_LIEN_RELEASE",
  UNKNOWN: "UNKNOWN",
  W9: "W9",
  WORK_AUTHORIZATION: "WORK_AUTHORIZATION",
};
