import React from "react";
import PropTypes from "prop-types";
import { FastField, getIn } from "formik";
import { FilePicker, FormField } from "evergreen-ui";

const FormFile = ({ name, label, ...rest }) => (
  <FastField name={name}>
    {({ field, form }) => {
      const error = getIn(form.errors, name);
      return (
        <FormField label={label} validationMessage={error}>
          <FilePicker
            {...rest}
            {...field}
            onChange={(files) => form.setFieldValue(name, files[0])}
          />
        </FormField>
      );
    }}
  </FastField>
);

FormFile.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  width: PropTypes.number,
};

FormFile.defaultProps = {
  label: "",
  width: 240,
};

export default FormFile;
