import React from "react";
import PropTypes from "prop-types";
import { FastField, getIn } from "formik";
import { HelpIcon, Icon, Pane, SelectField, Tooltip } from "evergreen-ui";

function FormDropdown({ name, options, helpText, onChange, ...rest }) {
  const handleChange = (field) => (e) => {
    field.onChange(e);
    onChange && onChange(e);
  };
  return (
    <FastField name={name}>
      {({ field, form }) => {
        const error = getIn(form.errors, name);

        return (
          <Pane display="flex" alignItems="center">
            <SelectField
              marginRight={5}
              {...field}
              {...rest}
              onChange={handleChange(field)}
              validationMessage={error}
            >
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </SelectField>
            {helpText && (
              <Tooltip content={helpText}>
                <Icon icon={HelpIcon} size={15} />
              </Tooltip>
            )}
          </Pane>
        );
      }}
    </FastField>
  );
}

FormDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  width: PropTypes.number,
};

FormDropdown.defaultProps = {
  width: 240,
};

export default FormDropdown;
