import React from "react";
import gql from "graphql-tag";
import { Form, FormInput, Link, Loading, Pane } from "components/materials";
import { useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import { isBlank, majorScale } from "helpers/utilities";

const UserResults = ({ userEmail }) => {
  const { data, error, loading } = useQuery(UserLookupPage.Query, {
    variables: { email: userEmail },
    skip: isBlank(userEmail),
  });

  if (loading) return <Loading />;

  if (isBlank(userEmail) || error) return null;

  return (
    <React.Fragment>
      {data.users.map(({ id, email, name, organization }) => (
        <Pane>
          <Link to={`/users/${id}`}>{`${name} (${email})`}</Link>
          {" - "}
          <Link to={`/organizations/${organization.id}/edit`}>
            {organization.name}
          </Link>
        </Pane>
      ))}
    </React.Fragment>
  );
};

const UserLookupPage = () => {
  const [userEmail, setUserEmail] = React.useState(null);

  return (
    <React.Fragment>
      <Pane width={500} marginBottom={majorScale(2)}>
        <Formik
          initialValues={{ email: "" }}
          onSubmit={(values) => {
            setUserEmail(values.email);
          }}
        >
          {(form) => (
            <Form textSubmit="Find User" onSubmit={form.handleSubmit}>
              <FormInput label="Email" name="email" type="text" />
            </Form>
          )}
        </Formik>
      </Pane>
      <UserResults userEmail={userEmail} />
    </React.Fragment>
  );
};

UserLookupPage.Query = gql`
  query UserLookupPageQuery($email: String!) {
    users(email: $email) {
      id
      name
      email
      organization {
        id
        name
      }
    }
  }
`;

export default UserLookupPage;
