import React from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  Button,
  FormInput,
  Form,
  Heading,
  Loading,
  Pane,
  Paragraph,
} from "components/materials";
import { Formik } from "formik";
import { majorScale, toaster } from "helpers/utilities";
import {
  INTEGRATION_AUTHORIZATIONS_QUERY,
  CLEAR_LAST_EXTERNAL_VENDOR_SYNC_AT_FOR_ORGANIZATION,
} from "./graphql";

export function QuickBooksOnlinePaymentSettingsForm({
  organizationId,
  qboClientId,
  qboClientSecret,
  qboUrl,
}) {
  const [updateQboPaymentSettings, updateQboPaymentSettingsResult] =
    useMutation(UPDATE_ORGANIZATION_QBO_PAYMENT_SETTINGS_MUTATION, {
      onCompleted: () => toaster.success("Organization updated"),
    });

  const [clearLastExternalVendorSyncAt] = useMutation(
    CLEAR_LAST_EXTERNAL_VENDOR_SYNC_AT_FOR_ORGANIZATION,
    {
      onCompleted: () => toaster.success("Success"),
    }
  );

  const [integrationAuthorizationsQuery, { data: queryData, loading }] =
    useLazyQuery(INTEGRATION_AUTHORIZATIONS_QUERY);

  if (loading) {
    return <Loading />;
  }

  const integrationAuthorizations = queryData
    ? queryData.organization.integrationAuthorizations
    : [];

  return (
    <Pane>
      <Pane marginBottom={majorScale(2)}>
        <Button
          intent="danger"
          appearance="primary"
          marginRight={majorScale(1)}
          onClick={() =>
            clearLastExternalVendorSyncAt({ variables: { organizationId } })
          }
        >
          Clear &quot;vendor last synced at&quot;
        </Button>
        <Button
          intent="success"
          appearance="primary"
          onClick={() =>
            integrationAuthorizationsQuery({ variables: { organizationId } })
          }
        >
          Pull Auth Tokens
        </Button>
      </Pane>
      {integrationAuthorizations.length >= 0 && (
        <Pane marginBottom={majorScale(2)}>
          {integrationAuthorizations.map((authorization) => (
            <Pane>
              <Heading>
                External Project Id: {authorization.externalProjectId}
              </Heading>
              <Paragraph marginBottom={majorScale(1)}>
                {authorization.authToken}
              </Paragraph>
            </Pane>
          ))}
        </Pane>
      )}
      <Pane>
        <Formik
          initialValues={{
            qboClientId: qboClientId || undefined,
            qboClientSecret: qboClientSecret || undefined,
            qboUrl: qboUrl || undefined,
            id: organizationId,
          }}
          onSubmit={(data) => {
            const trimmedData = {
              ...data,
              qboClientId: data.qboClientId ? data.qboClientId.trim() : "",
              qboClientSecret: data.qboClientSecret
                ? data.qboClientSecret.trim()
                : "",
              qboUrl: data.qboUrl ? data.qboUrl.trim() : "",
            };
            return updateQboPaymentSettings({ variables: trimmedData });
          }}
        >
          {({ handleSubmit }) => (
            <Form
              isLoading={updateQboPaymentSettingsResult.loading}
              onSubmit={handleSubmit}
            >
              <FormInput
                label="Url (ex. https://quickbooks.api.intuit.com)"
                name="qboUrl"
                width={300}
              />
              <FormInput label="Client Id" name="qboClientId" width={300} />
              <FormInput
                label="API Client Secret"
                name="qboClientSecret"
                width={300}
                type="password"
              />
            </Form>
          )}
        </Formik>
      </Pane>
    </Pane>
  );
}

const UPDATE_ORGANIZATION_QBO_PAYMENT_SETTINGS_MUTATION = gql`
  mutation UpdateOrganizationQboPaymentSettings(
    $id: String!
    $qboClientId: String
    $qboClientSecret: String
    $qboUrl: String
  ) {
    updateOrganizationQboPaymentSettings(
      id: $id
      qboClientId: $qboClientId
      qboClientSecret: $qboClientSecret
      qboUrl: $qboUrl
    ) {
      id
      qboClientId
      qboClientSecret
      qboUrl
    }
  }
`;
