export const isChargeableOrganization = (organization) => {
  if (
    typeof organization.projectMonthCredits === "number" &&
    isContractStartDateAssigned(organization)
  ) {
    return true;
  }
  return false;
};

export const isContractStartDateAssigned = ({ contractStartDate }) =>
  contractStartDate instanceof Date || typeof contractStartDate === "string";

export const isContractStartDatePassed = (organization) => {
  return (
    isContractStartDateAssigned(organization) &&
    new Date(organization.contractStartDate) < new Date()
  );
};
