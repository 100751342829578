import React from "react";
import PropTypes from "prop-types";
import { every, get } from "lodash";
import {
  Badge,
  Divider,
  FormCheckbox,
  Pane,
  Paragraph,
} from "components/materials";
import { majorScale, t } from "helpers/utilities";
import {
  ALPHA_PERMISSIONS,
  BETA_PERMISSIONS,
  SPECIAL_MESSAGE_PERMISSIONS,
} from "helpers/permissionSectionHelpers";

export function FeatureTags({ permission }) {
  if (ALPHA_PERMISSIONS.indexOf(permission) !== -1) {
    return (
      <React.Fragment>
        {" "}
        <Badge color="teal">Alpha</Badge>
      </React.Fragment>
    );
  }

  if (BETA_PERMISSIONS.indexOf(permission) !== -1) {
    return (
      <React.Fragment>
        {" "}
        <Badge color="purple">Beta</Badge>
      </React.Fragment>
    );
  }

  return null;
}

const FeatureGroup = ({
  checkedValue,
  error,
  form,
  formValueName,
  groupName,
}) => {
  const formValues = get(form, `values.${formValueName}`);
  return (
    <React.Fragment>
      <FormCheckbox
        checked={checkedValue}
        indeterminate={!checkedValue}
        label={t(`featureGroups.${groupName}`)}
        name={groupName}
      />
      <Divider color="#e4eaf0" height={1} />
      {formValues.map(({ action }, index) => (
        <FormCheckbox
          alertText={
            SPECIAL_MESSAGE_PERMISSIONS.find(
              (specialAction) => specialAction === action
            ) && t(`alertText.${action}`)
          }
          key={action}
          name={`${formValueName}.${index}.enabled`}
          label={
            <React.Fragment>
              {t(`permissions.${action}`)}
              <FeatureTags permission={action} />
            </React.Fragment>
          }
          helpText={t(`helpText.${action}`)}
          onChange={(target) => {
            form.setFieldValue(
              `${formValueName}.${index}.enabled`,
              target.checked
            );
            form.setFieldValue(groupName, checkedValue);
          }}
        />
      ))}
      {error ? <Paragraph color="red">{error}</Paragraph> : null}
    </React.Fragment>
  );
};

export function OrganizationPermissionsForm({ form }) {
  const generalChecked = every(
    form.values.generalPermissions,
    ({ enabled }) => !!enabled
  );

  const fundingChecked = every(
    form.values.fundingPermissions,
    ({ enabled }) => !!enabled
  );

  const documentChecked = every(
    form.values.documentPermissions,
    ({ enabled }) => !!enabled
  );

  const creationChecked = every(
    form.values.creationPermissions,
    ({ enabled }) => !!enabled
  );

  const lenderChecked = every(
    form.values.lenderPermissions,
    ({ enabled }) => !!enabled
  );

  const developerChecked = every(
    form.values.developerPermissions,
    ({ enabled }) => !!enabled
  );

  const accountsPayableChecked = every(
    form.values.accountsPayablePermissions,
    ({ enabled }) => !!enabled
  );

  return (
    <Pane>
      <Paragraph fontSize={12}>{t("helpText.organizationFeatures")}</Paragraph>
      <Paragraph fontSize={12} marginBottom={majorScale(2)}>
        For definitions of <Badge color="teal">Alpha</Badge> and{" "}
        <Badge color="purple">Beta</Badge>, please refer to{" "}
        <a
          href="https://coda.io/d/Fields-and-Documents-Brainstorming_d0_HVMtpLuR/Deliverables_suWhh#Sprint-Deliverables_tutw8/r20"
          rel="noopener noreferrer"
          target="_blank"
        >
          this document
        </a>
        .
      </Paragraph>
      <Pane display="flex">
        <Pane paddingRight={majorScale(4)} width="20%">
          <FeatureGroup
            checkedValue={generalChecked}
            error={form.errors.generalFeatures}
            form={form}
            formValueName="generalPermissions"
            groupName="generalFeatures"
          />
          <Divider height={majorScale(4)} />
          <FeatureGroup
            checkedValue={fundingChecked}
            form={form}
            formValueName="fundingPermissions"
            groupName="fundingFeatures"
          />
        </Pane>
        <Pane paddingRight={majorScale(4)} width="40%">
          <FeatureGroup
            checkedValue={documentChecked}
            form={form}
            formValueName="documentPermissions"
            groupName="documentFeatures"
          />
          <Divider height={majorScale(4)} />
          <FeatureGroup
            checkedValue={creationChecked}
            form={form}
            formValueName="creationPermissions"
            groupName="creationFeatures"
          />
          <Divider height={majorScale(4)} />
          <FeatureGroup
            checkedValue={accountsPayableChecked}
            error={form.errors.accountsPayableFeatures}
            form={form}
            formValueName="accountsPayablePermissions"
            groupName="accountsPayableFeatures"
          />
          <Divider height={majorScale(4)} />
          <FeatureGroup
            checkedValue={accountsPayableChecked}
            form={form}
            formValueName="accountsPayableOptionsPermissions"
            groupName="accountsPayableOptions"
          />
        </Pane>
        <Pane paddingRight={majorScale(4)} width="20%">
          <FeatureGroup
            checkedValue={lenderChecked}
            form={form}
            formValueName="lenderPermissions"
            groupName="lenderFeatures"
          />
        </Pane>
        <Pane width="20%">
          <FeatureGroup
            checkedValue={developerChecked}
            error={form.errors.developerFeatures}
            form={form}
            formValueName="developerPermissions"
            groupName="developerFeatures"
          />
        </Pane>
      </Pane>
    </Pane>
  );
}

OrganizationPermissionsForm.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.shape({
      accountsPayablePermissions: PropTypes.array.isRequired,
      generalPermissions: PropTypes.array.isRequired,
      fundingPermissions: PropTypes.array.isRequired,
      documentPermissions: PropTypes.array.isRequired,
      creationPermissions: PropTypes.array.isRequired,
      lenderPermissions: PropTypes.array.isRequired,
      developerPermissions: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
};
