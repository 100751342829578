import React from "react";
import gql from "graphql-tag";
import { Formik } from "formik";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { toaster } from "helpers/utilities";
import { DrawsTable, ProjectEditForm } from "components/templates";
import {
  Button,
  Dialog,
  Divider,
  Loading,
  Segment,
  TextInput,
} from "components/materials";
import { get } from "lodash";

const QUERY = gql`
  query ProjectEditPage($projectId: String!) {
    project(id: $projectId) {
      id
      name
      draws {
        id
        name
        disbursedAmount
        state
        requestedAmount
      }
      owner {
        id
      }
      template {
        id
        name
      }
    }
  }
`;

const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateProject($projectId: String!) {
    updateProject(projectId: $projectId) {
      id
      name
    }
  }
`;

const DELETE_PROJECT_MUTATION = gql`
  mutation DeleteProject($projectId: String!) {
    deleteProject(projectId: $projectId) {
      id
    }
  }
`;

const DELETE_DRAW_MUTATION = gql`
  mutation DeleteDraw($drawId: String!) {
    deleteDraw(drawId: $drawId) {
      id
    }
  }
`;

export default function ProjectEditPage(props) {
  const { history } = props;
  const [isShown, setIsShown] = React.useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = React.useState(false);
  const { data, error, loading } = useQuery(QUERY, {
    variables: props.match.params,
  });
  const [deleteProject] = useMutation(DELETE_PROJECT_MUTATION, {
    onCompleted: () => {
      history.push(`/organizations/${project.owner.id}/edit`);
      toaster.success("Project deleted");
    },
  });
  const [updateProject, updateProjectResult] = useMutation(
    UPDATE_PROJECT_MUTATION,
    {
      onCompleted: () => {
        toaster.success("Project updated");
      },
    }
  );

  const [deleteDraw] = useMutation(DELETE_DRAW_MUTATION, {
    refetchQueries: [{ query: QUERY, variables: props.match.params }],
    onCompleted: () => {
      toaster.success("Draw deleted");
    },
  });

  if (loading) return <Loading />;
  if (error) return null;

  const { project } = data;

  return (
    <React.Fragment>
      <Segment heading={project.name}>
        <Formik
          initialValues={ProjectEditForm.initialValues(project)}
          onSubmit={ProjectEditForm.onSubmit(updateProject)}
        >
          {(form) => (
            <ProjectEditForm
              projectType={get(data, "project.template.name")}
              form={form}
              result={updateProjectResult}
            />
          )}
        </Formik>
      </Segment>
      <Segment heading="Draws">
        <DrawsTable draws={project.draws} deleteDrawMutation={deleteDraw} />
      </Segment>
      <Divider />
      <React.Fragment>
        <Dialog
          isShown={isShown}
          title="Warning, are you sure you would like to delete this project? Please type 'DELETE' to confirm."
          intent="danger"
          isConfirmDisabled={!deleteConfirmed}
          onConfirm={() =>
            deleteProject({ variables: { projectId: project.id } })
          }
          onCloseComplete={() => setIsShown(false)}
          confirmLabel="Confirm"
        >
          <TextInput
            onChange={(e) =>
              e.target.value === "DELETE"
                ? setDeleteConfirmed(true)
                : setDeleteConfirmed(false)
            }
          />
        </Dialog>
        <Button onClick={() => setIsShown(true)} intent="danger">
          Delete project
        </Button>
      </React.Fragment>
    </React.Fragment>
  );
}
