import React from "react";
import { FormRadioGroup } from "components/materials";
import { ORGANIZATION_TYPE } from "helpers/enums";
import {
  CREATION_FEATURES,
  DEFAULT_DISABLED_FEATURES,
  DEFAULT_DISABLED_DEVELOPER_FEATURES,
  DEFAULT_DISABLED_LENDER_FEATURES,
  DEVELOPER_FEATURES,
  DOCUMENT_FEATURES,
  FUNDING_FEATURES,
  GENERAL_FEATURES,
  LENDER_FEATURES,
} from "helpers/permissionSectionHelpers";
import { majorScale, t } from "helpers/utilities";
import { map, partition } from "lodash";

export const OrganizationTypeForm = ({ form }) => {
  const onChange = ({ value }) => {
    const isDeveloper = value === ORGANIZATION_TYPE.BORROWER;

    const defaultNotEnabledLookup = [
      ...(isDeveloper
        ? DEFAULT_DISABLED_DEVELOPER_FEATURES
        : DEFAULT_DISABLED_LENDER_FEATURES),
      ...DEFAULT_DISABLED_FEATURES,
    ];

    const isDefaultEnabled = (action) =>
      defaultNotEnabledLookup.indexOf(action) === -1;

    form.setValues({
      ...form.values,
      creationPermissions: map(CREATION_FEATURES, (action) => ({
        action,
        enabled: isDefaultEnabled(action),
      })),
      developerPermissions: map(DEVELOPER_FEATURES, (action) => ({
        action,
        enabled: isDeveloper,
      })),
      developerFeatures: isDeveloper,
      documentPermissions: map(DOCUMENT_FEATURES, (action) => ({
        action,
        enabled: isDefaultEnabled(action),
      })),
      fundingPermissions: map(FUNDING_FEATURES, (action) => ({
        action,
        enabled: isDefaultEnabled(action),
      })),
      generalPermissions: map(GENERAL_FEATURES, (action) => ({
        action,
        enabled: isDefaultEnabled(action),
      })),
      lenderPermissions: map(LENDER_FEATURES, (action) => ({
        action,
        enabled: !isDeveloper,
      })),
      lenderFeatures: !isDeveloper,
      type: value,
    });
  };

  const organizationTypes = map(ORGANIZATION_TYPE, (type) => {
    return { label: t(`organizationTypes.${type}`), value: type };
  });

  const [organizationTypeOptions, stackedOptions] = partition(
    organizationTypes,
    ({ value }) =>
      value === ORGANIZATION_TYPE.BORROWER ||
      value === ORGANIZATION_TYPE.LENDER ||
      value === ORGANIZATION_TYPE.CONSTRUCTION_SERVICES
  );

  return (
    <FormRadioGroup
      isRequired
      label="Organization View"
      marginBottom={majorScale(1)}
      marginRight={majorScale(7)}
      marginTop={majorScale(2)}
      name="type"
      onChange={onChange}
      optionText={t("organizationPage.optionText")}
      options={organizationTypeOptions}
      size={majorScale(2)}
      stackedOptions={stackedOptions}
      stackedOptionText={t("organizationPage.stackedOptionText")}
    />
  );
};
