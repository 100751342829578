import gql from "graphql-tag";

export const INTEGRATION_AUTHORIZATIONS_QUERY = gql`
  query IntegrationsAuthorizationsQuery($organizationId: String!) {
    organization(id: $organizationId) {
      id
      integrationAuthorizations {
        id
        externalProjectId
        authToken
      }
    }
  }
`;

export const CLEAR_LAST_EXTERNAL_VENDOR_SYNC_AT_FOR_ORGANIZATION = gql`
  mutation ClearLastExternalVendorSyncAtForOrganization(
    $organizationId: String!
  ) {
    clearLastExternalVendorSyncAtForOrganization(id: $organizationId) {
      id
      integrationAuthorizations {
        id
      }
    }
  }
`;
