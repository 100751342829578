import React from "react";
import { Spinner } from "evergreen-ui";

const Loading = (props) => (
  <Spinner
    margin="auto"
    position="fixed"
    bottom={0}
    left={0}
    right={0}
    top={0}
    {...props}
  />
);

export default Loading;
