import React from "react";
import PropTypes from "prop-types";
import { EvergreenLink, Link, Table } from "components/materials";

const UsersTable = (props) => (
  <Table border="default">
    <Table.Head>
      <Table.TextHeaderCell>Name</Table.TextHeaderCell>
      <Table.TextHeaderCell>Email</Table.TextHeaderCell>
      <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
    </Table.Head>
    <Table.Body maxHeight={400}>
      {props.users.map((user) => (
        <Table.Row key={user.id}>
          <Table.TextCell>{user.name}</Table.TextCell>
          <Table.TextCell>
            <EvergreenLink href={`mailto:${user.email}`}>
              {user.email}
            </EvergreenLink>
          </Table.TextCell>
          <Table.Cell>
            <Link to={`/users/${user.id}`}>View</Link>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
};

export default UsersTable;
