import React from "react";
import PropTypes from "prop-types";
import { FastField, getIn } from "formik";
import { FormField, Pane, TextInput } from "evergreen-ui";
import DatePicker from "react-datepicker";
import {
  DATE_FORMAT,
  DATETIME_FORMAT,
  formatDate,
  formatDateTime,
} from "helpers/dateHelpers";
import "react-datepicker/dist/react-datepicker.css";

class DateInput extends React.PureComponent {
  render() {
    return <TextInput {...this.props} />;
  }
}

const FormDateInput = ({
  disabled,
  name,
  label,
  labelProps,
  onChange,
  showTimeSelect = true,
  ...props
}) => {
  const dateFormat = showTimeSelect ? DATETIME_FORMAT : DATE_FORMAT;
  const formatDateType = showTimeSelect ? formatDateTime : formatDate;
  return (
    <FastField name={name}>
      {({ field, form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        const hasError = !!error && !!touch;

        const selected = field.value;

        return (
          <FormField
            label={<Pane {...labelProps}>{label}</Pane>}
            validationMessage={hasError && error}
            {...props}
          >
            <DatePicker
              dateFormat={dateFormat}
              placeholder=""
              selected={selected}
              onChange={(date) => {
                form.setFieldValue(name, date);
                form.setFieldTouched(name, true);
                onChange(formatDateType(date, null));
              }}
              disabled={disabled}
              showTimeSelect={showTimeSelect}
              customInput={<DateInput />}
            />
          </FormField>
        );
      }}
    </FastField>
  );
};

FormDateInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  labelProps: PropTypes.object,
  onChange: PropTypes.func,
};

FormDateInput.defaultProps = {
  labelProps: {},
  onChange: () => {},
};

export default FormDateInput;
