import React, { useState } from "react";
import PropTypes from "prop-types";
import { Heading, Pane, Tab, Tablist } from "evergreen-ui";
import { majorScale } from "helpers/utilities";

function TabbedSegments({ heading, children }) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <Pane display="flex" height="100%">
      <Tablist flexBasis={240} marginRight={majorScale(2)}>
        {React.Children.map(children, (child, index) => (
          <Tab
            aria-controls={`panel-${child.props.heading}`}
            direction="vertical"
            id={child.props.heading}
            isSelected={index === selectedIndex}
            key={child.props.heading}
            onSelect={() => setSelectedIndex(index)}
          >
            {child.props.heading}
          </Tab>
        ))}
      </Tablist>
      <Pane flex="1">
        <Heading size={700} marginBottom={majorScale(2)}>
          {heading}
        </Heading>
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child, { isSelected: index === selectedIndex })
        )}
      </Pane>
    </Pane>
  );
}

TabbedSegments.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
};

export default TabbedSegments;
